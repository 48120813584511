import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, TYPES } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('type/startFetchDataFields', async ({ locale, token, ifEdit, type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TYPES}/dataFields/${type}?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchTypes = createAsyncThunk('type/startFetchTypes', async ({ locale, token, filter, type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${TYPES}/${type}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateType = createAsyncThunk('type/startCreateType', async ({ typeData, locale, token, form, type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${TYPES}/store/${type}`, typeData, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateType = createAsyncThunk('type/startUpdateType', async ({ id, typeData, locale, token, form, type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${TYPES}/update/${type}/${id}`, typeData, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteType = createAsyncThunk('type/startDeleteType', async ({ locale, token, id, type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${TYPES}/destroy/${type}/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveType = createAsyncThunk('type/startActiveType', async ({ locale, token, id, action, type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${TYPES}/active/${type}/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchType = createAsyncThunk('type/startFetchType', async ({ typeData, history, locale, token, type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TYPES}/edit/${type}/${typeData}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            type: data.type,
            name_en: data.name_en,
            name_el: data.name_el,
            desc_en: data.desc_en ? data.desc_en : "",
            desc_el: data.desc_el ? data.desc_el : "",
            color: data.color ? data.color : "",
            icon: data.icon ? data.icon : "",
            property_category_ids: data.property_category_ids ? data.property_category_ids : "",
            query_name: data.query_name,
            active_inactive: data.active_inactive,
            category: data.category,
            roles: data.roles ? data.roles : [],
            month_one: data.month_one ? data.month_one : null,
            month_two: data.month_two ? data.month_two : null,
            month_three: data.month_three ? data.month_three : null,
            month_four: data.month_four ? data.month_four : null,
            url: data.url,
            image: data.image ? data.image : '',
            image_file: ''
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('type/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('type/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('type/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    types: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{ desc: false, id: "id" }],
    createType: '',
    updateType: '',
    deleteType: '',
    activeType: '',
    typeData: {
        edit: false,
        type: '',
        name_en: '',
        name_el: '',
        desc_en: '',
        desc_el: '',
        color: '',
        icon: '',
        property_category_ids: [],
        query_name: '',
        active_inactive: 1,
        category: '',
        roles: [],
        month_one: null,
        month_two: null,
        month_three: null,
        month_four: null,
        url: '',
        image: '',
        image_file: ''
    },
    dataFields: {
        property_categories: [],
        activity_categories: [],
        roles: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const typeSlice = createSlice({
    name: 'type',
    initialState,
    reducers: {
        fetchTypesSuccess(state, { payload }) {
            return { ...state, loading: false, types: payload, error: '' };
        },
        clearType(state) {
            return { ...state, loadingBtn: false, loading: false, typeData: initialState.typeData, error: '' };
        },
        clearAlertType(state) {
            return { ...state, updateType: '', createType: '', deleteType: '', activeType: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchTypes.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchTypes.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, types: payload, error: '' };
      },
      [startFetchTypes.rejected]: (state, { payload }) => {
        return { ...state, loading: false, types: initialState.types, error: payload };
      },

      [startFetchType.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchType.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, typeData: payload, error: '' };
      },
      [startFetchType.rejected]: (state, { payload }) => {
        return { ...state, loading: false, typeData: '', error: payload };
      },
      
      [startCreateType.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateType.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createType: payload, error: '' };
      },
      [startCreateType.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createType: '', error: payload };
      },

      [startUpdateType.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateType.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateType: payload, error: '' };
      },
      [startUpdateType.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateType: '', error: payload };
      },

      [startDeleteType.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteType: payload, error: '' };
      },
      [startDeleteType.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteType: '', error: payload };
      },

      [startActiveType.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeType: payload, error: '' };
      },
      [startActiveType.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeType: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },  
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const typeActions = {
    ...typeSlice.actions,
    startFetchTypes,
    startFetchType,
    startCreateType,
    startUpdateType,
    startDeleteType,
    startActiveType,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default typeSlice.reducer