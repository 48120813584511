import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, ARTICLE_CATEGORIES } from '../../constants/defaultValues';

const startFetchArticleCategories = createAsyncThunk('articleCategory/startFetchArticleCategories', async ({ locale, token, filter, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${ARTICLE_CATEGORIES}?portalId=${portal_id}&pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateArticleCategory = createAsyncThunk('articleCategory/startCreateArticleCategory', async ({ article_category, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${ARTICLE_CATEGORIES}/store`, article_category, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateArticleCategory = createAsyncThunk('articleCategory/startUpdateArticleCategory', async ({ id, article_category, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${ARTICLE_CATEGORIES}/update/${id}`, article_category, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteArticleCategory = createAsyncThunk('articleCategory/startDeleteArticleCategory', async ({ locale, token, id, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${ARTICLE_CATEGORIES}/destroy/${portal_id}/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveArticleCategory = createAsyncThunk('articleCategory/startActiveArticleCategory', async ({ locale, token, id, action, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${ARTICLE_CATEGORIES}/active/${id}`, { action: action, portal_id: portal_id }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchArticleCategory = createAsyncThunk('articleCategory/startFetchArticleCategory', async ({ portal_id, article_category, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ARTICLE_CATEGORIES}/edit/${portal_id}/${article_category}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            portal_id: data.portal_id,
            url_en: data.url_en,
            url_el: data.url_el,
            url_de: data.url_de,
            url_sr: data.url_sr,
            url_bg: data.url_bg,
            url_ru: data.url_ru,
            title_en: data.title_en,
            title_el: data.title_el,
            title_de: data.title_de,
            title_sr: data.title_sr,
            title_bg: data.title_bg,
            title_ru: data.title_ru,
            meta_title_en: data.page_config ? data.page_config.meta_title_en : '',
            meta_title_el: data.page_config ? data.page_config.meta_title_el : '',
            meta_title_de: data.page_config ? data.page_config.meta_title_de : '',
            meta_title_sr: data.page_config ? data.page_config.meta_title_sr : '',
            meta_title_bg: data.page_config ? data.page_config.meta_title_bg : '',
            meta_title_ru: data.page_config ? data.page_config.meta_title_ru : '',
            meta_desc_en: data.page_config ? data.page_config.meta_desc_en : '',
            meta_desc_el: data.page_config ? data.page_config.meta_desc_el : '',
            meta_desc_de: data.page_config ? data.page_config.meta_desc_de: '',
            meta_desc_sr: data.page_config ? data.page_config.meta_desc_sr : '',
            meta_desc_bg: data.page_config ? data.page_config.meta_desc_bg : '',
            meta_desc_ru: data.page_config ? data.page_config.meta_desc_ru : '',
            meta_lsi_en: data.page_config ? data.page_config.meta_lsi_en : [],
            meta_lsi_el: data.page_config ? data.page_config.meta_lsi_el : [],
            meta_lsi_de: data.page_config ? data.page_config.meta_lsi_de : [],
            meta_lsi_sr: data.page_config ? data.page_config.meta_lsi_sr : [],
            meta_lsi_bg: data.page_config ? data.page_config.meta_lsi_bg : [],
            meta_lsi_ru: data.page_config ? data.page_config.meta_lsi_ru : [],
            sort: data.sort
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('articleCategory/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('articleCategory/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('articleCategory/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    articleCategories: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    articleCategoryData: {
        portal_id: '',
        url_en: '',
        url_el: '',
        url_de: '',
        url_sr: '',
        url_bg: '',
        url_ru: '',
        title_en: '',
        title_el: '',
        title_de: '',
        title_sr: '',
        title_bg: '',
        title_ru: '',
        meta_title_en: '',
        meta_title_el: '',
        meta_title_de: '',
        meta_title_sr: '',
        meta_title_bg: '',
        meta_title_ru: '',
        meta_desc_en: '',
        meta_desc_el: '',
        meta_desc_de: '',
        meta_desc_sr: '',
        meta_desc_bg: '',
        meta_desc_ru: '',
        meta_lsi_en: [],
        meta_lsi_el: [],
        meta_lsi_de: [],
        meta_lsi_sr: [],
        meta_lsi_bg: [],
        meta_lsi_ru: [],
        sort: 0
    },
    createArticleCategory: '',
    updateArticleCategory: '',
    deleteArticleCategory: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const articleCategorySlice = createSlice({
    name: 'articleCategory',
    initialState,
    reducers: {
        fetchArticleCategoriesSuccess(state, { payload }) {
            return { ...state, loading: false, articleCategories: payload, error: '' };
        },
        clearArticleCategory(state) {
            return { ...state, loadingBtn: false, loading: false, articleCategoryData: initialState.articleCategoryData, error: '' };
        },
        clearAlertArticleCategory(state) {
            return { ...state, updateArticleCategory: '', createArticleCategory: '', deleteArticleCategory: '', activeArticleCategory: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchArticleCategories.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchArticleCategories.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, articleCategories: payload, error: '' };
      },
      [startFetchArticleCategories.rejected]: (state, { payload }) => {
        return { ...state, loading: false, articleCategories: initialState.articleCategories, error: payload };
      },

      [startFetchArticleCategory.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchArticleCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, articleCategoryData: payload, error: '' };
      },
      [startFetchArticleCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, articleCategoryData: '', error: payload };
      },
      
      [startCreateArticleCategory.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateArticleCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createArticleCategory: payload, error: '' };
      },
      [startCreateArticleCategory.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createArticleCategory: '', error: payload };
      },

      [startUpdateArticleCategory.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateArticleCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateArticleCategory: payload, error: '' };
      },
      [startUpdateArticleCategory.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateArticleCategory: '', error: payload };
      },

      [startDeleteArticleCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteArticleCategory: payload, error: '' };
      },
      [startDeleteArticleCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteArticleCategory: '', error: payload };
      },

      [startActiveArticleCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeArticleCategory: payload, error: '' };
      },
      [startActiveArticleCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeArticleCategory: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const articleCategoryActions = {
    ...articleCategorySlice.actions,
    startFetchArticleCategories,
    startFetchArticleCategory,
    startCreateArticleCategory,
    startUpdateArticleCategory,
    startDeleteArticleCategory,
    startActiveArticleCategory,
    setSearch,
    setCurrentPage,
    setSort
}
export default articleCategorySlice.reducer