import { createSlice } from '@reduxjs/toolkit' 
import { defaultMenuType, subHiddenBreakpoint, menuHiddenBreakpoint } from '../../constants/defaultValues'

const initialState = {
    containerClassnames: defaultMenuType,
	subHiddenBreakpoint,
	menuHiddenBreakpoint,
	menuClickCount: 0,
	selectedMenuHasSubItems: defaultMenuType === "menu-default" //if you use menu-sub-hidden as default menu type, set value of this variable to false
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        changeSelectedMenuHasSubItems(state, { payload }) {
            state.selectedMenuHasSubItems= payload
        },
        changeDefaultClassnames(state, { payload }) {
            state.containerClassnames = payload
        },
        addContainerClassname(state, { payload }) {
            let { classname, strCurrentClasses } = payload;
            const newClasses = !strCurrentClasses.indexOf(classname) > -1 ? strCurrentClasses + ' ' + classname : strCurrentClasses;

            state.containerClassnames = newClasses
        },
        clickOnMobileMenu(state, { payload }) {
            const currentClasses = payload ? payload.split(' ').filter(x => x !== '' && x !== 'sub-show-temporary') : '';

            let nextClasses = '';
            if (currentClasses.includes('main-show-temporary')) {
                nextClasses = (currentClasses.filter(x => x !== 'main-show-temporary')).join(' ');
            } else {
                nextClasses = currentClasses.join(' ') + ' main-show-temporary';
            }

            state.containerClassnames = nextClasses
            state.menuClickCount = 0
        },
        setContainerClassnames(state, { payload }) {
            let { clickIndex, strCurrentClasses, selectedMenuHasSubItems } = payload;
            const currentClasses = strCurrentClasses ? strCurrentClasses.split(' ').filter(x => x !== '') : '';
            let nextClasses = '';

            if (!selectedMenuHasSubItems) {
                if (currentClasses.includes("menu-default") && (clickIndex % 4 === 0 || clickIndex % 4 === 3)) {
                    clickIndex = 1;
                }
                if (currentClasses.includes("menu-sub-hidden") && (clickIndex % 4 === 2)) {
                    clickIndex = 0;
                }
                if (currentClasses.includes("menu-hidden") && (clickIndex % 4 === 2 || clickIndex % 4 === 3)) {
                    clickIndex = 0;
                }
        
            }

            if (clickIndex % 4 === 0) {
                if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
                    nextClasses = 'menu-default menu-sub-hidden';
                } else if (currentClasses.includes('menu-default')) {
                    nextClasses = 'menu-default';
                } else if (currentClasses.includes('menu-sub-hidden')) {
                    nextClasses = 'menu-sub-hidden';
                } else if (currentClasses.includes('menu-hidden')) {
                    nextClasses = 'menu-hidden';
                }
                clickIndex = 0;
            } else if (clickIndex % 4 === 1) {
                if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
                    nextClasses = 'menu-default menu-sub-hidden main-hidden sub-hidden';
                } else if (currentClasses.includes('menu-default')) {
                    nextClasses = 'menu-default sub-hidden';
                } else if (currentClasses.includes('menu-sub-hidden')) {
                    nextClasses = 'menu-sub-hidden main-hidden sub-hidden';
                } else if (currentClasses.includes('menu-hidden')) {
                    nextClasses = 'menu-hidden main-show-temporary';
                }
            } else if (clickIndex % 4 === 2) {
                if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
                    nextClasses = 'menu-default menu-sub-hidden sub-hidden';
                } else if (currentClasses.includes('menu-default')) {
                    nextClasses = 'menu-default main-hidden sub-hidden';
                } else if (currentClasses.includes('menu-sub-hidden')) {
                    nextClasses = 'menu-sub-hidden sub-hidden';
                } else if (currentClasses.includes('menu-hidden')) {
                    nextClasses = 'menu-hidden main-show-temporary sub-show-temporary';
                }
            } else if (clickIndex % 4 === 3) {
                if (currentClasses.includes('menu-default') && currentClasses.includes('menu-sub-hidden')) {
                    nextClasses = 'menu-default menu-sub-hidden sub-show-temporary';
                } else if (currentClasses.includes('menu-default')) {
                    nextClasses = 'menu-default sub-hidden';
                } else if (currentClasses.includes('menu-sub-hidden')) {
                    nextClasses = 'menu-sub-hidden sub-show-temporary';
                } else if (currentClasses.includes('menu-hidden')) {
                    nextClasses = 'menu-hidden main-show-temporary';
                }
            }

            if (currentClasses.includes('menu-mobile')) {
                nextClasses += ' menu-mobile';
            }

            state.containerClassnames = nextClasses
            state.menuClickCount = clickIndex
        }
    }
});

export const { changeSelectedMenuHasSubItems, changeDefaultClassnames, addContainerClassname, clickOnMobileMenu, setContainerClassnames } = menuSlice.actions;
export default menuSlice.reducer