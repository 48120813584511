import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, CLOSED_DAYS } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('closedDay/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CLOSED_DAYS}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchClosedDays = createAsyncThunk('closedDay/startFetchClosedDays', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${CLOSED_DAYS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateClosedDay = createAsyncThunk('closedDay/startCreateClosedDay', async ({ closedDay, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CLOSED_DAYS}/store`, closedDay, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateClosedDay = createAsyncThunk('closedDay/startUpdateClosedDay', async ({ id, closedDay, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CLOSED_DAYS}/update/${id}`, closedDay, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteClosedDay = createAsyncThunk('closedDay/startDeleteClosedDay', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${CLOSED_DAYS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveClosedDay = createAsyncThunk('closedDay/startActiveClosedDay', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${CLOSED_DAYS}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchClosedDay = createAsyncThunk('closedDay/startFetchClosedDay', async ({ closedDay, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CLOSED_DAYS}/edit/${closedDay}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            name: data.name,
            close_start: new Date(data.close_start),
            close_end: new Date(data.close_end),
            dayOff_or_holiday: data.dayOff_or_holiday,
            users: data.users
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('closedDay/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('closedDay/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('closedDay/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    closedDays: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{ desc: false, id: "id" }],
    createClosedDay: '',
    updateClosedDay: '',
    activeClosedDay: '',
    deleteClosedDay: '',
    closedDayData: {
        edit: false,
        name: '',
        close_start: null,
        close_end: null,
        dayOff_or_holiday: 2,
        users: []
    },
    dataFields: {
        users: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const closedDaySlice = createSlice({
    name: 'closedDay',
    initialState,
    reducers: {
        fetchClosedDaysSuccess(state, { payload }) {
            return { ...state, loading: false, closedDays: payload, error: '' };
        },
        clearClosedDay(state) {
            return { ...state, loadingBtn: false, loading: false, closedDayData: initialState.closedDayData, error: '' };
        },
        clearAlertClosedDay(state) {
            return { ...state, updateClosedDay: '', createClosedDay: '', deleteClosedDay: '', activeClosedDay: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchClosedDays.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchClosedDays.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, closedDays: payload, error: '' };
      },
      [startFetchClosedDays.rejected]: (state, { payload }) => {
        return { ...state, loading: false, closedDays: initialState.closedDays, error: payload };
      },

      [startFetchClosedDay.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchClosedDay.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, closedDayData: payload, error: '' };
      },
      [startFetchClosedDay.rejected]: (state, { payload }) => {
        return { ...state, loading: false, closedDayData: '', error: payload };
      },
      
      [startCreateClosedDay.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateClosedDay.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createClosedDay: payload, error: '' };
      },
      [startCreateClosedDay.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createClosedDay: '', error: payload };
      },

      [startUpdateClosedDay.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateClosedDay.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateClosedDay: payload, error: '' };
      },
      [startUpdateClosedDay.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateClosedDay: '', error: payload };
      },

      [startDeleteClosedDay.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteClosedDay: payload, error: '' };
      },
      [startDeleteClosedDay.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteClosedDay: '', error: payload };
      },

      [startActiveClosedDay.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeClosedDay: payload, error: '' };
      },
      [startActiveClosedDay.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeClosedDay: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const closedDayActions = {
    ...closedDaySlice.actions,
    startFetchClosedDays,
    startFetchClosedDay,
    startCreateClosedDay,
    startUpdateClosedDay,
    startDeleteClosedDay,
    startActiveClosedDay,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default closedDaySlice.reducer