import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';

import authUserReducer from './auth/authUserSlice';
import settingsReducer from './settings/settingsSlice';
import menuReducer from './menu/menuSlice';
import notificationReducer from './notifications/notificationSlice';
import bugReducer from './bugs/bugSlice';
import activityReducer from './activity/activitySlice';
import startReducer from './start/startSlice';
import calendarReducer from './calendar/calendarSlice';
import taskReducer from './tasks/taskSlice';
import fileTemplateReducer from './file-template/fileTemplateSlice';
import knowledgeDatabaseReducer from './knowledge-database/knowledgeDatabaseSlice';
import knowledgeDatabaseCommentsReducer from './knowledgeDatabaseComments/knowledgeDatabaseCommentSlice';
import absenceReducer from './absences/absenceSlice';
import liveGoalReducer from './liveGoals/liveGoalSlice';
import campaignReducer from './campaigns/campaignSlice';
import permissionReducer from './permissions/permissionSlice';
import roleReducer from './roles/roleSlice';
import userReducer from './users/userSlice';
import agencyReducer from './agency/agencySlice';
import cvReducer from './cv/cvSlice';
import experienceReducer from './experience/experienceSlice';
import locationCategoryReducer from './location-category/locationCategorySlice';
import locationReducer from './location/locationSlice';
import parentMenuReducer from './parent-menu/parentMenuSlice';
import sectionMenuReducer from './section-menu/sectionMenuSlice';
import childrenMenuReducer from './children-menu/childrenMenuSlice';
import serviceCategoryReducer from './service-category/serviceCategorySlice';
import serviceReducer from './service/serviceSlice';
import articleCategoryReducer from './article-category/articleCategorySlice';
import articleReducer from './article/articleSlice';
import careerReducer from './career/careerSlice';
import homePageConfigReducer from './home-page-config/homePageConfigSlice';
import contactPageConfigReducer from './contact-page-config/contactPageConfigSlice';
import faqReducer from './faq/faqSlice';
import projectReducer from './project/projectSlice';
import partnerReducer from './partner/partnerSlice';
import ourTeamReducer from './ourTeam/ourTeamSlice';
import sellReducer from './sell/sellSlice';
import typeReducer from './types/typeSlice';
import typeSyncReducer from './typeSyncs/typeSyncSlice';
import contactScoreReducer from './contact-score/contactScoreSlice';
import propertyScoreReducer from './property-score/propertyScoreSlice';
import readinessScoreReducer from './readiness-score/readinessScoreSlice';
import quarterGoalReducer from './quarter-goals/quarterGoalSlice';
import dayOffReducer from './days-off/dayOffSlice';
import closedDayReducer from './closed-days/closedDaySlice';
import propertyCategoryReducer from './property-categories/propertyCategorySlice';
import propertySubcategoryReducer from './property-subcategories/propertySubcategorySlice';
import extraReducer from './extras/extraSlice';
import countryReducer from './countries/countrySlice';
import timezoneReducer from './timezones/timezoneSlice';
import propertyCountryReducer from './property-countries/propertyCountrySlice';
import propertyPrefectureReducer from './property-prefectures/propertyPrefectureSlice';
import propertyMunicipalityReducer from './property-municipalities/propertyMunicipalitySlice';
import propertyAreaReducer from './property-areas/propertyAreaSlice';
import complexReducer from './complexes/complexSlice';
import websitePropertyReducer from './website-properties/websitePropertySlice';
import assignmentReducer from './assignment/assignmentSlice';
import indicationReducer from './indication/indicationSlice';
import contactReducer from './contacts/contactSlice';
import requestReducer from './requests/requestSlice';
import requestsCommentsReducer from './requestsComments/requestsCommentsSlice';
import propertyReducer from './properties/propertySlice';
import propertiesCommentsReducer from './propertiesComments/propertiesCommentsSlice';

import propertyBucketReducer from './property-buckets/bucketSlice';
import propertyBucketCommentsReducer from './propertyBucketComments/propertyBucketCommentsSlice';

import requestBucketCommentsReducer from './requestBucketComments/requestBucketCommentsSlice';
import requestBucketReducer from './request-bucket/requestBucketSlice';

import legalAuditReducer from './legal-audit/legalAuditSlice';
import legalAuditCommentsReducer from './legalAuditComments/legalAuditCommentsSlice';

const persistConfig = {
  key: 'root',
  storage,
  transform: [
    createFilter('authUser', ['author'])
  ]
}

const rootReducer = combineReducers({
  authUser: authUserReducer,
  settings: settingsReducer,
  menu: menuReducer,
  notification: notificationReducer,
  bug: bugReducer,
  activity: activityReducer,
  start: startReducer,
  calendar: calendarReducer,
  task: taskReducer,
  fileTemplate: fileTemplateReducer,
  knowledgeDatabase: knowledgeDatabaseReducer,
  knowledgeDatabaseComments: knowledgeDatabaseCommentsReducer,
  absence: absenceReducer,
  liveGoal: liveGoalReducer,
  campaign: campaignReducer,
  permission: permissionReducer,
  role: roleReducer,
  user: userReducer,
  agency: agencyReducer,
  cv: cvReducer,
  experience: experienceReducer,
  locationCategory: locationCategoryReducer,
  location: locationReducer,
  parentMenu: parentMenuReducer,
  sectionMenu: sectionMenuReducer,
  childrenMenu: childrenMenuReducer,
  serviceCategory: serviceCategoryReducer,
  service: serviceReducer,
  articleCategory: articleCategoryReducer,
  article: articleReducer,
  career: careerReducer,
  homePageConfig: homePageConfigReducer,
  contactPageConfig: contactPageConfigReducer,
  faq: faqReducer,
  project: projectReducer,
  partner: partnerReducer,
  ourTeam: ourTeamReducer,
  sell: sellReducer,
  type: typeReducer,
  typeSync: typeSyncReducer,
  contactScore: contactScoreReducer,
  propertyScore: propertyScoreReducer,
  readinessScore: readinessScoreReducer,
  quarterGoal: quarterGoalReducer,
  dayOff: dayOffReducer,
  closedDay: closedDayReducer,
  propertyCategory: propertyCategoryReducer,
  propertySubcategory: propertySubcategoryReducer,
  extra: extraReducer,
  country: countryReducer,
  timezone: timezoneReducer,
  propertyCountry: propertyCountryReducer,
  propertyPrefecture: propertyPrefectureReducer,
  propertyMunicipality: propertyMunicipalityReducer,
  propertyArea: propertyAreaReducer,
  complex: complexReducer,
  websiteProperty: websitePropertyReducer,
  assignment: assignmentReducer,
  indication: indicationReducer,
  contact: contactReducer,
  request: requestReducer,
  requestsComments: requestsCommentsReducer,
  property: propertyReducer,
  propertyBucket: propertyBucketReducer,
  propertiesComments: propertiesCommentsReducer,
  propertyBucketComments: propertyBucketCommentsReducer,
  requestBucketComments: requestBucketCommentsReducer,
  requestBucket: requestBucketReducer,
  legalAudit: legalAuditReducer,
  legalAuditComments: legalAuditCommentsReducer,
});

export default persistReducer(persistConfig, rootReducer);