import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, SERVICE_CATEGORIES } from '../../constants/defaultValues';

const startFetchServiceCategories = createAsyncThunk('serviceCategory/startFetchServiceCategories', async ({ locale, token, filter, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${SERVICE_CATEGORIES}?portalId=${portal_id}&pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateServiceCategory = createAsyncThunk('serviceCategory/startCreateServiceCategory', async ({ service_category, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${SERVICE_CATEGORIES}/store`, service_category, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateServiceCategory = createAsyncThunk('serviceCategory/startUpdateServiceCategory', async ({ id, service_category, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${SERVICE_CATEGORIES}/update/${id}`, service_category, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteServiceCategory = createAsyncThunk('serviceCategory/startDeleteServiceCategory', async ({ locale, token, id, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${SERVICE_CATEGORIES}/destroy/${portal_id}/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveServiceCategory = createAsyncThunk('serviceCategory/startActiveServiceCategory', async ({ locale, token, id, action, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${SERVICE_CATEGORIES}/active/${id}`, { action: action, portal_id: portal_id }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchServiceCategory = createAsyncThunk('serviceCategory/startFetchServiceCategory', async ({ portal_id, service_category, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${SERVICE_CATEGORIES}/edit/${portal_id}/${service_category}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            portal_id: data.portal_id,
            url_en: data.url_en,
            url_el: data.url_el,
            url_de: data.url_de,
            url_sr: data.url_sr,
            url_bg: data.url_bg,
            url_ru: data.url_ru,
            title_en: data.title_en,
            title_el: data.title_el,
            title_de: data.title_de,
            title_sr: data.title_sr,
            title_bg: data.title_bg,
            title_ru: data.title_ru,
            meta_title_en: data.page_config ? data.page_config.meta_title_en : '',
            meta_title_el: data.page_config ? data.page_config.meta_title_el : '',
            meta_title_de: data.page_config ? data.page_config.meta_title_de : '',
            meta_title_sr: data.page_config ? data.page_config.meta_title_sr : '',
            meta_title_bg: data.page_config ? data.page_config.meta_title_bg : '',
            meta_title_ru: data.page_config ? data.page_config.meta_title_ru : '',
            meta_desc_en: data.page_config ? data.page_config.meta_desc_en : '',
            meta_desc_el: data.page_config ? data.page_config.meta_desc_el : '',
            meta_desc_de: data.page_config ? data.page_config.meta_desc_de: '',
            meta_desc_sr: data.page_config ? data.page_config.meta_desc_sr : '',
            meta_desc_bg: data.page_config ? data.page_config.meta_desc_bg : '',
            meta_desc_ru: data.page_config ? data.page_config.meta_desc_ru : '',
            meta_lsi_en: data.page_config ? data.page_config.meta_lsi_en : [],
            meta_lsi_el: data.page_config ? data.page_config.meta_lsi_el : [],
            meta_lsi_de: data.page_config ? data.page_config.meta_lsi_de : [],
            meta_lsi_sr: data.page_config ? data.page_config.meta_lsi_sr : [],
            meta_lsi_bg: data.page_config ? data.page_config.meta_lsi_bg : [],
            meta_lsi_ru: data.page_config ? data.page_config.meta_lsi_ru : [],
            sort: data.sort,
            template: data.template,
            image: data.image,
            image_name: data.image,
            file: ''
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('serviceCategory/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('serviceCategory/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('serviceCategory/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    serviceCategories: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    serviceCategoryData: {
        portal_id: '',
        url_en: '',
        url_el: '',
        url_de: '',
        url_sr: '',
        url_bg: '',
        url_ru: '',
        title_en: '',
        title_el: '',
        title_de: '',
        title_sr: '',
        title_bg: '',
        title_ru: '',
        meta_title_en: '',
        meta_title_el: '',
        meta_title_de: '',
        meta_title_sr: '',
        meta_title_bg: '',
        meta_title_ru: '',
        meta_desc_en: '',
        meta_desc_el: '',
        meta_desc_de: '',
        meta_desc_sr: '',
        meta_desc_bg: '',
        meta_desc_ru: '',
        meta_lsi_en: [],
        meta_lsi_el: [],
        meta_lsi_de: [],
        meta_lsi_sr: [],
        meta_lsi_bg: [],
        meta_lsi_ru: [],
        sort: 0,
        template: 1,
        image: '',
        image_name: '',
        file: ''
    },
    createServiceCategory: '',
    updateServiceCategory: '',
    deleteServiceCategory: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const serviceCategorySlice = createSlice({
    name: 'serviceCategory',
    initialState,
    reducers: {
        fetchServiceCategoriesSuccess(state, { payload }) {
            return { ...state, loading: false, serviceCategories: payload, error: '' };
        },
        clearServiceCategory(state) {
            return { ...state, loadingBtn: false, loading: false, serviceCategoryData: initialState.serviceCategoryData, error: '' };
        },
        clearAlertServiceCategory(state) {
            return { ...state, updateServiceCategory: '', createServiceCategory: '', deleteServiceCategory: '', activeServiceCategory: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchServiceCategories.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchServiceCategories.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, serviceCategories: payload, error: '' };
      },
      [startFetchServiceCategories.rejected]: (state, { payload }) => {
        return { ...state, loading: false, serviceCategories: initialState.serviceCategories, error: payload };
      },

      [startFetchServiceCategory.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchServiceCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, serviceCategoryData: payload, error: '' };
      },
      [startFetchServiceCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, serviceCategoryData: '', error: payload };
      },
      
      [startCreateServiceCategory.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateServiceCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createServiceCategory: payload, error: '' };
      },
      [startCreateServiceCategory.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createServiceCategory: '', error: payload };
      },

      [startUpdateServiceCategory.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateServiceCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateServiceCategory: payload, error: '' };
      },
      [startUpdateServiceCategory.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateServiceCategory: '', error: payload };
      },

      [startDeleteServiceCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteServiceCategory: payload, error: '' };
      },
      [startDeleteServiceCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteServiceCategory: '', error: payload };
      },

      [startActiveServiceCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeServiceCategory: payload, error: '' };
      },
      [startActiveServiceCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeServiceCategory: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const serviceCategoryActions = {
    ...serviceCategorySlice.actions,
    startFetchServiceCategories,
    startFetchServiceCategory,
    startCreateServiceCategory,
    startUpdateServiceCategory,
    startDeleteServiceCategory,
    startActiveServiceCategory,
    setSearch,
    setCurrentPage,
    setSort
}
export default serviceCategorySlice.reducer