import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, TIMEZONES } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('timezone/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TIMEZONES}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchTimezones = createAsyncThunk('timezone/startFetchTimezones', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${TIMEZONES}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateTimezone = createAsyncThunk('timezone/startCreateTimezone', async ({ timezone, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${TIMEZONES}/store`, timezone, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateTimezone = createAsyncThunk('timezone/startUpdateTimezone', async ({ id, timezone, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${TIMEZONES}/update/${id}`, timezone, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteTimezone = createAsyncThunk('timezone/startDeleteTimezone', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${TIMEZONES}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveTimezone = createAsyncThunk('timezone/startActiveTimezone', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${TIMEZONES}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchTimezone = createAsyncThunk('timezone/startFetchTimezone', async ({ timezone, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TIMEZONES}/edit/${timezone}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            zone: data.zone,
            time: data.time,
            country_id: data.country_id
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('timezone/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('timezone/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('timezone/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    timezones: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    createTimezone: '',
    updateTimezone: '',
    deleteTimezone: '',
    activeTimezone: '',
    timezoneData: {
        edit:false,
        zone: '',
        time: '',
        country_id: ''
    },
    dataFields: {
        countries: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const timezoneSlice = createSlice({
    name: 'timezone',
    initialState,
    reducers: {
        fetchTimezonesSuccess(state, { payload }) {
            return { ...state, loading: false, timezones: payload, error: '' };
        },
        clearTimezone(state) {
            return { ...state, loadingBtn: false, loading: false, timezoneData: initialState.timezoneData, error: '' };
        },
        clearAlertTimezone(state) {
            return { ...state, updateTimezone: '', createTimezone: '', deleteTimezone: '', activeTimezone: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchTimezones.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchTimezones.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, timezones: payload, error: '' };
      },
      [startFetchTimezones.rejected]: (state, { payload }) => {
        return { ...state, loading: false, timezones: initialState.timezones, error: payload };
      },

      [startFetchTimezone.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchTimezone.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, timezoneData: payload, error: '' };
      },
      [startFetchTimezone.rejected]: (state, { payload }) => {
        return { ...state, loading: false, timezoneData: '', error: payload };
      },
      
      [startCreateTimezone.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateTimezone.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createTimezone: payload, error: '' };
      },
      [startCreateTimezone.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createTimezone: '', error: payload };
      },

      [startUpdateTimezone.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateTimezone.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateTimezone: payload, error: '' };
      },
      [startUpdateTimezone.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateTimezone: '', error: payload };
      },

      [startDeleteTimezone.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteTimezone: payload, error: '' };
      },
      [startDeleteTimezone.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteTimezone: '', error: payload };
      },

      [startActiveTimezone.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeTimezone: payload, error: '' };
      },
      [startActiveTimezone.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeTimezone: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const timezoneActions = {
    ...timezoneSlice.actions,
    startFetchTimezones,
    startFetchTimezone,
    startCreateTimezone,
    startUpdateTimezone,
    startDeleteTimezone,
    startActiveTimezone,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default timezoneSlice.reducer