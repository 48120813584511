import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, AGENCY } from '../../constants/defaultValues';

const startFetchAgencies = createAsyncThunk('agency/startFetchAgencies', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${AGENCY}?pageSize=${filter.pageSize}&currentPage=${filter.currentPage}&orderBy=${filter.orderBy}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateAgency = createAsyncThunk('agency/startCreateAgency', async ({ agency, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${AGENCY}/store`, agency, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateAgency = createAsyncThunk('agency/startUpdateAgency', async ({ id, agency, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${AGENCY}/update/${id}`, agency, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('agency/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${AGENCY}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAgency = createAsyncThunk('agency/startFetchAgency', async ({ agency, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${AGENCY}/edit/${agency}`, { headers });

        const data = res.data;

        const results = {
          edit: true,
          company_name: data.company_name,
          business_name: data.business_name,
          code: data.phone.split(' ')[0],
          phone: data.phone.split(' ')[1],
          web: data.web ? data.web : '',
          email: data.email,
          vat: data.vat,
          validateVat: data.validateVat,
          tax: data.tax ? data.tax : "",
          address: data.address,
          image: data.image ? data.image : '',
          file: '',
          country: data.country,
          iso: data.iso,
          timezone: data.timezone,
          notes: data.notes ? data.notes : '',
          reg_nomber: data.reg_nomber ? data.reg_nomber : "",
          description: data.description ? data.description : '',
          lat: Number(data.lat),
          lng: Number(data.lng),
          fb: data.insta ? data.insta : '',
          insta: data.insta ? data.insta : '',
          linkedin: data.linkedin ? data.linkedin : '',
          twitter: data.twitter ? data.twitter : ''
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteAgency = createAsyncThunk('agency/startDeleteAgency', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${AGENCY}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveAgency = createAsyncThunk('agency/startActiveAgency', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${AGENCY}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCheckVat = createAsyncThunk('agency/startCheckVat', async ({ countryCode, vatNumber, setFieldValue, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${AGENCY}/checkVat`, { countryCode: countryCode, vatNumber: vatNumber }, { headers });

        if(res.data){
            setFieldValue('validateVat', 1);
        }

        return res.data;
    } catch (error) {
        setFieldValue('validateVat', 0);
        try {
            return rejectWithValue(error.response.data)
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});
const startAgencyInfo = createAsyncThunk('agency/startAgencyInfo', async ({ agency, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${AGENCY}/edit/${agency}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('agency/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('agency/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSelectedOrderOption = createAsyncThunk('agency/setSelectedOrderOption', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    agencies: {
        data: [],
        totalItemCount: 0
    },
    displayMode: "thumblist",
    selectedPageSize: 10,
    selectedOrderOption: { column: "id-asc", label: "ID ASC" },
    search: "",
    searchValue: "",
    currentPage: 1,
    agencyData: {
        company_name: '',
        business_name: '',
        phone: '',
        email: '',
        vat: '',
        validateVat: 0,
        tax: '',
        address: '',
        country: '',
        iso: '',
        code: '',
        timezone: '',
        web: '',
        image: '',
        file: '',
        notes: '',
        business_activity: '',
        reg_nomber: '',
        description: '',
        lat: '',
        lng: '',
        fb: '',
        insta: '',
        linkedin: '',
        twitter: ''
    },
    createAgency: '',
    updateAgency: '',
    deleteAgency: '',
    activeAgency: '',
    vatAgency: '',
    agencyInfo: '',
    dataFields: {
        countries: [],
        timezones: []
    },
    loadingBtn: false,
    loading: true,
    loadingModal: false,
    error: ''
}

export const agencySlice = createSlice({
    name: 'agency',
    initialState,
    reducers: {
        fetchAgenciesSuccess(state, { payload }) {
            return { ...state, loading: false, agencies: payload, error: '' };
        },
        clearAgent(state) {
            return { ...state, loadingBtn: false, loading: false, agencyData: initialState.agencyData, error: '' };
        },
        clearAlertAgent(state) {
            return { ...state, updateAgency: '', createAgency: '', deleteAgency: '', activeAgency: '', vatAgency: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        setDisplayMode(state, { payload }) {
            return { ...state, displayMode: payload };
        },
    },
    extraReducers: {
      [startFetchAgencies.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchAgencies.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, agencies: payload, error: '' };
      },
      [startFetchAgencies.rejected]: (state, { payload }) => {
        return { ...state, loading: false, agencies: initialState.agencies, error: payload };
      },

      [startCreateAgency.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateAgency.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createAgency: payload, error: '' };
      },
      [startCreateAgency.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createAgency: '', error: payload };
      },

      [startUpdateAgency.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateAgency.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateAgency: payload, error: '' };
      },
      [startUpdateAgency.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateAgency: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchAgency.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchAgency.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, agencyData: payload, error: '' };
      },
      [startFetchAgency.rejected]: (state, { payload }) => {
        return { ...state, loading: false, agencyData: '', error: payload };
      },

      
      [startDeleteAgency.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteAgency: payload, error: '' };
      },
      [startDeleteAgency.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteAgency: '', error: payload };
      },

      [startActiveAgency.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeAgency: payload, error: '' };
      },
      [startActiveAgency.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeAgency: '', error: payload };
      },

      [startCheckVat.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, vatAgency: payload, error: '' };
      },
      [startCheckVat.rejected]: (state, { payload }) => {
        return { ...state, loading: false, vatAgency: '', error: payload };   
      },

      [startAgencyInfo.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startAgencyInfo.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, agencyInfo: payload, error: '' };
      },
      [startAgencyInfo.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, agencyInfo: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSelectedOrderOption.fulfilled]: (state, { payload }) => {
        return { ...state, selectedOrderOption: payload, currentPage: 1 };
      },

    },
});


export const agencyActions = {
    ...agencySlice.actions,
    startFetchDataFields,
    startFetchAgencies,
    startCreateAgency,
    startUpdateAgency,
    startFetchAgency,
    startDeleteAgency,
    startActiveAgency,
    startCheckVat,
    startAgencyInfo,
    setSearch,
    setCurrentPage,
    setSelectedOrderOption
}
export default agencySlice.reducer