import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, HOME_PAGE_CONFIG } from '../../constants/defaultValues';

const startFetchHomePageConfig = createAsyncThunk('homePageConfig/startFetchHomePageConfig', async ({ portal_id, route_id, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${HOME_PAGE_CONFIG}/edit/${route_id}/${portal_id}`, { headers });

        const data = res.data;

        const results = {
            portal_id: data.portal_id,
            route_id: data.route_id,
            id: data.id,
            heading_title_en: data.page_config.heading_title_en,
            heading_title_el: data.page_config.heading_title_el,
            heading_title_de: data.page_config.heading_title_de,
            heading_title_sr: data.page_config.heading_title_sr,
            heading_title_bg: data.page_config.heading_title_bg,
            heading_title_ru: data.page_config.heading_title_ru,
            heading_subtitle_en: data.page_config.heading_subtitle_en,
            heading_subtitle_el: data.page_config.heading_subtitle_el,
            heading_subtitle_de: data.page_config.heading_subtitle_de,
            heading_subtitle_sr: data.page_config.heading_subtitle_sr,
            heading_subtitle_bg: data.page_config.heading_subtitle_bg,
            heading_subtitle_ru: data.page_config.heading_subtitle_ru,
            alt_image_en: data.page_config.alt_image_en,
            alt_image_el: data.page_config.alt_image_el,
            alt_image_de: data.page_config.alt_image_de,
            alt_image_sr: data.page_config.alt_image_sr,
            alt_image_bg: data.page_config.alt_image_bg,
            alt_image_ru: data.page_config.alt_image_ru,
            meta_title_en: data.page_config.meta_title_en,
            meta_title_el: data.page_config.meta_title_el,
            meta_title_de: data.page_config.meta_title_de,
            meta_title_sr: data.page_config.meta_title_sr,
            meta_title_bg: data.page_config.meta_title_bg,
            meta_title_ru: data.page_config.meta_title_ru,
            meta_desc_en: data.page_config.meta_desc_en,
            meta_desc_el: data.page_config.meta_desc_el,
            meta_desc_de: data.page_config.meta_desc_de,
            meta_desc_sr: data.page_config.meta_desc_sr,
            meta_desc_bg: data.page_config.meta_desc_bg,
            meta_desc_ru: data.page_config.meta_desc_ru,
            meta_lsi_en: data.page_config.meta_lsi_en,
            meta_lsi_el: data.page_config.meta_lsi_el,
            meta_lsi_de: data.page_config.meta_lsi_de,
            meta_lsi_sr: data.page_config.meta_lsi_sr,
            meta_lsi_bg: data.page_config.meta_lsi_bg,
            meta_lsi_ru: data.page_config.meta_lsi_ru,
            categoriesData: data.page_config.categoriesData,
            bannersData: data.page_config.bannersData,
            categoriesFiles: [],
            bannersFiles: [],
            image: data.page_config.image,
            image_name: data.page_config.image,
            file: ''
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateHomePageConfig = createAsyncThunk('homePageConfig/startUpdateHomePageConfig', async ({ id, home_page_config, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${HOME_PAGE_CONFIG}/update/${id}`, home_page_config, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const initialState = {
    homePageConfigData: {
        portal_id: '',
        route_id: '',
        id: '',
        heading_title_en: "",
        heading_title_el: "",
        heading_title_de: "",
        heading_title_sr: "",
        heading_title_bg: "",
        heading_title_ru: "",
        heading_subtitle_en: "",
        heading_subtitle_el: "",
        heading_subtitle_de: "",
        heading_subtitle_sr: "",
        heading_subtitle_bg: "",
        heading_subtitle_ru: "",
        alt_image_en: '',
        alt_image_el: '',
        alt_image_de: '',
        alt_image_sr: '',
        alt_image_bg: '',
        alt_image_ru: '',
        meta_title_en: '',
        meta_title_el: '',
        meta_title_de: '',
        meta_title_sr: '',
        meta_title_bg: '',
        meta_title_ru: '',
        meta_desc_en: '',
        meta_desc_el: '',
        meta_desc_de: '',
        meta_desc_sr: '',
        meta_desc_bg: '',
        meta_desc_ru: '',
        meta_lsi_en: [],
        meta_lsi_el: [],
        meta_lsi_de: [],
        meta_lsi_sr: [],
        meta_lsi_bg: [],
        meta_lsi_ru: [],
        categoriesData: [],
        bannersData: [],
        categoriesFiles: [],
        bannersFiles: [],
        image: '',
        image_name: '',
        file: ''
    },
    updateHomePageConfig: '',
    loadingBtn: false,
    loading: false,
    error: ''
}

export const homePageConfigSlice = createSlice({
    name: 'homePageConfig',
    initialState,
    reducers: {
        clearHomePageConfig(state) {
            return { ...state, loadingBtn: false, loading: false, homePageConfigData: initialState.homePageConfigData, error: '' };
        },
        clearAlertHomePageConfig(state) {
            return { ...state, updateHomePageConfig: '', error: '' };
        }
    },
    extraReducers: {
      [startFetchHomePageConfig.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchHomePageConfig.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, loadingBtn: false, homePageConfigData: payload, error: '' };
      },
      [startFetchHomePageConfig.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingBtn: false, homePageConfigData: initialState.homePageConfigData, error: payload };
      },

      [startUpdateHomePageConfig.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateHomePageConfig.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateHomePageConfig: payload, error: '' };
      },
      [startUpdateHomePageConfig.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateHomePageConfig: '', error: payload };
      },
    },
});


export const homePageConfigActions = {
    ...homePageConfigSlice.actions,
    startFetchHomePageConfig,
    startUpdateHomePageConfig
}
export default homePageConfigSlice.reducer