import { createSlice } from '@reduxjs/toolkit';
import { defaultLocale, localeOptions } from '../../constants/defaultValues';

const initialState = {
    locale: (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale,
    pusher: null
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        changeLocale(state, { payload }) {
            localStorage.setItem('currentLanguage', payload);
            return { ...state, locale: payload };
        },
        setPusher(state, { payload }) {
            return { ...state, pusher: payload };
        },
    },
    extraReducers: {}
});

export const { changeLocale, setPusher } = settingsSlice.actions
export default settingsSlice.reducer