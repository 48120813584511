import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, REQUEST_BUCKET } from '../../constants/defaultValues';

const startFetchSearchFields = createAsyncThunk('requestBucket/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${REQUEST_BUCKET}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateRequestBucket = createAsyncThunk('requestBucket/startCreateRequestBucket', async ({ token, locale, name, list }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST_BUCKET}/store`, { first_name: name }, { headers });

        let new_list = JSON.parse(JSON.stringify(list));

        new_list.data.unshift(res.data.data)
        new_list.totalItemCount = new_list.totalItemCount + 1;

        dispatch(requestBucketSlice.actions.fetchRequestBucketsSuccess(new_list))

        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateRequestBucket = createAsyncThunk('requestBucket/startUpdateRequestBucket', async ({ id, fieldKey, fieldValue, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST_BUCKET}/update/${id}`, { fieldKey: fieldKey, fieldValue: fieldValue }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchRequestBuckets = createAsyncThunk('requestBucket/startFetchRequestBuckets', async ({ locale, token, filter, loading, items, clear }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(requestBucketSlice.actions.actionFetchRequestBuckets(loading));
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${REQUEST_BUCKET}?
        &orderBy=${filter.orderBy}
        &search=${filter.search}
        &page=${filter.page}
    
        &gender_type=${filter.searchFilter.gender_type.length > 0 ? filter.searchFilter.gender_type.join() : ""}
        &status_type=${filter.searchFilter.status_type.length > 0 ? filter.searchFilter.status_type.join() : ""}
        &source_type=${filter.searchFilter.source_type.length > 0 ? filter.searchFilter.source_type.join() : ""}
        &spoken_lang_type=${filter.searchFilter.spoken_lang_type.length > 0 ? filter.searchFilter.spoken_lang_type.join() : ""}
        &category_type=${filter.searchFilter.category_type.length > 0 ? filter.searchFilter.category_type.join() : ""}
        &subcategory_type=${filter.searchFilter.subcategory_type.length > 0 ? filter.searchFilter.subcategory_type.join() : ""}
        &transaction_type=${filter.searchFilter.transaction_type.length > 0 ? filter.searchFilter.transaction_type.join() : ""}
        &country_type=${filter.searchFilter.country_type.length > 0 ? filter.searchFilter.country_type.join() : ""}
        &national_type=${filter.searchFilter.national_type.length > 0 ? filter.searchFilter.national_type.join() : ""}
        &prefecture_type=${filter.searchFilter.prefecture_type.length > 0 ? filter.searchFilter.prefecture_type.join() : ""}
        &municipality_type=${filter.searchFilter.municipality_type.length > 0 ? filter.searchFilter.municipality_type.join() : ""}
        &area_type=${filter.searchFilter.area_type.length > 0 ? filter.searchFilter.area_type.join() : ""}
        &priority_type=${filter.searchFilter.priority_type.length > 0 ? filter.searchFilter.priority_type.join() : ""}
        &stages_type=${filter.searchFilter.stages_type.length > 0 ? filter.searchFilter.stages_type.join() : ""}
        &first_call_type=${filter.searchFilter.first_call_type.length > 0 ? filter.searchFilter.first_call_type.join() : ""}
        &action_web_type=${filter.searchFilter.action_web_type.length > 0 ? filter.searchFilter.action_web_type.join() : ""}
        &price_range_type=${filter.searchFilter.price_range_type.length > 0 ? filter.searchFilter.price_range_type.join() : ""}
        &portal_type=${filter.searchFilter.portal_type.length > 0 ? filter.searchFilter.portal_type.join() : ""}
        &sms_type=${filter.searchFilter.sms_type.length > 0 ? filter.searchFilter.sms_type.join() : ""}
        &createdUser_type=${filter.searchFilter.createdUser_type.length > 0 ? filter.searchFilter.createdUser_type.join() : ""}
        &assignUser_type=${filter.searchFilter.assignUser_type.length > 0 ? filter.searchFilter.assignUser_type.join() : ""}
        &fdsUser_type=${filter.searchFilter.fdsUser_type.length > 0 ? filter.searchFilter.fdsUser_type.join() : ""}

        &fromDateCreate_type=${filter.searchFilter.fromDateCreate_type}
        &toDateCreate_type=${filter.searchFilter.toDateCreate_type}
        &fromDateIncome_type=${filter.searchFilter.fromDateIncome_type}
        &toDateIncome_type=${filter.searchFilter.toDateIncome_type}
        &fromBudget_type=${filter.searchFilter.fromBudget_type}
        &toBudget_type=${filter.searchFilter.toBudget_type}
        `, { headers });

        let arrayData = {
            data: [],
            totalItemCount: 0
        };

        if(res.data.data.length > 0){
            let newItems = {...items};
  
            if(clear){
              arrayData = {
                data: res.data.data,
                totalItemCount: res.data.totalItemCount
              }
            }else{
              arrayData = {
                data: [...newItems.data, ...res.data.data],
                totalItemCount: res.data.totalItemCount
              }
            }
            dispatch(requestBucketSlice.actions.setStopFetch(false))
        }else{
            if(clear){}else{
                let newItems = {...items};

                arrayData = {
                    data: newItems.data,
                    totalItemCount: newItems.totalItemCount
                }
            }
            dispatch(requestBucketSlice.actions.setStopFetch(true))
        }

        return arrayData;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteRequestBucket = createAsyncThunk('requestBucket/startDeleteRequestBucket', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${REQUEST_BUCKET}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startOrderRequestBucket = createAsyncThunk('requestBucket/startOrderRequestBucket', async ({ locale, token, data }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST_BUCKET}/order`, { buckets: data }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteFile = createAsyncThunk('requestBucket/startDeleteFile', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${REQUEST_BUCKET}/destroyFile/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDuplicateRequestBucket = createAsyncThunk('requestBucket/startDuplicateRequestBucket', async ({ locale, token, id, index, items }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${REQUEST_BUCKET}/duplicate/${id}`, { headers });

        const newItems = [...items.data];

        const newItem = { ...newItems[index] };

        newItem['id'] = res.data.id;
        newItem['sort'] = 0;
        newItem['comments'] = 0;
        newItem['user_image'] = res.data.user_image;
        newItem['user_create'] = res.data.user_create;
        newItem['user_name'] = res.data.user_name;
        newItem['income_date'] = res.data.created_at;
        newItem['created_at'] = res.data.created_at;

        newItems.unshift(newItem);

        const newArray = {
          data: newItems,
          totalItemCount: items.totalItemCount + 1
        }

        dispatch(requestBucketSlice.actions.fetchRequestBucketsSuccess(newArray))

        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//FORM ACTIONS

const startFetchDataFormFields = createAsyncThunk('requestBucket/startFetchDataFormFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${REQUEST_BUCKET}/formFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchMatchProps = createAsyncThunk('requestBucket/startFetchMatchProps', async ({ locale, token, data }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST_BUCKET}/matchProperties`, data, { headers });

        return res.data.count;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSubcategory = createAsyncThunk('requestBucket/startFetchSubcategory', async ({ locale, token, category_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${REQUEST_BUCKET}/selectSubcategory?category_id=${category_id}`, { headers });

        return res.data.subcategories;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchMunicipalities = createAsyncThunk('requestBucket/startFetchMunicipalities', async ({ locale, token, prefecture_ids }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST_BUCKET}/getMunicipality`, { prefecture_ids: prefecture_ids }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAreas = createAsyncThunk('requestBucket/startFetchAreas', async ({ locale, token, prefecture_ids, municipality_ids }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST_BUCKET}/getArea`, { prefecture_ids: prefecture_ids, municipality_ids: municipality_ids }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateForm = createAsyncThunk('requestBucket/startCreateForm', async ({ request, locale, token, bucketItems, form, bucket_index, toggle, closeMenu }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST_BUCKET}/storeForm`, request, { headers });

        let new_list = JSON.parse(JSON.stringify(bucketItems));

        if(bucket_index !== null && Number(bucket_index) >= 0){
          new_list.data[bucket_index] = res.data.bucket;
        }else{
          new_list.data.unshift(res.data.bucket);
          new_list.totalItemCount = new_list.totalItemCount + 1;
        }

        dispatch(requestBucketSlice.actions.fetchRequestBucketsSuccess(new_list));

        form.resetForm();
        form.setStatus({ success: true });

        toggle();
        closeMenu();

        return res.data.status;
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});



//Heading Functions
const setSelectedOrderOption = createAsyncThunk('request/setSelectedOrderOption', ( args ) => {
    return args;
});

const setSearch = createAsyncThunk('request/setSearch', ( args ) => {
    return args;
});

const initialState = {
    items: {
        data: [],
        totalItemCount: 0
    },
    selectedOrderOption: "",
    search: "",
    searchValue: "",
    searchFilterType: 1,
    fields: [],
    searchFilter: [
        { translate: "general.status", id: "status_type", dataKey: "status_types", search: "", type: 1 },
        { translate: "general.gender", id: "gender_type", dataKey: "gender_types", search: "", type: 1 },
        { translate: "general.source", id: "source_type", dataKey: "source_types", search: "", type: 1 },
        { translate: "general.spoken-language", id: "spoken_lang_type", dataKey: "spoken_lang_types", search: "", type: 1 },
        { translate: "property.property-category", id: "category_type", dataKey: "category_types", search: "", type: 1 },
        { translate: "property.property-subcategory", id: "subcategory_type", dataKey: "subcategory_types", search: "", type: 1 },
        { translate: "property.property-transaction-types", id: "transaction_type", dataKey: "transaction_types", search: "", type: 1 },
        { translate: "general.country", id: "country_type", dataKey: "country_types", search: "", type: 1 },
        { translate: "general.national", id: "national_type", dataKey: "national_types", search: "", type: 1 },
        { translate: "general.prefectures", id: "prefecture_type", dataKey: "prefecture_types", search: "", type: 1 },
        { translate: "general.municipalities", id: "municipality_type", dataKey: "municipality_types", search: "", type: 1 },
        { translate: "general.areas", id: "area_type", dataKey: "area_types", search: "", type: 1 },
        { translate: "general.priority", id: "priority_type", dataKey: "priority_types", search: "", type: 1 },
        { translate: "menu.stage-list", id: "stages_type", dataKey: "stages_types", search: "", type: 1 },
        { translate: "general.first-call", id: "first_call_type", dataKey: "first_call_types", search: "", type: 1 },
        { translate: "general.action-web", id: "action_web_type", dataKey: "action_web_types", search: "", type: 1 },
        { translate: "general.price-range", id: "price_range_type", dataKey: "price_range_types", search: "", type: 1 },
        { translate: "general.agency", id: "portal_type", dataKey: "portal_types", search: "", type: 1 },
        { translate: "general.sms_mail", id: "sms_type", dataKey: "sms_types", search: "", type: 1 },
        { translate: "menu.create-user", id: "createdUser_type", dataKey: "createdUser_types", search: "", type: 1 },
        { translate: "general.assignee", id: "assignUser_type", dataKey: "assignUser_types", search: "", type: 1 },
        { translate: "general.fds", id: "fdsUser_type", dataKey: "fdsUser_types", search: "", type: 1 },

        { translate: "general.create-date", id: "create_date_type", dataKey: "", search: "", type: 1 },
        { translate: "general.income-date", id: "income_date_type", dataKey: "", search: "", type: 1 },
        { translate: "general.budget", id: "budget_type", dataKey: "", search: "", type: 1 },
    ],
    searchFilterValues: {
        status_type: [],
        gender_type: [],
        source_type: [],
        spoken_lang_type: [],
        category_type: [],
        subcategory_type: [],
        transaction_type: [],
        country_type: [],
        national_type: [],
        prefecture_type: [],
        municipality_type: [],
        area_type: [],
        priority_type: [],
        stages_type: [],
        first_call_type: [],
        action_web_type: [],
        price_range_type: [],
        portal_type: [],
        sms_type: [],
        createdUser_type: [],
        assignUser_type: [],
        fdsUser_type: [],

        fromDateCreate_type: "",
        toDateCreate_type: "",
        fromDateIncome_type: "",
        toDateIncome_type: "",
        fromBudget_type: "",
        toBudget_type: "",
    },
    formMutchProps: 0,
    formData: {
        bucket_id: '',
        contact_type: [],
        communication_channel: '',
        property_ids: '',
        sale_or_rent: 1,
        category_id: '',
        subcategories: [],
        luxury: null,
        prefectures: [],
        municipalities: [],
        areas: [],
        bedrooms: '',
        buildables: [],
        electricity: '',
        water: '',
        sea_accessibilities: '',
        budget_from: '',  
        budget_to: '',  
        priority_type_id: '',
        buy_priority_type_id: '',
        pools: [],
        views: [],
        sq_meters_from: '',
        sq_meters_to: '',
        elevator: '',
        complex_id: '',
        complexes: { id: '', complexes: [], loading: false },
        contacts: [],
        contact_id: '',
        name: '',
        surname: '',
        gender: '',
        country: '',
        email: '',
        phone: '',
        phoneCode: '',
        phoneCountry: '',
        phoneIso: '',
        mobile: '',
        mobileCountry: '',
        mobileCode: '',
        mobileIso: '',
        spoken_language: [],
        preferred_communication_id: [],
        age_range_id: '',
        cooperative_owner_id: '',
        contact_source: '',
        priority_pool: 0,
        operator: '',
        sendOffer: 1,
        notes: ''
    },
    formFields: {
        types: [],
        categories: [],
        prefectures: [],
        buildables: [],
        sea_accessibilities: [],
        priorities: [],
        buy_priorities: [],
        pools: [],
        views: [],
        genders: [],
        countries: [],
        preferredCommunications: [],
        languages: [],
        sources: [],
        ranges: [],
        cooperatives: [],
        operators: []
    },
    subcategory: [],
    municipalities: [],
    areas: [],
    createItem: '',
    updateItem: '',
    deleteItem: '',
    duplicateItem: '',
    orderItem: '',
    createForm: '',
    stopFetch: false,
    loadingBtn: false,
    loading: true,
    loadingSearch: false,
    loadingForm: true,
    loadingFormBtn: false,
    loadingFormMutchProps: false,
    error: ''
}

export const requestBucketSlice = createSlice({
    name: 'requestBucket',
    initialState,
    reducers: {
        fetchRequestBucketsSuccess(state, { payload }) {
            return { ...state, loading: false, loadingSearch: false, items: payload, error: '' };
        },
        clearRequestBucket(state) {
            return { ...state, stopFetch: false, loadingBtn: false, loading: false, loadingSearch: false, loadingFormMutchProps: false, itemData: initialState.itemData, error: '' };
        },
        clearAlertRequestBucket(state) {
            return { ...state, createItem: '', updateItem: '', deleteItem: '', duplicateItem: '', orderItem: '', deleteFile: '', createForm: '', error: '' };
        },

        setFilter(state, { payload }) {
            return { ...state, searchFilterValues: payload };
        },
        setFilterType(state, { payload }) {
            return { ...state, searchFilterType: payload };
        },
        setFilterFieldSearch(state, { payload }) {
            return { ...state, fields: payload.dataValues, searchFilter: payload.values };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        actionFetchRequestBuckets(state, { payload }) {
            return { ...state, loading: payload ? true : false, loadingSearch: true, error: '' };
        },
        clearForm(state) {
            return { ...state, loadingForm: false, loadingFormBtn: false, formData: initialState.formData, formMutchProps: initialState.formMutchProps,  createForm: '', error: '' };
        },
        dataSelectMunicipalities(state, { payload }) {
            return { ...state, municipalities: payload, error: '' };
        },
        dataSelectAreas(state, { payload }) {
            return { ...state, areas: payload, error: '' };
        },
        setStopFetch(state, { payload }) {
            return { ...state, stopFetch: payload }
        }
    },
    extraReducers: {
      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, fields: payload, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, fields: initialState.fields, error: payload };
      },

      [startCreateRequestBucket.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateRequestBucket.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createItem: payload, error: '' };
      },
      [startCreateRequestBucket.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createItem: '', error: payload };
      },

      [startUpdateRequestBucket.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateRequestBucket.fulfilled]: (state, { payload }) => {
        return { ...state, updateItem: payload, error: '' };
      },
      [startUpdateRequestBucket.rejected]: (state, { payload }) => {
        return { ...state, updateItem: '', error: payload };
      },

      [startFetchRequestBuckets.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, items: payload, error: '' };
      },
      [startFetchRequestBuckets.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, items: initialState.items, error: payload };
      },

      [startDeleteRequestBucket.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteItem: payload, error: '' };
      },
      [startDeleteRequestBucket.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteItem: '', error: payload };
      },

      [startDuplicateRequestBucket.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, duplicateItem: payload, error: '' };
      },
      [startDuplicateRequestBucket.rejected]: (state, { payload }) => {
        return { ...state, loading: false, duplicateItem: '', error: payload };
      },

      [startOrderRequestBucket.fulfilled]: (state, { payload }) => {
        return { ...state, orderItem: payload, error: '' };
      },
      [startOrderRequestBucket.rejected]: (state, { payload }) => {
        return { ...state, orderItem: '', error: payload };
      },

      [startDeleteFile.fulfilled]: (state, { payload }) => {
        return { ...state, deleteFile: payload, error: '' };
      },
      [startDeleteFile.rejected]: (state, { payload }) => {
        return { ...state, deleteFile: '', error: payload };
      },

      //FORM ACTIONS
      [startFetchDataFormFields.pending]: (state) => {
        return { ...state, loadingForm: true, error: '' };
      },
      [startFetchDataFormFields.fulfilled]: (state, { payload }) => {
        return { ...state, formFields: payload, error: '' };
      },
      [startFetchDataFormFields.rejected]: (state, { payload }) => {
        return { ...state, loadingForm: false, formFields: initialState.formFields, error: payload };
      },

      [startFetchMatchProps.pending]: (state) => {
        return { ...state, loadingFormMutchProps: true, error: '' };
      },
      [startFetchMatchProps.fulfilled]: (state, { payload }) => {
        return { ...state, loadingFormMutchProps: false, formMutchProps: payload, error: '' };
      },
      [startFetchMatchProps.rejected]: (state, { payload }) => {
        return { ...state, loadingFormMutchProps: false, formMutchProps: initialState.formMutchProps, error: payload };
      },


      [startFetchSubcategory.fulfilled]: (state, { payload }) => {
        return { ...state, subcategory: payload, error: '' };
      },
      [startFetchSubcategory.rejected]: (state, { payload }) => {
        return { ...state, subcategory: initialState.subcategory, error: payload };
      },

      [startFetchMunicipalities.fulfilled]: (state, { payload }) => {
        return { ...state, municipalities: payload, error: '' };
      },
      [startFetchMunicipalities.rejected]: (state, { payload }) => {
        return { ...state, municipalities: initialState.municipalities, error: payload };
      },

      [startFetchAreas.fulfilled]: (state, { payload }) => {
        return { ...state, areas: payload, error: '' };
      },
      [startFetchAreas.rejected]: (state, { payload }) => {
        return { ...state, areas: initialState.areas, error: payload };
      },

      [startCreateForm.pending]: (state) => {
        return { ...state, loadingFormBtn: true, error: '' };
      },
      [startCreateForm.fulfilled]: (state, { payload }) => {
        return { ...state, loadingFormBtn: false, createForm: payload, error: '' };
      },
      [startCreateForm.rejected]: (state, { payload }) => {
        return { ...state, loadingFormBtn: false, createForm: '', error: payload };
      },

      //Heading Functions
      [setSelectedOrderOption.fulfilled]: (state, { payload }) => {
        return { ...state, selectedOrderOption: payload, currentPage: 1 };
      },
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      }
    },
});


export const requestBucketActions = {
    ...requestBucketSlice.actions,
    startFetchSearchFields,
    startCreateRequestBucket,
    startUpdateRequestBucket,
    startFetchRequestBuckets,
    startDeleteRequestBucket,
    startDuplicateRequestBucket,
    startOrderRequestBucket,
    startDeleteFile,

    startFetchDataFormFields,
    startFetchMatchProps,
    startFetchSubcategory,
    startFetchMunicipalities,
    startFetchAreas,
    startCreateForm,
    
    setSelectedOrderOption,
    setSearch
}
export default requestBucketSlice.reducer