import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, LOCATIONS } from '../../constants/defaultValues';

const startFetchLocations = createAsyncThunk('location/startFetchLocations', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${LOCATIONS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateLocation = createAsyncThunk('location/startCreateLocation', async ({ location, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${LOCATIONS}/store`, location, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateLocation = createAsyncThunk('location/startUpdateLocation', async ({ id, location, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${LOCATIONS}/update/${id}`, location, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteLocation = createAsyncThunk('location/startDeleteLocation', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${LOCATIONS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveLocation = createAsyncThunk('location/startActiveLocation', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${LOCATIONS}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('location/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${LOCATIONS}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchLocation = createAsyncThunk('location/startFetchLocation', async ({ location, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${LOCATIONS}/edit/${location}`, { headers });

        const data = res.data;

        const results = {
          edit: true,
          id: data.id,
          location_category_id: data.location_category_id,
          template: data.template,
          image_or_yt: data.image_or_yt,
          bottom_img_yt_multiply: data.bottom_img_yt_multiply,
          extra_text: data.extra_text ? true : false,
          url_en: data.url_en,
          url_el: data.url_el,
          title_en: data.title_en,
          title_el: data.title_el,
          text_en: data.text_en,
          text_el: data.text_el,
          subtext_en: data.subtext_en,
          subtext_el: data.subtext_el,
          meta_title_en: data.page_config ? data.page_config.meta_title_en : '',
          meta_title_el: data.page_config ? data.page_config.meta_title_el : '',
          meta_desc_en: data.page_config ? data.page_config.meta_desc_en : '',
          meta_desc_el: data.page_config ? data.page_config.meta_desc_el : '',
          meta_lsi_en: data.page_config ? data.page_config.meta_lsi_en : [],
          meta_lsi_el: data.page_config ? data.page_config.meta_lsi_el : [],
          youtube_url: data.youtube_url,
          youtube_url_bottom: data.youtube_url_bottom,
          sort: data.sort,
          image: data.image,
          image_name: data.image,
          image_bottom: data.image_bottom,
          image_bottom_name: data.image_bottom_name,
          images_bottom_multiply: data.images_bottom_multiply
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('location/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('location/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('location/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    locations: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    locationData: {
        location_id: '',
        template: 1,
        image_or_yt: 0,
        bottom_img_yt_multiply: 0,
        extra_text: false,
        sort: 0,
        url_en: '',
        url_el: '',
        title_en: '',
        title_el: '',
        text_en: '',
        text_el: '',
        subtext_en: '',
        subtext_el: '',
        meta_title_en: '',
        meta_title_el: '',
        meta_desc_en: '',
        meta_desc_el: '',
        meta_lsi_en: [],
        meta_lsi_el: [],
        youtube_url: '',
        youtube_url_bottom: '',
        image: '',
        image_name: '',
        image_bottom: '',
        image_bottom_name: '',
        images_bottom_multiply: []
    },
    dataFields: {
        location_categories: []
    },
    createLocation: '',
    updateLocation: '',
    deleteLocation: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        fetchLocationsSuccess(state, { payload }) {
            return { ...state, loading: false, locations: payload, error: '' };
        },
        clearLocation(state) {
            return { ...state, loadingBtn: false, loading: false, locationData: initialState.locationData, error: '' };
        },
        clearAlertLocation(state) {
            return { ...state, updateLocation: '', createLocation: '', deleteLocation: '', activeLocation: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchLocations.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchLocations.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, locations: payload, error: '' };
      },
      [startFetchLocations.rejected]: (state, { payload }) => {
        return { ...state, loading: false, locations: initialState.locations, error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchLocation.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchLocation.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, locationData: payload, error: '' };
      },
      [startFetchLocation.rejected]: (state, { payload }) => {
        return { ...state, loading: false, locationData: '', error: payload };
      },
      
      [startCreateLocation.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateLocation.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createLocation: payload, error: '' };
      },
      [startCreateLocation.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createLocation: '', error: payload };
      },

      [startUpdateLocation.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateLocation.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateLocation: payload, error: '' };
      },
      [startUpdateLocation.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateLocation: '', error: payload };
      },

      [startDeleteLocation.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteLocation: payload, error: '' };
      },
      [startDeleteLocation.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteLocation: '', error: payload };
      },

      [startActiveLocation.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeLocation: payload, error: '' };
      },
      [startActiveLocation.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeLocation: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const locationActions = {
    ...locationSlice.actions,
    startFetchLocations,
    startFetchDataFields,
    startFetchLocation,
    startCreateLocation,
    startUpdateLocation,
    startDeleteLocation,
    startActiveLocation,
    setSearch,
    setCurrentPage,
    setSort
}
export default locationSlice.reducer