import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, LIVE_GOALS } from '../../constants/defaultValues';

const startFetchLiveGoals = createAsyncThunk('liveGoal/startFetchLiveGoals', async ({ locale, token, filter_values }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const user      = filter_values.filter.user ? filter_values.filter.user : '';
        const year      = filter_values.filter.year ? filter_values.filter.year : '';
        const quarter   = filter_values.filter.quarter ? filter_values.filter.quarter : '';

        const res = await axios.get(`${LIVE_GOALS}?user=${user}&year=${year}&quarter=${quarter}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSearchFields = createAsyncThunk('liveGoal/startFetchSearchFields', async ({ locale, token, loading }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${LIVE_GOALS}/searchFields?lang=${locale}`, { headers });

        return { data: res.data, loading: loading };
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const initialState = {
    stats: {},
    filter: {user: '', year: '', quarter: ""},
    sort: [{ desc: false, id: "id" }],
    searchFields: {
        users: [],
        quarters: []
    },
    loading: true,
    loadingFetch: false,
    error: ''
}

export const liveGoalSlice = createSlice({
    name: 'liveGoal',
    initialState,
    reducers: {
        clearLiveGoal(state) {
            return { ...state, stats: {}, filter: initialState.filter, loading: false, loadingFetch: false, error: '' };
        },
        clearAlertLiveGoal(state) {
            return { ...state, error: '' };
        },
        setFilterValue(state, { payload }) {
            return { ...state, filter: payload };
        }
    },
    extraReducers: {
      [startFetchLiveGoals.pending]: (state) => {
        return { ...state, loadingFetch: true, error: '' };
      },
      [startFetchLiveGoals.fulfilled]: (state, { payload }) => {
        return { ...state, loadingFetch: false, loading: false, stats: payload, error: '' };
      },
      [startFetchLiveGoals.rejected]: (state, { payload }) => {
        return { ...state, loadingFetch: false, loading: false, stats: initialState.stats, error: payload };
      },

      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, searchFields: payload.data, loading: payload.loading, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, searchFields: initialState.searchFields, loading: false, error: payload };
      }
    },
});


export const liveGoalActions = {
    ...liveGoalSlice.actions,
    startFetchLiveGoals,
    startFetchSearchFields
}
export default liveGoalSlice.reducer