import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, LEGAL_AUDIT } from '../../constants/defaultValues';

const startFetchSearchFields = createAsyncThunk('legalAudit/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${LEGAL_AUDIT}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateLegalAudit = createAsyncThunk('legalAudit/startUpdateLegalAudit', async ({ id, fieldKey, fieldValue, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${LEGAL_AUDIT}/update/${id}`, { fieldKey: fieldKey, fieldValue: fieldValue }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchLegalAudits = createAsyncThunk('legalAudit/startFetchLegalAudits', async ({ locale, token, filter, loading, items, clear }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(legalAuditSlice.actions.actionFetchLegalAudits(loading));
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${LEGAL_AUDIT}?
        &orderBy=${filter.orderBy}
        &search=${filter.search}
        &page=${filter.page}
        &status_type=${filter.searchFilter.status_type.length > 0 ? filter.searchFilter.status_type.join() : ""}
        &subcategory_type=${filter.searchFilter.subcategory_type.length > 0 ? filter.searchFilter.subcategory_type.join() : ""}
        &area_type=${filter.searchFilter.area_type.length > 0 ? filter.searchFilter.area_type.join() : ""}
        &checkUser_type=${filter.searchFilter.checkUser_type.length > 0 ? filter.searchFilter.checkUser_type.join() : ""}
        &mortgage_type=${filter.searchFilter.mortgage_type.length > 0 ? filter.searchFilter.mortgage_type.join() : ""}

        &fromDateInactive_type=${filter.searchFilter.fromDateInactive_type}
        &toDateInactive_type=${filter.searchFilter.toDateInactive_type}
        `, { headers });

        let arrayData = {
            data: [],
            totalItemCount: 0
        };

        if(res.data.data.length > 0){
            let newItems = {...items};
  
            if(clear){
              arrayData = {
                data: res.data.data,
                totalItemCount: res.data.totalItemCount
              }
            }else{
              arrayData = {
                data: [...newItems.data, ...res.data.data],
                totalItemCount: res.data.totalItemCount
              }
            }
            dispatch(legalAuditSlice.actions.setStopFetch(false))
        }else{
            if(clear){}else{
                let newItems = {...items};

                arrayData = {
                    data: newItems.data,
                    totalItemCount: newItems.totalItemCount
                }
            }
            dispatch(legalAuditSlice.actions.setStopFetch(true))
        }

        return arrayData;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSelectedOrderOption = createAsyncThunk('request/setSelectedOrderOption', ( args ) => {
    return args;
});

const setSearch = createAsyncThunk('request/setSearch', ( args ) => {
    return args;
});

const initialState = {
    items: {
        data: [],
        totalItemCount: 0
    },
    selectedOrderOption: "",
    search: "",
    searchValue: "",
    searchFilterType: 1,
    fields: [],
    searchFilter: [
        { translate: "general.status", id: "status_type", dataKey: "status_types", search: "", type: 1 },
        { translate: "property.property-subcategory", id: "subcategory_type", dataKey: "subcategory_types", search: "", type: 1 },
        { translate: "general.areas", id: "area_type", dataKey: "area_types", search: "", type: 1 },
        { translate: "general.last-check-person", id: "checkUser_type", dataKey: "checkUser_types", search: "", type: 1 },
        { translate: "general.mortgage-office", id: "mortgage_type", dataKey: "mortgage_types", search: "", type: 1 },

        { translate: "general.inactive", id: "inactive_date_type", dataKey: "", search: "", type: 1 },
    ],
    searchFilterValues: {
        status_type: [],
        subcategory_type: [],
        area_type: [],
        checkUser_type: [],
        mortgage_type: [],

        fromDateInactive_type: "",
        toDateInactive_type: "",
    },
    updateItem: '',
    stopFetch: false,
    loadingBtn: false,
    loading: true,
    loadingSearch: false,
    error: ''
}

export const legalAuditSlice = createSlice({
    name: 'legalAudit',
    initialState,
    reducers: {
        fetchLegalAuditsSuccess(state, { payload }) {
            return { ...state, loading: false, loadingSearch: false, items: payload, error: '' };
        },
        clearLegalAudit(state) {
            return { ...state, stopFetch: false, loadingBtn: false, loading: false, loadingSearch: false, itemData: initialState.itemData, error: '' };
        },
        clearAlertLegalAudit(state) {
            return { ...state, updateItem: '', error: '' };
        },

        setFilter(state, { payload }) {
            return { ...state, searchFilterValues: payload };
        },
        setFilterType(state, { payload }) {
            return { ...state, searchFilterType: payload };
        },
        setFilterFieldSearch(state, { payload }) {
            return { ...state, fields: payload.dataValues, searchFilter: payload.values };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        actionFetchLegalAudits(state, { payload }) {
            return { ...state, loading: payload ? true : false, loadingSearch: true, error: '' };
        },
        setStopFetch(state, { payload }) {
            return { ...state, stopFetch: payload }
        }
    },
    extraReducers: {
      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, fields: payload, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, fields: initialState.fields, error: payload };
      },

      [startUpdateLegalAudit.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateLegalAudit.fulfilled]: (state, { payload }) => {
        return { ...state, updateItem: payload, error: '' };
      },
      [startUpdateLegalAudit.rejected]: (state, { payload }) => {
        return { ...state, updateItem: '', error: payload };
      },

      [startFetchLegalAudits.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, items: payload, error: '' };
      },
      [startFetchLegalAudits.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, items: initialState.items, error: payload };
      },

      //Heading Functions
      [setSelectedOrderOption.fulfilled]: (state, { payload }) => {
        return { ...state, selectedOrderOption: payload, currentPage: 1 };
      },
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      }
    },
});


export const legalAuditActions = {
    ...legalAuditSlice.actions,
    startFetchSearchFields,
    startUpdateLegalAudit,
    startFetchLegalAudits,
    
    setSelectedOrderOption,
    setSearch
}
export default legalAuditSlice.reducer