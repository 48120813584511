import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, NOTIFICATION } from '../../constants/defaultValues';

const startFetchNotifications = createAsyncThunk('notification/startFetchNotifications', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${NOTIFICATION}?pageSize=${filter.pageSize}&currentPage=${filter.currentPage}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startSeenNotifications = createAsyncThunk('notification/startSeenNotifications', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${NOTIFICATION}/seen`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startReadNotification = createAsyncThunk('notification/startReadNotification', async ({ locale, token, id, index }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${NOTIFICATION}/read`, { notification_id: id }, { headers });

        return {status: res.data, index: index};
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startAllReadNotifications = createAsyncThunk('notification/startAllReadNotifications', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${NOTIFICATION}/allRead`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


const initialState = {
    notifications: {
        data: [],
        unseenCount: 0
    },
    selectedPageSize: 10,
    currentPage: 1,
    seenNotifications: '',
    readNotification: '',
    allReadNotifications: '',
    loading: false,
    error: ''
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setCurrentPage(state, { payload }) {
            state.currentPage= payload
        },
        setSelectedPageSize(state, { payload }) {
            state.selectedPageSize = payload
            state.currentPage = 1
        },
        setNotification(state, { payload }) {
            state.notifications = payload
        },
        clearNotifications(state) {
            state.notifications = initialState.notifications
            state.currentPage = 1
            state.seenNotifications = ''
            state.readNotification = ''
            state.allReadNotifications = ''
            state.loading = false
            state.error = ''
        },
    },
    extraReducers: {
      [startFetchNotifications.pending]: (state) => {
        state.loading = true
        state.error = ''
      },
      [startFetchNotifications.fulfilled]: (state, { payload }) => {
        let fetch_not = {...state.notifications}

        if(payload.data.length > 0){
            payload.data.forEach((item) => {
                fetch_not.data.unshift(item);
            })
            fetch_not.unseenCount = payload.unseenCount;
        }

        state.loading = false;
        state.error = '';
        state.notifications = fetch_not;
      },
      [startFetchNotifications.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.notifications = initialState.notifications
      },

      [startSeenNotifications.fulfilled]: (state, { payload }) => {
        let seen = {...state.notifications}
        seen.unseenCount = 0;

        state.loading = false;
        state.error = '';
        state.seenNotifications = payload;
        state.notifications = seen;
      },
      [startSeenNotifications.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.seenNotifications = ''
      },

      [startReadNotification.fulfilled]: (state, { payload }) => {
        let read = {...state.notifications}
        read.data[payload.index].read = 1;

        state.loading = false;
        state.error = '';
        state.readNotification = payload.status;
        state.notifications = read;
      },
      [startReadNotification.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.readNotification = ''
      },

      [startAllReadNotifications.fulfilled]: (state, { payload }) => {
        let allRead = {...state.notifications}

        allRead.data.forEach((item, i) => {
            allRead.data[i].read = 1;
        });

        state.loading = false;
        state.error = '';
        state.allReadNotifications = payload;
        state.notifications = allRead;
      },
      [startAllReadNotifications.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.allReadNotifications = ''
      },

     
    },
});

export const notificationActions = {
    ...notificationSlice.actions,
    startFetchNotifications,
    startSeenNotifications,
    startReadNotification,
    startAllReadNotifications
}
export default notificationSlice.reducer