import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, CONTACT_SCORE } from '../../constants/defaultValues';

const startFetchScore = createAsyncThunk('contactScore/startFetchScore', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT_SCORE}`, { headers });

        const data = res.data;

        const results = {
          scores: data
        };

        return results;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateScore = createAsyncThunk('contactScore/startUpdateScore', async ({ score, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CONTACT_SCORE}/update`, score, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const initialState = {
    updateScore: '',
    scoreData: {
        scores: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const contactScoreSlice = createSlice({
    name: 'contactScore',
    initialState,
    reducers: {
        clearScore(state) {
            return { ...state, loadingBtn: false, loading: false, scoreData: initialState.scoreData, error: '' };
        },
        clearAlertScore(state) {
            return { ...state, updateScore: '', error: '' };
        }
    },
    extraReducers: {
      [startFetchScore.pending]: (state) => {
        return { ...state, loading: true, loadingBtn: false, error: '' };
      },
      [startFetchScore.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, scoreData: payload, error: '' };
      },
      [startFetchScore.rejected]: (state, { payload }) => {
        return { ...state, loading: false, scoreData: '', error: payload };
      },

      [startUpdateScore.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateScore.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateScore: payload, error: '' };
      },
      [startUpdateScore.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateScore: '', error: payload };
      }
    },
});


export const contactScoreActions = {
    ...contactScoreSlice.actions,
    startFetchScore,
    startUpdateScore,
}
export default contactScoreSlice.reducer