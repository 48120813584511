import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, QUARTER_GOALS } from '../../constants/defaultValues';

const startFetchGoals = createAsyncThunk('quarterGoal/startFetchGoals', async ({ locale, token, role_id, setFieldValue }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${QUARTER_GOALS}/fetchGoals/${role_id}`, { headers });

        if(res.data){
            setFieldValue('goals', res.data)
        }

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('quarterGoal/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${QUARTER_GOALS}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchQuarterGoals = createAsyncThunk('quarterGoal/startFetchQuarterGoals', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${QUARTER_GOALS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateQuarterGoal = createAsyncThunk('quarterGoal/startCreateQuarterGoal', async ({ quarterGoal, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${QUARTER_GOALS}/store`, quarterGoal, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateQuarterGoal = createAsyncThunk('quarterGoal/startUpdateQuarterGoal', async ({ id, quarterGoal, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${QUARTER_GOALS}/update/${id}`, quarterGoal, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteQuarterGoal = createAsyncThunk('quarterGoal/startDeleteQuarterGoal', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${QUARTER_GOALS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchQuarterGoal = createAsyncThunk('quarterGoal/startFetchQuarterGoal', async ({ quarterGoal, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${QUARTER_GOALS}/edit/${quarterGoal}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            quarter_id: data.quarter_id,
            role_id: data.role_id,
            year: data.year,
            grey_rate: data.grey_rate,
            blue_rate: data.blue_rate,
            green_rate: data.green_rate,
            goals: data.goals
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('quarterGoal/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('quarterGoal/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('quarterGoal/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    quarterGoals: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{ desc: false, id: "id" }],
    createQuarterGoal: '',
    updateQuarterGoal: '',
    deleteQuarterGoal: '',
    quarterGoalData: {
        edit: false,
        quarter_id: '',
        role_id: '',
        year: null,
        grey_rate: '',
        blue_rate: '',
        green_rate: '',
        goals: []
    },
    dataFields: {
        roles: [],
        quarters: []
    },
    loadingContent: false,
    loadingBtn: false,
    loading: true,
    error: ''
}

export const quarterGoalSlice = createSlice({
    name: 'quarterGoal',
    initialState,
    reducers: {
        fetchQuarterGoalsSuccess(state, { payload }) {
            return { ...state, loading: false, quarterGoals: payload, error: '' };
        },
        clearQuarterGoal(state) {
            return { ...state, loadingBtn: false, loading: false, loadingContent: false, quarterGoalData: initialState.quarterGoalData, error: '' };
        },
        clearAlertQuarterGoal(state) {
            return { ...state, updateQuarterGoal: '', createQuarterGoal: '', deleteQuarterGoal: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchQuarterGoals.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchQuarterGoals.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, quarterGoals: payload, error: '' };
      },
      [startFetchQuarterGoals.rejected]: (state, { payload }) => {
        return { ...state, loading: false, quarterGoals: initialState.quarterGoals, error: payload };
      },

      [startFetchQuarterGoal.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchQuarterGoal.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, quarterGoalData: payload, error: '' };
      },
      [startFetchQuarterGoal.rejected]: (state, { payload }) => {
        return { ...state, loading: false, quarterGoalData: '', error: payload };
      },
      
      [startCreateQuarterGoal.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateQuarterGoal.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createQuarterGoal: payload, error: '' };
      },
      [startCreateQuarterGoal.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createQuarterGoal: '', error: payload };
      },

      [startUpdateQuarterGoal.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateQuarterGoal.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateQuarterGoal: payload, error: '' };
      },
      [startUpdateQuarterGoal.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateQuarterGoal: '', error: payload };
      },

      [startDeleteQuarterGoal.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteQuarterGoal: payload, error: '' };
      },
      [startDeleteQuarterGoal.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteQuarterGoal: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      [startFetchGoals.pending]: (state) => {
        return { ...state, loadingContent: true, error: '' };
      },
      [startFetchGoals.fulfilled]: (state, { payload }) => {
            let qua_data = {...state.quarterGoalData}

            qua_data.goals = payload;

            return { ...state, loadingContent: false, quarterGoalData: qua_data, error: '' };
      },
      [startFetchGoals.rejected]: (state, { payload }) => {
        return { ...state, loadingContent: false, quarterGoalData: initialState.quarterGoalData, error: payload }; 
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const quarterGoalActions = {
    ...quarterGoalSlice.actions,
    startFetchQuarterGoals,
    startFetchQuarterGoal,
    startCreateQuarterGoal,
    startUpdateQuarterGoal,
    startDeleteQuarterGoal,
    startFetchDataFields,
    startFetchGoals,
    setSearch,
    setCurrentPage,
    setSort
}
export default quarterGoalSlice.reducer