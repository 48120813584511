import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, COUNTRIES } from '../../constants/defaultValues';

const startFetchCountries = createAsyncThunk('country/startFetchCountries', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${COUNTRIES}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateCountry = createAsyncThunk('country/startCreateCountry', async ({ country, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${COUNTRIES}/store`, country, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateCountry = createAsyncThunk('country/startUpdateCountry', async ({ id, country, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${COUNTRIES}/update/${id}`, country, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteCountry = createAsyncThunk('country/startDeleteCountry', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${COUNTRIES}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveCountry = createAsyncThunk('country/startActiveCountry', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${COUNTRIES}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchCountry = createAsyncThunk('country/startFetchCountry', async ({ country, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${COUNTRIES}/edit/${country}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            country_en: data.country_en,
            country_el: data.country_el,
            country_de: data.country_de,
            country_sr: data.country_sr,
            country_bg: data.country_bg,
            country_ru: data.country_ru,
            iso: data.iso,
            lang_iso: data.lang_iso,
            country_phone_code: data.country_phone_code,
            portal_hp: data.portal_hp === 1 ? true : false,
            portal_hp_ru: data.portal_hp_ru === 1 ? true : false,
            portal_gp: data.portal_gp === 1 ? true : false
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('country/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('country/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('country/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    countries: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{ desc: false, id: "id" }],
    createCountry: '',
    updateCountry: '',
    activeCountry: '',
    deleteCountry: '',
    countryData: {
        edit: false,
        country_en: '',
        country_el: '',
        country_de: '',
        country_sr: '',
        country_bg: '',
        country_ru: '',
        iso: '',
        lang_iso: '',
        country_phone_code: '',
        portal_hp: false,
        portal_hp_ru: false,
        portal_gp: false,
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        fetchCountriesSuccess(state, { payload }) {
            return { ...state, loading: false, countries: payload, error: '' };
        },
        clearCountry(state) {
            return { ...state, loadingBtn: false, loading: false, countryData: initialState.countryData, error: '' };
        },
        clearAlertCountry(state) {
            return { ...state, updateCountry: '', createCountry: '', deleteCountry: '', activeCountry: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchCountries.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchCountries.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, countries: payload, error: '' };
      },
      [startFetchCountries.rejected]: (state, { payload }) => {
        return { ...state, loading: false, countries: initialState.countries, error: payload };
      },

      [startFetchCountry.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchCountry.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, countryData: payload, error: '' };
      },
      [startFetchCountry.rejected]: (state, { payload }) => {
        return { ...state, loading: false, countryData: '', error: payload };
      },
      
      [startCreateCountry.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateCountry.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createCountry: payload, error: '' };
      },
      [startCreateCountry.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createCountry: '', error: payload };
      },

      [startUpdateCountry.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateCountry.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateCountry: payload, error: '' };
      },
      [startUpdateCountry.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateCountry: '', error: payload };
      },

      [startDeleteCountry.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteCountry: payload, error: '' };
      },
      [startDeleteCountry.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteCountry: '', error: payload };
      },

      [startActiveCountry.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeCountry: payload, error: '' };
      },
      [startActiveCountry.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeCountry: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const countryActions = {
    ...countrySlice.actions,
    startFetchCountries,
    startFetchCountry,
    startCreateCountry,
    startUpdateCountry,
    startDeleteCountry,
    startActiveCountry,
    setSearch,
    setCurrentPage,
    setSort
}
export default countrySlice.reducer