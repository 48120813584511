import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, EXTRAS } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('extra/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${EXTRAS}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchExtras = createAsyncThunk('extra/startFetchExtras', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${EXTRAS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateExtra = createAsyncThunk('extra/startCreateExtra', async ({ extra, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${EXTRAS}/store`, extra, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateExtra = createAsyncThunk('extra/startUpdateExtra', async ({ id, extra, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${EXTRAS}/update/${id}`, extra, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteExtra = createAsyncThunk('extra/startDeleteExtra', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${EXTRAS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveExtra = createAsyncThunk('extra/startActiveExtra', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${EXTRAS}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchExtra = createAsyncThunk('extra/startFetchExtra', async ({ extra, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${EXTRAS}/edit/${extra}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            extra_en: data.extra_en,
            extra_el: data.extra_el,
            extra_de: data.extra_de,
            extra_sr: data.extra_sr,
            extra_bg: data.extra_bg,
            extra_ru: data.extra_ru,
            sort: data.sort,
            property_category_ids: data.property_category_ids ? data.property_category_ids : [],
            portal_hp: data.portal_hp === 1 ? true : false,
            portal_hp_ru: data.portal_hp_ru === 1 ? true : false,
            portal_gp: data.portal_gp === 1 ? true : false,
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('extra/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('extra/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('extra/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    extras: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    createExtra: '',
    updateExtra: '',
    deleteExtra: '',
    extraData: {
        edit:false,
        extra_en: '',
        extra_el: '',
        extra_de: '',
        extra_sr: '',
        extra_bg: '',
        extra_ru: '',
        sort: 0,
        property_category_ids: [],
        portal_hp: false,
        portal_hp_ru: false,
        portal_gp: false,
    },
    dataFields: {
        property_categories: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const extraSlice = createSlice({
    name: 'extra',
    initialState,
    reducers: {
        fetchExtrasSuccess(state, { payload }) {
            return { ...state, loading: false, extras: payload, error: '' };
        },
        clearExtra(state) {
            return { ...state, loadingBtn: false, loading: false, extraData: initialState.extraData, error: '' };
        },
        clearAlertExtra(state) {
            return { ...state, updateExtra: '', createExtra: '', deleteExtra: '', activeExtra: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchExtras.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchExtras.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, extras: payload, error: '' };
      },
      [startFetchExtras.rejected]: (state, { payload }) => {
        return { ...state, loading: false, extras: initialState.extras, error: payload };
      },

      [startFetchExtra.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchExtra.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, extraData: payload, error: '' };
      },
      [startFetchExtra.rejected]: (state, { payload }) => {
        return { ...state, loading: false, extraData: '', error: payload };
      },
      
      [startCreateExtra.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateExtra.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createExtra: payload, error: '' };
      },
      [startCreateExtra.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createExtra: '', error: payload };
      },

      [startUpdateExtra.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateExtra.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateExtra: payload, error: '' };
      },
      [startUpdateExtra.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateExtra: '', error: payload };
      },

      [startDeleteExtra.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteExtra: payload, error: '' };
      },
      [startDeleteExtra.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteExtra: '', error: payload };
      },

      [startActiveExtra.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeExtra: payload, error: '' };
      },
      [startActiveExtra.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeExtra: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const extraActions = {
    ...extraSlice.actions,
    startFetchExtras,
    startFetchExtra,
    startCreateExtra,
    startUpdateExtra,
    startDeleteExtra,
    startActiveExtra,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default extraSlice.reducer