import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import moment from "moment";
import { HEADERS, START } from '../../constants/defaultValues';

const startFetchHome = createAsyncThunk('start/startFetchHome', async ({ filter, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const users     = filter.users;
        const type      = filter.type;
        const date_from = filter.date_from;
        const date_to   = filter.date_to;


        const res = await axios.get(`${START}/home?users=${users}&type=${type}&date_from=${date_from ? date_from : ""}&date_to=${date_to ? date_to : ""}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSearchFields = createAsyncThunk('start/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${START}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchRequests = createAsyncThunk('start/startFetchRequests', async ({ filter, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const users     = filter.users;
        const type      = filter.type;
        const date_from = filter.date_from;
        const date_to   = filter.date_to;


        const res = await axios.get(`${START}/requests?users=${users}&type=${type}&date_from=${date_from ? date_from : ""}&date_to=${date_to ? date_to : ""}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchContacts = createAsyncThunk('start/startFetchContacts', async ({ filter, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const users     = filter.users;
        const type      = filter.type;
        const date_from = filter.date_from;
        const date_to   = filter.date_to;


        const res = await axios.get(`${START}/contacts?users=${users}&type=${type}&date_from=${date_from ? date_from : ""}&date_to=${date_to ? date_to : ""}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchProperties = createAsyncThunk('start/startFetchProperties', async ({ filter, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const users     = filter.users;
        const type      = filter.type;
        const date_from = filter.date_from;
        const date_to   = filter.date_to;


        const res = await axios.get(`${START}/properties?users=${users}&type=${type}&date_from=${date_from ? date_from : ""}&date_to=${date_to ? date_to : ""}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const initialState = {
    home: {
        assign_properties: 0,
        assign_contacts: 0,
        active_requests: 0,
        active_assign_orders: 0,
        halken_table: []
    },
    properties: {
        health_score: 0,
        uploaded_not_uploaded: {
            labels: [],
            data: [],
            border: [],
            background: []
        },
        assignment_without_assignment: {
            labels: [],
            data: [],
            border: [],
            background: []
        },
        viewers_assignment_without_assignment: {
            labels: [],
            data: [],
            border: [],
            background: []
        },
        distribution_assignment: {
            labels: [],
            data: [],
            border: [],
            background: []
        },
        favorite_prop_10: {labels: [], fp_data: []},
        ready_publication: {labels: [], data: []},
        properties_list: []
    },
    requests: {
        total_sales_agent: 0,
        total_sales: 0,
        transferred_requests: {labels: [], data: []},
        offers_auto: {labels: [], app_auto: [], app_manual: []},
        average_demands: {labels: [], data: []},
        appointments_vs_vts: {labels: [], app_data: [], app_view: []},
        properties_specific_offers: {labels: [], ps_data: []},
        most_visited_properties: {labels: [], mv_data: []},
        properties_subs: {
            labels: [],
            data: [],
            border: [],
            background: []
        },
        properties_areas: {
            labels: [],
            data: [],
            border: [],
            background: []
        },
        distribution_of_requests: [0, 0, 0, 0, 0, 0],
    },
    contacts: {
        health_score: 0,
        register_unregister: {
            labels: [],
            data: [],
            border: [],
            background: []
        },
        top_eight_nationalities: {
            labels: [],
            data: [],
            border: [],
            background: []
        },
        distribution_contact_type: {
            labels: [],
            data: [],
            border: [],
            background: []
        },
        distribution_contact_props: [],
        properties_subs: {
            labels: [],
            data: [],
            border: [],
            background: []
        },
        properties_areas: {
            labels: [],
            data: [],
            border: [],
            background: []
        },
        returning_clients: 0,
        contacts_visit_prop: {labels: [], cvp_data: []},
    },
    filter: {users: [], type: 1, date_from: moment().format('yyyy-MM'), date_to: moment().format('yyyy-MM')},
    searchFields: {
        users: []
    },
    loading: false,
    loadingTab: false,
    error: ''
}

export const startSlice = createSlice({
    name: 'start',
    initialState,
    reducers: {
        clearAlertStart(state) {
            state.error = ''
        },
        clearStart(state) {
            state = {...initialState}
        },
        setFilterValue(state, { payload }) {
            state.filter = payload
        }
    },
    extraReducers: {
      [startFetchHome.pending]: (state) => {
        state.loadingTab = true
        state.error = ''
      },
      [startFetchHome.fulfilled]: (state, { payload }) => {
        state.loadingTab = false;
        state.error = '';
        state.home = payload;
      },
      [startFetchHome.rejected]: (state, { payload }) => {
        state.loadingTab = false
        state.error = payload
        state.home = initialState.home
      },

      [startFetchSearchFields.pending]: (state) => {
        state.loading = true
        state.error = ''
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.searchFields = payload;
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.searchFields = initialState.searchFields
      },

      [startFetchRequests.pending]: (state) => {
        state.loadingTab = true
        state.error = ''
      },
      [startFetchRequests.fulfilled]: (state, { payload }) => {
        state.loadingTab = false;
        state.error = '';
        state.requests = payload;
      },
      [startFetchRequests.rejected]: (state, { payload }) => {
        state.loadingTab = false
        state.error = payload
        state.requests = initialState.requests
      },

      [startFetchContacts.pending]: (state) => {
        state.loadingTab = true
        state.error = ''
      },
      [startFetchContacts.fulfilled]: (state, { payload }) => {
        state.loadingTab = false;
        state.error = '';
        state.contacts = payload;
      },
      [startFetchContacts.rejected]: (state, { payload }) => {
        state.loadingTab = false
        state.error = payload
        state.contacts = initialState.contacts
      },

      [startFetchProperties.pending]: (state) => {
        state.loadingTab = true
        state.error = ''
      },
      [startFetchProperties.fulfilled]: (state, { payload }) => {
        state.properties = payload;
        state.loadingTab = false;
        state.error = '';
      },
      [startFetchProperties.rejected]: (state, { payload }) => {
        state.loadingTab = false
        state.error = payload
        state.properties = initialState.properties
      },
    },
});

export const startActions = {
    ...startSlice.actions,
    startFetchHome,
    startFetchSearchFields,
    startFetchRequests,
    startFetchContacts,
    startFetchProperties
}
export default startSlice.reducer