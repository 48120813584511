import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, CALENDAR } from '../../constants/defaultValues';

const startFetchSearchFields = createAsyncThunk('calendar/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CALENDAR}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchCalendar = createAsyncThunk('calendar/startFetchCalendar', async ({ locale, token, filter, loading }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(calendarSlice.actions.actionCalendar(loading))
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CALENDAR}?
        &start=${filter.start}
        &end=${filter.end}
        &category=${filter.searchFilter.category.length > 0 ? filter.searchFilter.category.join() : ""}
        &type=${filter.searchFilter.type.length > 0 ? filter.searchFilter.type.join() : ""}
        &status=${filter.searchFilter.status.length > 0 ? filter.searchFilter.status.join() : ""}
        &task=${filter.searchFilter.task.length > 0 ? filter.searchFilter.task.join() : ""}
        &user=${filter.searchFilter.user.length > 0 ? filter.searchFilter.user.join() : ""}
    
        &fromDate_type=${filter.searchFilter.fromDate_type}
        &toDate_type=${filter.searchFilter.toDate_type}
        `, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCalendarInfo = createAsyncThunk('calendar/startCalendarInfo', async ({ id, category, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CALENDAR}/info/${id}/${category}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const initialState = {
    events: {
        data: [],
        totalItemCount: 0
    },
    searchFilterType: 1,
    fields: [],
    searchFilter: [
        { translate: "general.category", id: "category", dataKey: "categories", search: "", type: 1},
        { translate: "general.type", id: "type", dataKey: "types", search: "", type: 1},
        { translate: "general.status", id: "status", dataKey: "status", search: "", type: 1},
        { translate: "general.task", id: "task", dataKey: "tasks", search: "", type: 1},
        { translate: "menu.users", id: "user", dataKey: "users", search: "", type: 1},   

        { translate: "general.date", id: "date_type", dataKey: "", search: "", type: 1 },
    ],
    searchFilterValues: {
        category: [],
        type: [],
        status: [],
        task: [],
        user: [],

        fromDate_type: "",
        toDate_type: "",
    },
    calendarInfo: {
        id: '',
        title: '',
        message: '',
        user: '',
        start: '',
        end: '',
        category: '',
        status: '',
        properties: [],
        contacts: []
    },
    loadingBtn: false,
    loadingModal: false,
    loading: true,
    loadingSearch: false,
    error: ''
};

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        clearCalendar(state) {
            state.loadingBtn = false
            state.loadingModal= false
            state.loading= false
            state.loadingSearch= false
            state.error= ''
        },
        clearAlertCalendar(state) {
            state.calendarInfo = initialState.calendarInfo
            state.error= ''
        },
        setFilter(state, { payload }) {
            state.searchFilterValues = payload
        },
        setFilterType(state, { payload }) {
            state.searchFilterType = payload
        },
        setFilterFieldSearch(state, { payload }) {
            const { dataValues, values } = payload;

            state.fields = dataValues
            state.searchFilter = values
        },
        fetchCalendarSuccess(state, { payload }) {
            return {...state, events: payload}
        },
        calendarInfoSuccess(state, { payload }) {
            state.calendarInfo = payload
        },
        actionCalendar(state, { payload }) {
            return {...state, loading: payload ? true : false, loadingSearch: true, error: ''}
        }
    },
    extraReducers: {
      [startFetchSearchFields.pending]: (state) => {
        state.loading = true
        state.error = ''
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.fields = payload;
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.fields = initialState.fields
      },

      [startFetchCalendar.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.loadingSearch = false;
        state.error = '';
        state.events = payload;
      },
      [startFetchCalendar.rejected]: (state, { payload }) => {
        state.loading = false
        state.loadingSearch = false
        state.error = payload
        state.events = initialState.events
      },

      [startCalendarInfo.pending]: (state) => {
        state.loadingModal = true
        state.error = ''
      },
      [startCalendarInfo.fulfilled]: (state, { payload }) => {
        state.loadingModal = false;
        state.error = '';
        state.calendarInfo = payload;
      },
      [startCalendarInfo.rejected]: (state, { payload }) => {
        state.loadingModal = false
        state.error = payload
        state.calendarInfo = initialState.calendarInfo
      },

     
    },
});

export const calendarActions = {
    ...calendarSlice.actions,
    startFetchSearchFields,
    startFetchCalendar,
    startCalendarInfo
}
export default calendarSlice.reducer