import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, FILE_TEMPLATES } from '../../constants/defaultValues';

const startFetchFileTemplates = createAsyncThunk('fileTemplate/startFetchFileTemplates', async ({ history, locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${FILE_TEMPLATES}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteFileTemplate = createAsyncThunk('fileTemplate/startDeleteFileTemplate', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${FILE_TEMPLATES}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteFile = createAsyncThunk('fileTemplate/startDeleteFile', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${FILE_TEMPLATES}/destroyFile/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateFileTemplate = createAsyncThunk('fileTemplate/startUpdateFileTemplate', async ({ id, file_template, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${FILE_TEMPLATES}/update/${id}`, file_template, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchFileTemplate = createAsyncThunk('fileTemplate/startFetchFileTemplate', async ({ file_template, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${FILE_TEMPLATES}/edit/${file_template}`, { headers });

        const data = res.data;

        const results = {
          edit: true,
          title: data.title,
          files: [],
          filesData: data.filesData
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateFileTemplate = createAsyncThunk('fileTemplate/startCreateFileTemplate', async ({ file_template, locale, token, form, dropzone }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${FILE_TEMPLATES}/store`, file_template, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
            dropzone.removeAllFiles(true)
        }
        
        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('fileTemplate/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('fileTemplate/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('fileTemplate/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    fileTemplates: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    fileTemplateData: {
        title: '',
        files: [],
        filesData: []
    },
    createFileTemplate: '',
    updateFileTemplate: '',
    deleteFileTemplate: '',
    deleteFile: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const fileTemplateSlice = createSlice({
    name: 'fileTemplate',
    initialState,
    reducers: {
        fetchFileTemplatesSuccess(state, { payload }) {
            return {...state, loading: false, fileTemplates: payload, error: ''}
        },
        clearFileTemplate(state) {
            return {...state, loading: false, loadingBtn: false, fileTemplateData: initialState.fileTemplateData, error: ''}
        },
        clearAlertFileTemplate(state) {
            return { ...state, updateFileTemplate: '', createFileTemplate: '', deleteFileTemplate: '', deleteFile: '', error: '' }
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchFileTemplates.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchFileTemplates.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, fileTemplates: payload, error: '' };
      },
      [startFetchFileTemplates.rejected]: (state, { payload }) => {
        return { ...state, loading: false, fileTemplates: initialState.fileTemplates, error: payload.message };
      },

      [startDeleteFileTemplate.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteFileTemplate: payload, error: '' };
      },
      [startDeleteFileTemplate.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteFileTemplate: '', error: payload.message };
      },

      [startDeleteFile.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteFile: payload, error: '' };
      },
      [startDeleteFile.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteFile: '', error: payload.message }; 
      },

      [startUpdateFileTemplate.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateFileTemplate.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateFileTemplate: payload, error: '' };
      },
      [startUpdateFileTemplate.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateFileTemplate: '', error: payload.message };
      },

      [startFetchFileTemplate.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchFileTemplate.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, fileTemplateData: payload, error: '' };
      },
      [startFetchFileTemplate.rejected]: (state, { payload }) => {
        return { ...state, loading: false, fileTemplateData: '', error: payload.message };
      },

      [startCreateFileTemplate.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateFileTemplate.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createFileTemplate: payload, error: '' };
      },
      [startCreateFileTemplate.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createFileTemplate: '', error: payload.message };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      },
    },
});


export const fileTemplateActions = {
    ...fileTemplateSlice.actions,
    startFetchFileTemplates,
    startDeleteFileTemplate,
    startDeleteFile,
    startUpdateFileTemplate,
    startFetchFileTemplate,
    startCreateFileTemplate,
    setSearch,
    setCurrentPage,
    setSort
}
export default fileTemplateSlice.reducer