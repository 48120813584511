import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, WEBSITE_PROPERTY } from '../../constants/defaultValues';

const startFetchWebsiteProperties = createAsyncThunk('websiteProperty/startFetchWebsiteProperties', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${WEBSITE_PROPERTY}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteWebsiteProperty = createAsyncThunk('websiteProperty/startDeleteWebsiteProperty', async ({ locale, token, id, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${WEBSITE_PROPERTY}/destroy/${id}/${portal_id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startSyncWebsiteProperty = createAsyncThunk('websiteProperty/startSyncWebsiteProperty', async ({ locale, token, id, portal_id, wbsiteProperties }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${WEBSITE_PROPERTY}/sync/${id}/${portal_id}`, { headers });

        let new_data = [];

        wbsiteProperties.data.forEach(item => {
            let new_item = {...item};

            if(new_item.id === id){
              new_item = res.data.item;
            }

            new_data.push(item)
        });

        const newArray = {
            data: new_data,
            totalItemCount: wbsiteProperties.totalItemCount
        }

        return newArray;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchWebsiteProperty = createAsyncThunk('websiteProperty/startFetchWebsiteProperty', async ({ id, portal_id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${WEBSITE_PROPERTY}/edit/${id}/${portal_id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions

const setCurrentPage = createAsyncThunk('websiteProperty/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('websiteProperty/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    wbsiteProperties: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    currentPage: 1,
    sort: [{ desc: false, id: "id" }],
    deleteWesiteProperty: '',
    syncWesiteProperty: '',
    wesitePropertyData: {},
    loadingBtn: false,
    loadingModal: false,
    loading: true,
    error: ''
}

export const websitePropertySlice = createSlice({
    name: 'websiteProperty',
    initialState,
    reducers: {
        fetchWebsitePropertiesSuccess(state, { payload }) {
            return { ...state, loading: false, wbsiteProperties: payload, error: '' };
        },
        clearWebsiteProperties(state) {
            return { ...state,  loadingBtn: false, loading: false, loadingModal: false, wesitePropertyData: {} };
        },
        clearAlertWebsiteProperties(state) {
            return { ...state, deleteWesiteProperty: '', syncWesiteProperty: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        }
    },
    extraReducers: {
      [startFetchWebsiteProperties.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchWebsiteProperties.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, wbsiteProperties: payload, error: '' };
      },
      [startFetchWebsiteProperties.rejected]: (state, { payload }) => {
        return { ...state, loading: false, wbsiteProperties: initialState.wbsiteProperties, error: payload };
      },

      [startFetchWebsiteProperty.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startFetchWebsiteProperty.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, wesitePropertyData: payload, error: '' };
      },
      [startFetchWebsiteProperty.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, wesitePropertyData: {}, error: payload };
      },

      [startDeleteWebsiteProperty.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteWebsiteProperty: payload, error: '' };
      },
      [startDeleteWebsiteProperty.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteWebsiteProperty: '', error: payload };
      },

      [startSyncWebsiteProperty.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startSyncWebsiteProperty.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, syncWesiteProperty: payload, error: '' };
      },
      [startSyncWebsiteProperty.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, syncWesiteProperty: '', error: payload };
      },

      //Heading Functions
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const websitePropertyActions = {
    ...websitePropertySlice.actions,
    startFetchWebsiteProperties,
    startFetchWebsiteProperty,
    startDeleteWebsiteProperty,
    startSyncWebsiteProperty,
    setCurrentPage,
    setSort
}
export default websitePropertySlice.reducer