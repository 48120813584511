import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, EXPERIENCES } from '../../constants/defaultValues';

const startFetchExperiences = createAsyncThunk('experience/startFetchExperiences', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${EXPERIENCES}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateExperience = createAsyncThunk('experience/startCreateExperience', async ({ experience, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;
        
        const res = await axios.post(`${EXPERIENCES}/store`, experience, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateExperience = createAsyncThunk('experience/startUpdateExperience', async ({ id, experience, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;
        
        const res = await axios.post(`${EXPERIENCES}/update/${id}`, experience, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchExperience = createAsyncThunk('experience/startFetchExperience', async ({ experience, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;
        
        const res = await axios.get(`${EXPERIENCES}/edit/${experience}`, { headers });

        const data = res.data;

        const results = {
          edit: true,
          url_en: data.url_en,
          url_el: data.url_el,
          title_en: data.title_en,
          title_el: data.title_el,
          text_en: data.text_en,
          text_el: data.text_el,
          have_subtext: data.have_subtext,
          subtext_en: data.subtext_en ? data.subtext_en : "",
          subtext_el: data.subtext_el ? data.subtext_el : "",
          have_youtube: data.have_youtube,
          youtube: data.youtube ? data.youtube : "",
          meta_title_en: data.page_config ? data.page_config.meta_title_en : '',
          meta_title_el: data.page_config ? data.page_config.meta_title_el : '',
          meta_desc_en: data.page_config ? data.page_config.meta_desc_en : '',
          meta_desc_el: data.page_config ? data.page_config.meta_desc_el : '',
          meta_lsi_en: data.page_config ? data.page_config.meta_lsi_en : [],
          meta_lsi_el: data.page_config ? data.page_config.meta_lsi_el : [],
          sort: data.sort,
          files: data.files
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteExperience = createAsyncThunk('experience/startDeleteExperience', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${EXPERIENCES}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveExperience = createAsyncThunk('experience/startActiveExperience', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${EXPERIENCES}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteExperienceFile = createAsyncThunk('experience/startDeleteExperienceFile', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${EXPERIENCES}/destroyFile/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


//Heading Functions
const setSearch = createAsyncThunk('experience/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('experience/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('experience/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    experiences: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    experienceData: {
        url_en: '',
        url_el: '',
        title_en: '',
        title_el: '',
        text_en: '',
        text_el: '',
        have_subtext: 0,
        subtext_en: '',
        subtext_el: '',
        have_youtube: 0,
        youtube: '',
        meta_title_en: '',
        meta_title_el: '',
        meta_desc_en: '',
        meta_desc_el: '',
        meta_lsi_en: [],
        meta_lsi_el: [],
        sort: 0,
        files: []
    },
    createExperience: '',
    updateExperience: '',
    deleteExperience: '',
    deleteFileExperience: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const experienceSlice = createSlice({
    name: 'experience',
    initialState,
    reducers: {
        fetchExperiencesSuccess(state, { payload }) {
            return { ...state, loading: false, experiences: payload, error: '' };
        },
        clearExperience(state) {
            return { ...state, loadingBtn: false, loading: false, experienceData: initialState.experienceData, error: '' };
        },
        clearAlertExperience(state) {
            return { ...state, updateExperience: '', createExperience: '', deleteExperience: '', activeExperience: '', deleteFileExperience: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchExperiences.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchExperiences.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, experiences: payload, error: '' };
      },
      [startFetchExperiences.rejected]: (state, { payload }) => {
        return { ...state, loading: false, experiences: initialState.experiences, error: payload };
      },

      [startCreateExperience.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateExperience.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createExperience: payload, error: '' };
      },
      [startCreateExperience.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createExperience: '', error: payload };
      },

      [startUpdateExperience.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateExperience.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateExperience: payload, error: '' };
      },
      [startUpdateExperience.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateExperience: '', error: payload };
      },

      [startFetchExperience.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchExperience.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, experienceData: payload, error: '' };
      },
      [startFetchExperience.rejected]: (state, { payload }) => {
        return { ...state, loading: false, experienceData: '', error: payload };
      },

      [startDeleteExperience.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteExperience: payload, error: '' };
      },
      [startDeleteExperience.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteExperience: '', error: payload };
      },
      
      [startActiveExperience.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeExperience: payload, error: '' };
      },
      [startActiveExperience.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeExperience: '', error: payload };   
      },

      [startDeleteExperienceFile.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteFileExperience: payload, error: '' };
      },
      [startDeleteExperienceFile.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteFileExperience: '', error: payload };    
      },

      
      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const experienceActions = {
    ...experienceSlice.actions,
    startFetchExperiences,
    startCreateExperience,
    startUpdateExperience,
    startFetchExperience,
    startDeleteExperience,
    startActiveExperience,
    startDeleteExperienceFile,
    setSearch,
    setCurrentPage,
    setSort
}
export default experienceSlice.reducer