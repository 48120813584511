import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PERMISSIONS } from '../../constants/defaultValues';

const startFetchPermissions = createAsyncThunk('permission/startFetchPermissions', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${PERMISSIONS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreatePermission = createAsyncThunk('permission/startCreatePermission', async ({ permission, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PERMISSIONS}/store`, permission, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdatePermission = createAsyncThunk('permission/startUpdatePermission', async ({ id, permission, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PERMISSIONS}/update/${id}`, permission, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPermission = createAsyncThunk('permission/startFetchPermission', async ({ permission, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PERMISSIONS}/edit/${permission}`, { headers });

        const data = res.data;

        const results = {
          edit: true,
          permission: data.name
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeletePermission = createAsyncThunk('permission/startDeletePermission', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PERMISSIONS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('permission/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('permission/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('permission/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    permissions: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    permissionData: {
        permission: ''
    },
    createPermission: '',
    updatePermission: '',
    deletePermission: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const permissionSlice = createSlice({
    name: 'permission',
    initialState,
    reducers: {
        fetchPermissionsSuccess(state, { payload }) {
            return { ...state, loading: false, permissions: payload, error: '' };
        },
        clearPermission(state) {
            return { ...state, loadingBtn: false, loading: false, permissionData: initialState.permissionData, error: '' };
        },
        clearAlertPermission(state) {
            return { ...state, updatePermission: '', createPermission: '', deletePermission: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchPermissions.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPermissions.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, permissions: payload, error: '' };
      },
      [startFetchPermissions.rejected]: (state, { payload }) => {
        return { ...state, loading: false, permissions: initialState.permissions, error: payload };
      },

      [startCreatePermission.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreatePermission.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPermission: payload, error: '' };
      },
      [startCreatePermission.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPermission: '', error: payload };
      },

      [startUpdatePermission.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdatePermission.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePermission: payload, error: '' };
      },
      [startUpdatePermission.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePermission: '', error: payload };
      },

      [startFetchPermission.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPermission.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, permissionData: payload, error: '' };
      },
      [startFetchPermission.rejected]: (state, { payload }) => {
        return { ...state, loading: false, permissionData: initialState.permissionData, error: payload };
      },

      [startDeletePermission.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deletePermission: payload, error: '' };
      },
      [startDeletePermission.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deletePermission: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const permissionActions = {
    ...permissionSlice.actions,
    startFetchPermissions,
    startCreatePermission,
    startUpdatePermission,
    startFetchPermission,
    startDeletePermission,
    setSearch,
    setCurrentPage,
    setSort
}
export default permissionSlice.reducer