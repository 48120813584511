import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PROPERTY_SCORE } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('propertyScore/startFetchDataFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_SCORE}/dataFields`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateScore = createAsyncThunk('propertyScore/startUpdateScore', async ({ score, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_SCORE}/update`, score, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchScore = createAsyncThunk('propertyScore/startFetchScore', async ({ history, locale, token, category_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_SCORE}/${category_id}`, { headers });

        const data = res.data;

        const results = {
          scores: data
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const initialState = {
    updateScore: '',
    category_id: 1,
    scoreData: {
        scores: []
    },
    dataFields: {
        property_categories: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const propertyScoreSlice = createSlice({
    name: 'propertyScore',
    initialState,
    reducers: {
        clearScore(state) {
            return { ...state, loadingBtn: false, loading: false, scoreData: initialState.scoreData, error: '' };
        },
        clearAlertScore(state) {
            return { ...state, updateScore: '', error: '' };
        },
        setCategory(state, { payload }) {
            return { ...state, category_id: payload, error: '' };
        }
    },
    extraReducers: {
      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };   
      },

      [startUpdateScore.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateScore.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateScore: payload, error: '' };
      },
      [startUpdateScore.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateScore: '', error: payload }; 
      },

      [startFetchScore.pending]: (state) => {
        return { ...state, loading: true, loadingBtn: false, error: '' };
      },
      [startFetchScore.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, scoreData: payload, error: '' };
      },
      [startFetchScore.rejected]: (state, { payload }) => {
        return { ...state, loading: false, scoreData: '', error: payload };
      }
    },
});


export const propertyScoreActions = {
    ...propertyScoreSlice.actions,
    startFetchDataFields,
    startUpdateScore,
    startFetchScore
}
export default propertyScoreSlice.reducer