import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PROPERTY_PREFECTURES } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('propertyPrefecture/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_PREFECTURES}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropertyPrefectures = createAsyncThunk('propertyPrefecture/startFetchPropertyPrefectures', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${PROPERTY_PREFECTURES}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreatePropertyPrefecture = createAsyncThunk('propertyPrefecture/startCreatePropertyPrefecture', async ({ propertyPrefecture, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_PREFECTURES}/store`, propertyPrefecture, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdatePropertyPrefecture = createAsyncThunk('propertyPrefecture/startUpdatePropertyPrefecture', async ({ id, propertyPrefecture, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROPERTY_PREFECTURES}/update/${id}`, propertyPrefecture, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeletePropertyPrefecture = createAsyncThunk('propertyPrefecture/startDeletePropertyPrefecture', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PROPERTY_PREFECTURES}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActivePropertyPrefecture = createAsyncThunk('propertyPrefecture/startActivePropertyPrefecture', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${PROPERTY_PREFECTURES}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropertyPrefecture = createAsyncThunk('propertyPrefecture/startFetchPropertyPrefecture', async ({ propertyPrefecture, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROPERTY_PREFECTURES}/edit/${propertyPrefecture}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            prefecture_en: data.prefecture_en,
            prefecture_el: data.prefecture_el,
            prefecture_de: data.prefecture_de,
            prefecture_sr: data.prefecture_sr,
            prefecture_bg: data.prefecture_bg,
            prefecture_ru: data.prefecture_ru,
            country_id: data.country_id,
            geometry_location_id: data.geometry_location_id,
            kml: data.kml,
            lat: data.lat,
            lng: data.lng,
            zoom: data.zoom,
            portal_hp: data.portal_hp === 1 ? true : false,
            portal_hp_ru: data.portal_hp_ru === 1 ? true : false,
            portal_gp: data.portal_gp === 1 ? true : false,
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('propertyPrefecture/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('propertyPrefecture/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('propertyPrefecture/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    propertyPrefectures: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    createPropertyPrefecture: '',
    updatePropertyPrefecture: '',
    deletePropertyPrefecture: '',
    propertyPrefectureData: {
        edit:false,
        prefecture_en: '',
        prefecture_el: '',
        prefecture_de: '',
        prefecture_sr: '',
        prefecture_bg: '',
        prefecture_ru: '',
        lat: '37.9815336',
        lng: '23.7493355',
        zoom: 6,
        country_id: '',
        geometry_location_id: '',
        kml: '',
        portal_hp: false,
        portal_hp_ru: false,
        portal_gp: false,
    },
    dataFields: {
        countries: [],
        geometry_locations: [] 
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const propertyPrefectureSlice = createSlice({
    name: 'propertyPrefecture',
    initialState,
    reducers: {
        fetchPropertyPrefecturesSuccess(state, { payload }) {
            return { ...state, loading: false, propertyPrefectures: payload, error: '' };
        },
        clearPropertyPrefecture(state) {
            return { ...state, loadingBtn: false, loading: false, propertyPrefectureData: initialState.propertyPrefectureData, error: '' };
        },
        clearAlertPropertyPrefecture(state) {
            return { ...state, updatePropertyPrefecture: '', createPropertyPrefecture: '', deletePropertyPrefecture: '', activePropertyPrefecture: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchPropertyPrefectures.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPropertyPrefectures.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, propertyPrefectures: payload, error: '' };
      },
      [startFetchPropertyPrefectures.rejected]: (state, { payload }) => {
        return { ...state, loading: false, propertyPrefectures: initialState.propertyPrefectures, error: payload };
      },

      [startFetchPropertyPrefecture.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPropertyPrefecture.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, propertyPrefectureData: payload, error: '' };
      },
      [startFetchPropertyPrefecture.rejected]: (state, { payload }) => {
        return { ...state, loading: false, propertyPrefectureData: '', error: payload };
      },
      
      [startCreatePropertyPrefecture.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreatePropertyPrefecture.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPropertyPrefecture: payload, error: '' };
      },
      [startCreatePropertyPrefecture.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPropertyPrefecture: '', error: payload };
      },

      [startUpdatePropertyPrefecture.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdatePropertyPrefecture.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePropertyPrefecture: payload, error: '' };
      },
      [startUpdatePropertyPrefecture.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePropertyPrefecture: '', error: payload };
      },

      [startDeletePropertyPrefecture.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deletePropertyPrefecture: payload, error: '' };
      },
      [startDeletePropertyPrefecture.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deletePropertyPrefecture: '', error: payload };
      },

      [startActivePropertyPrefecture.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activePropertyPrefecture: payload, error: '' };
      },
      [startActivePropertyPrefecture.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activePropertyPrefecture: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const propertyPrefectureActions = {
    ...propertyPrefectureSlice.actions,
    startFetchPropertyPrefectures,
    startFetchPropertyPrefecture,
    startCreatePropertyPrefecture,
    startUpdatePropertyPrefecture,
    startDeletePropertyPrefecture,
    startActivePropertyPrefecture,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default propertyPrefectureSlice.reducer