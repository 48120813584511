import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PARTNERS } from '../../constants/defaultValues';

const startFetchPartners = createAsyncThunk('partner/startFetchPartners', async ({ locale, token, filter, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${PARTNERS}?portalId=${portal_id}&pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreatePartner = createAsyncThunk('partner/startCreatePartner', async ({ partner, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PARTNERS}/store`, partner, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdatePartner = createAsyncThunk('partner/startUpdatePartner', async ({ id, partner, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PARTNERS}/update/${id}`, partner, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeletePartner = createAsyncThunk('partner/startDeletePartner', async ({ locale, token, id, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PARTNERS}/destroy/${portal_id}/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActivePartner = createAsyncThunk('partner/startActivePartner', async ({ locale, token, id, action, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${PARTNERS}/active/${id}`, { action: action, portal_id: portal_id }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeletePartnerLogo = createAsyncThunk('partner/startDeletePartnerLogo', async ({ locale, token, id, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PARTNERS}/destroyLogo/${portal_id}/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startOptionsLogoPartner = createAsyncThunk('partner/startOptionsLogoPartner', async ({ locale, token, id, url, alt, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${PARTNERS}/saveLogoOptions/${id}`, { url: url, alt: alt, portal_id: portal_id }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPartner = createAsyncThunk('partner/startFetchPartner', async ({ portal_id, partner, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PARTNERS}/edit/${portal_id}/${partner}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            portal_id: data.portal_id,
            url: data.url,
            title_en: data.title_en,
            title_el: data.title_el,
            title_de: data.title_de,
            title_sr: data.title_sr,
            title_bg: data.title_bg,
            title_ru: data.title_ru,
            text_en: data.text_en,
            text_el: data.text_el,
            text_de: data.text_de,
            text_sr: data.text_sr,
            text_bg: data.text_bg,
            text_ru: data.text_ru,
            sort: data.sort,
            file: ''
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('partner/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('partner/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('partner/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    partners: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    partnerData: {
        portal_id: '',
        sort: 0,
        url: '',
        title_en: '',
        title_el: '',
        title_de: '',
        title_sr: '',
        title_bg: '',
        title_ru: '',
        text_en: '',
        text_el: '',
        text_de: '',
        text_sr: '',
        text_bg: '',
        text_ru: '',
        file: ''
    },
    createPartner: '',
    updatePartner: '',
    deletePartnerLogo: '',
    optionsPartnerLogo: '',
    deleteLogo: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        fetchPartnersSuccess(state, { payload }) {
            return { ...state, loading: false, partners: payload, error: '' };
        },
        clearPartner(state) {
            return { ...state, loadingBtn: false, loading: false, partnerData: initialState.partnerData, error: '' };
        },
        clearAlertPartner(state) {
            return { ...state, updatePartner: '', createPartner: '', deletePartner: '', activePartner: '', deletePartnerLogo: '', optionsPartnerLogo: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchPartners.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPartners.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, partners: payload, error: '' };
      },
      [startFetchPartners.rejected]: (state, { payload }) => {
        return { ...state, loading: false, partners: initialState.partners, error: payload };
      },

      [startFetchPartner.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchPartner.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, partnerData: payload, error: '' };
      },
      [startFetchPartner.rejected]: (state, { payload }) => {
        return { ...state, loading: false, partnerData: '', error: payload };
      },
      
      [startCreatePartner.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreatePartner.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPartner: payload, error: '' };
      },
      [startCreatePartner.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createPartner: '', error: payload };
      },

      [startUpdatePartner.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdatePartner.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePartner: payload, error: '' };
      },
      [startUpdatePartner.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updatePartner: '', error: payload };
      },

      [startDeletePartner.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deletePartner: payload, error: '' };
      },
      [startDeletePartner.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deletePartner: '', error: payload };
      },

      [startActivePartner.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activePartner: payload, error: '' };
      },
      [startActivePartner.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activePartner: '', error: payload };
      },

      [startDeletePartnerLogo.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deletePartnerLogo: payload, error: '' };
      },
      [startDeletePartnerLogo.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deletePartnerLogo: '', error: payload };
      },

      [startOptionsLogoPartner.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, optionsPartnerLogo: payload, error: '' };
      },
      [startOptionsLogoPartner.rejected]: (state, { payload }) => {
        return { ...state, loading: false, optionsPartnerLogo: '', error: payload };  
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const partnerActions = {
    ...partnerSlice.actions,
    startFetchPartners,
    startFetchPartner,
    startCreatePartner,
    startUpdatePartner,
    startDeletePartner,
    startActivePartner,
    startDeletePartnerLogo,
    startOptionsLogoPartner,
    setSearch,
    setCurrentPage,
    setSort
}
export default partnerSlice.reducer