import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, OURTEAMS } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('ourTeam/startFetchDataFields', async ({ locale, token, ifEdit, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${OURTEAMS}/dataFields?portalId=${portal_id}&lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchOurTeams = createAsyncThunk('ourTeam/startFetchOurTeams', async ({ locale, token, filter, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${OURTEAMS}?portalId=${portal_id}&pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateOurTeam = createAsyncThunk('ourTeam/startCreateOurTeam', async ({ ourTeam, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${OURTEAMS}/store`, ourTeam, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateOurTeam = createAsyncThunk('ourTeam/startUpdateOurTeam', async ({ id, ourTeam, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${OURTEAMS}/update/${id}`, ourTeam, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteOurTeam = createAsyncThunk('ourTeam/startDeleteOurTeam', async ({ locale, token, id, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${OURTEAMS}/destroy/${portal_id}/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveOurTeam = createAsyncThunk('ourTeam/startActiveOurTeam', async ({ locale, token, id, action, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${OURTEAMS}/active/${id}`, { action: action, portal_id: portal_id }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchOurTeam = createAsyncThunk('ourTeam/startFetchOurTeam', async ({ portal_id, ourTeam, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${OURTEAMS}/edit/${portal_id}/${ourTeam}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            id: data.id,
            portal_id: data.portal_id,
            name: data.name,
            surname: data.surname,
            user_id: data.user_id,
            email: data.email,
            email_hide: data.email_hide,
            address: data.address,
            address_hide: data.address_hide,
            country: data.country,
            iso: data.iso,
            image: data.image ? data.image : '',
            file: '',
            lat: Number(data.lat),
            lng: Number(data.lng),
            socials: data.socials,
            job_title: data.job_title.id,
            department: data.department ? data.department.id : null,
            extraPhones: data.extraPhones,
            extraMobiles: data.extraMobiles,
            spoken_language: data.spoken_language,
            sort: data.sort
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('ourTeam/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('ourTeam/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('ourTeam/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    ourTeams: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    ourTeamData: {
        portal_id: '',
        name: '',
        surname: '',
        user_id: '',
        email: '',
        email_hide: 0,
        address: '',
        address_hide: 0,
        country: '',
        iso: '',
        image: '',
        file: '',
        lat: '',
        lng: '',
        job_title: '',
        department: '',
        socials: [],
        extraPhones: [],
        extraMobiles: [],
        spoken_language: [],
        sort: ''
    },
    createOurTeam: '',
    updateOurTeam: '',
    deleteOurTeam: '',
    activeOurTeam: '',
    dataFields: {
        jobTitles: [],
        departments: [],
        languages: [],
        countries: [],
        users: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const ourTeamSlice = createSlice({
    name: 'ourTeam',
    initialState,
    reducers: {
        fetchOurTeamsSuccess(state, { payload }) {
            return { ...state, loading: false, ourTeams: payload, error: '' };
        },
        clearOurTeam(state) {
            return { ...state, loadingBtn: false, loading: false, ourTeamData: initialState.ourTeamData, error: '' };
        },
        clearAlertOurTeam(state) {
            return { ...state, updateOurTeam: '', createOurTeam: '', deleteOurTeam: '', activeOurTeam: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchOurTeams.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchOurTeams.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, ourTeams: payload, error: '' };
      },
      [startFetchOurTeams.rejected]: (state, { payload }) => {
        return { ...state, loading: false, ourTeams: initialState.ourTeams, error: payload };
      },

      [startFetchOurTeam.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchOurTeam.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, ourTeamData: payload, error: '' };
      },
      [startFetchOurTeam.rejected]: (state, { payload }) => {
        return { ...state, loading: false, ourTeamData: '', error: payload };
      },
      
      [startCreateOurTeam.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateOurTeam.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createOurTeam: payload, error: '' };
      },
      [startCreateOurTeam.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createOurTeam: '', error: payload };
      },

      [startUpdateOurTeam.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateOurTeam.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateOurTeam: payload, error: '' };
      },
      [startUpdateOurTeam.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateOurTeam: '', error: payload };
      },

      [startDeleteOurTeam.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteOurTeam: payload, error: '' };
      },
      [startDeleteOurTeam.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteOurTeam: '', error: payload };
      },

      [startActiveOurTeam.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeOurTeam: payload, error: '' };
      },
      [startActiveOurTeam.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeOurTeam: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const ourTeamActions = {
    ...ourTeamSlice.actions,
    startFetchOurTeams,
    startFetchOurTeam,
    startCreateOurTeam,
    startUpdateOurTeam,
    startDeleteOurTeam,
    startActiveOurTeam,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default ourTeamSlice.reducer