import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, PROJECTS } from '../../constants/defaultValues';

const startFetchProjects = createAsyncThunk('project/startFetchProjects', async ({ locale, token, filter, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${PROJECTS}?portalId=${portal_id}&pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateProject = createAsyncThunk('project/startCreateProject', async ({ project, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROJECTS}/store`, project, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateProject = createAsyncThunk('project/startUpdateProject', async ({ id, project, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${PROJECTS}/update/${id}`, project, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteProject = createAsyncThunk('project/startDeleteProject', async ({ locale, token, id, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${PROJECTS}/destroy/${portal_id}/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveProject = createAsyncThunk('project/startActiveProject', async ({ locale, token, id, action, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${PROJECTS}/active/${id}`, { action: action, portal_id: portal_id }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchProject = createAsyncThunk('project/startFetchProject', async ({ portal_id, project, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${PROJECTS}/edit/${portal_id}/${project}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            portal_id: data.portal_id,
            template: data.template,
            image_or_yt: data.image_or_yt,
            bottom_img_yt_multiply: data.bottom_img_yt_multiply,
            extra_text: data.extra_text ? true : false,
            url_en: data.url_en,
            url_el: data.url_el,
            url_de: data.url_de,
            url_sr: data.url_sr,
            url_bg: data.url_bg,
            url_ru: data.url_ru,
            title_en: data.title_en,
            title_el: data.title_el,
            title_de: data.title_de,
            title_sr: data.title_sr,
            title_bg: data.title_bg,
            title_ru: data.title_ru,
            text_en: data.text_en,
            text_el: data.text_el,
            text_de: data.text_de,
            text_sr: data.text_sr,
            text_bg: data.text_bg,
            text_ru: data.text_ru,
            subtext_en: data.subtext_en,
            subtext_el: data.subtext_el,
            subtext_de: data.subtext_de,
            subtext_sr: data.subtext_sr,
            subtext_bg: data.subtext_bg,
            subtext_ru: data.subtext_ru,
            meta_title_en: data.page_config ? data.page_config.meta_title_en : '',
            meta_title_el: data.page_config ? data.page_config.meta_title_el : '',
            meta_title_de: data.page_config ? data.page_config.meta_title_de : '',
            meta_title_sr: data.page_config ? data.page_config.meta_title_sr : '',
            meta_title_bg: data.page_config ? data.page_config.meta_title_bg : '',
            meta_title_ru: data.page_config ? data.page_config.meta_title_ru : '',
            meta_desc_en: data.page_config ? data.page_config.meta_desc_en : '',
            meta_desc_el: data.page_config ? data.page_config.meta_desc_el : '',
            meta_desc_de: data.page_config ? data.page_config.meta_desc_de: '',
            meta_desc_sr: data.page_config ? data.page_config.meta_desc_sr : '',
            meta_desc_bg: data.page_config ? data.page_config.meta_desc_bg : '',
            meta_desc_ru: data.page_config ? data.page_config.meta_desc_ru : '',
            meta_lsi_en: data.page_config ? data.page_config.meta_lsi_en : [],
            meta_lsi_el: data.page_config ? data.page_config.meta_lsi_el : [],
            meta_lsi_de: data.page_config ? data.page_config.meta_lsi_de : [],
            meta_lsi_sr: data.page_config ? data.page_config.meta_lsi_sr : [],
            meta_lsi_bg: data.page_config ? data.page_config.meta_lsi_bg : [],
            meta_lsi_ru: data.page_config ? data.page_config.meta_lsi_ru : [],
            youtube_url: data.youtube_url,
            youtube_url_bottom: data.youtube_url_bottom,
            sort: data.sort,
            image: data.image,
            image_name: data.image,
            image_bottom: data.image_bottom,
            image_bottom_name: data.image_bottom_name,
            images_bottom_multiply: data.images_bottom_multiply
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('project/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('project/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('project/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    projects: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    projectData: {
        portal_id: '',
        template: 1,
        image_or_yt: 0,
        bottom_img_yt_multiply: 0,
        extra_text: false,
        sort: 0,
        url_en: '',
        url_el: '',
        url_de: '',
        url_sr: '',
        url_bg: '',
        url_ru: '',
        title_en: '',
        title_el: '',
        title_de: '',
        title_sr: '',
        title_bg: '',
        title_ru: '',
        text_en: '',
        text_el: '',
        text_de: '',
        text_sr: '',
        text_bg: '',
        text_ru: '',
        subtext_en: '',
        subtext_el: '',
        subtext_de: '',
        subtext_sr: '',
        subtext_bg: '',
        subtext_ru: '',
        meta_title_en: '',
        meta_title_el: '',
        meta_title_de: '',
        meta_title_sr: '',
        meta_title_bg: '',
        meta_title_ru: '',
        meta_desc_en: '',
        meta_desc_el: '',
        meta_desc_de: '',
        meta_desc_sr: '',
        meta_desc_bg: '',
        meta_desc_ru: '',
        meta_lsi_en: [],
        meta_lsi_el: [],
        meta_lsi_de: [],
        meta_lsi_sr: [],
        meta_lsi_bg: [],
        meta_lsi_ru: [],
        youtube_url: '',
        youtube_url_bottom: '',
        image: '',
        image_name: '',
        image_bottom: '',
        image_bottom_name: '',
        images_bottom_multiply: []
    },
    createProject: '',
    updateProject: '',
    deleteProject: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        fetchProjectsSuccess(state, { payload }) {
            return { ...state, loading: false, projects: payload, error: '' };
        },
        clearProject(state) {
            return { ...state, loadingBtn: false, loading: false, projectData: initialState.projectData, error: '' };
        },
        clearAlertProject(state) {
            return { ...state, updateProject: '', createProject: '', deleteProject: '', activeProject: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchProjects.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchProjects.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, projects: payload, error: '' };
      },
      [startFetchProjects.rejected]: (state, { payload }) => {
        return { ...state, loading: false, projects: initialState.projects, error: payload };
      },

      [startFetchProject.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchProject.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, projectData: payload, error: '' };
      },
      [startFetchProject.rejected]: (state, { payload }) => {
        return { ...state, loading: false, projectData: '', error: payload };
      },
      
      [startCreateProject.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateProject.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createProject: payload, error: '' };
      },
      [startCreateProject.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createProject: '', error: payload };
      },

      [startUpdateProject.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateProject.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateProject: payload, error: '' };
      },
      [startUpdateProject.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateProject: '', error: payload };
      },

      [startDeleteProject.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteProject: payload, error: '' };
      },
      [startDeleteProject.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteProject: '', error: payload };
      },

      [startActiveProject.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeProject: payload, error: '' };
      },
      [startActiveProject.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeProject: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const projectActions = {
    ...projectSlice.actions,
    startFetchProjects,
    startFetchProject,
    startCreateProject,
    startUpdateProject,
    startDeleteProject,
    startActiveProject,
    setSearch,
    setCurrentPage,
    setSort
}
export default projectSlice.reducer