import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, DAYS_OFF } from '../../constants/defaultValues';

const startUpdateDaysOff = createAsyncThunk('daysOff/startUpdateDaysOff', async ({ daysOff, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${DAYS_OFF}/update`, daysOff, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDaysOff = createAsyncThunk('daysOff/startFetchDaysOff', async ({ history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${DAYS_OFF}`, { headers });

        const data = res.data;

        const results = {
          days: data
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const initialState = {
    updateDaysOff: '',
    daysOffData: {
        days: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const daysOffSlice = createSlice({
    name: 'daysOff',
    initialState,
    reducers: {
        clearDaysOff(state) {
            return { ...state, loadingBtn: false, loading: false, daysOffData: initialState.daysOffData, error: '' };
        },
        clearAlertDaysOff(state) {
            return { ...state, updateDaysOff: '', error: '' };
        }
    },
    extraReducers: {
      [startUpdateDaysOff.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateDaysOff.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateDaysOff: payload, error: '' };
      },
      [startUpdateDaysOff.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateDaysOff: '', error: payload };
      },

      [startFetchDaysOff.pending]: (state) => {
        return { ...state, loading: true, loadingBtn: false, error: '' };
      },
      [startFetchDaysOff.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, daysOffData: payload, error: '' };
      },
      [startFetchDaysOff.rejected]: (state, { payload }) => {
        return { ...state, loading: false, daysOffData: '', error: payload };
      }
    },
});


export const daysOffActions = {
    ...daysOffSlice.actions,
    startUpdateDaysOff,
    startFetchDaysOff
}
export default daysOffSlice.reducer