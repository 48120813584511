import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, CAMPAIGN } from '../../constants/defaultValues';

const startFetchCampaigns = createAsyncThunk('campaign/startFetchCampaigns', async ({ locale, token, filter, loading }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(campaignSlice.actions.actionCampaign(loading))
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CAMPAIGN}?
        pageSize=${filter.pageSize}
        &currentPage=${filter.currentPage}
        &orderBy=${filter.orderBy}
        &search=${filter.search}
        &users=${filter.searchFilter.user.length > 0 ? filter.searchFilter.user.join() : ""}
        &schedule_types=${filter.searchFilter.schedule_type.length > 0 ? filter.searchFilter.schedule_type.join() : ""}
        &email_options=${filter.searchFilter.email_option.length > 0 ? filter.searchFilter.email_option.join() : ""}
        &email_types=${filter.searchFilter.email_type.length > 0 ? filter.searchFilter.email_type.join() : ""}
        &channels=${filter.searchFilter.channel.length > 0 ? filter.searchFilter.channel.join() : ""}
    
        &fromDate_type=${filter.searchFilter.fromDate_type}
        &toDate_type=${filter.searchFilter.toDate_type}
       
        `, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('campaign/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CAMPAIGN}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSearchFields = createAsyncThunk('campaign/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CAMPAIGN}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveCampaign = createAsyncThunk('campaign/startActiveAgency', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${CAMPAIGN}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteCampaign = createAsyncThunk('campaign/startDeleteCampaign', async ({ locale, token, id, campaigns }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${CAMPAIGN}/destroy/${id}`, { headers });

        const remove = campaigns.data.filter(item => item.id !== id);

        const newArray = {
            data: remove,
            totalItemCount: campaigns.totalItemCount - 1
        }

        dispatch(campaignSlice.actions.fetchCampaignsSuccess(newArray))

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateCampaign = createAsyncThunk('campaign/startUpdateCampaign', async ({ id, campaign, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CAMPAIGN}/update/${id}`, campaign, { headers });

        if(res.data){
            form.setStatus({ success: true });
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchCampaign = createAsyncThunk('campaign/startFetchCampaign', async ({ campaign, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CAMPAIGN}/edit/${campaign}`, { headers });

        return res.data;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


const startCreateCampaign = createAsyncThunk('campaign/startCreateCampaign', async ({ campaign, locale, token, form, user_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CAMPAIGN}/store`, campaign, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
            form.setFieldValue('operator', user_id)
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSubcategory = createAsyncThunk('campaign/startFetchSubcategory', async ({ locale, token, ifEdit, category_ids }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CAMPAIGN}/selectSubcategory`, {category_ids: category_ids, edit: ifEdit}, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchMunicipalities = createAsyncThunk('campaign/startFetchMunicipalities', async ({ locale, token, ifEdit, prefecture_ids }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CAMPAIGN}/getMunicipality`, { prefecture_ids: prefecture_ids, edit: ifEdit }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAreas = createAsyncThunk('campaign/startFetchAreas', async ({ locale, token, ifEdit, prefecture_ids, municipality_ids }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CAMPAIGN}/getArea`, { prefecture_ids: prefecture_ids, municipality_ids: municipality_ids, edit: ifEdit }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchMatchEmails = createAsyncThunk('campaign/startFetchMatchEmails', async ({ locale, token, search }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CAMPAIGN}/matchEmails`, search, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchActivityCampaign = createAsyncThunk('campaign/startFetchActivityCampaign', async ({ id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CAMPAIGN}/activityCampaign/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteCampaignActivity = createAsyncThunk('campaign/startDeleteCampaignActivity', async ({ locale, token, id, activityCampaigns }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${CAMPAIGN}/destroyActivity/${id}`, { headers });

        const remove = activityCampaigns.filter(item => item.id !== id);

        const newArray = remove;

        return { status: res.data, data: newArray };
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startSaveTemplate = createAsyncThunk('campaign/startSaveTemplate', async ({ locale, token, title, design, body }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CAMPAIGN}/saveTemplate`, { title, design, body }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchTemplates = createAsyncThunk('campaign/startFetchTemplates', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CAMPAIGN}/loadTemplates`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteTempalte = createAsyncThunk('campaign/startDeleteTempalte', async ({ locale, token, id, templates }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${CAMPAIGN}/destroyTemplate/${id}`, { headers });

        const remove = templates.filter(item => item.id !== id);

        const newArray = remove;

        return { status: res.data, data: newArray };
    } catch (error) {
    console.log(error)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSample = createAsyncThunk('campaign/startFetchSample', async ({ campaign_id, locale, token, toggle }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CAMPAIGN}/fetchSampleData`, {campaign_id: campaign_id}, { headers });

        return res.data;
    } catch (error) {
        toggle(true);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const sendSample = createAsyncThunk('campaign/sendSample', async ({ formData, locale, token, form, toggle }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CAMPAIGN}/sendSampleData`, formData, { headers });

        if(res.data){
            toggle(true);
            form.resetForm();
            form.setStatus({ success: true });
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchContactsCampaign = createAsyncThunk('campaign/startFetchContactsCampaign', async ({ locale, token, search }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CAMPAIGN}/contactsCampaign`, search, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('campaign/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('campaign/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('campaign/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const setSelectedOrderOption = createAsyncThunk('campaign/setSelectedOrderOption', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    campaigns: {
        data: [],
        totalItemCount: 0
    },
    displayMode: "list",
    selectedPageSize: 10,
    selectedOrderOption: "",
    search: "",
    searchValue: "",
    searchFilterType: 1,
    fields: [],
    searchFilter: [
        { translate: "menu.users", id: "user", dataKey: "users", search: "", type: 1},
        { translate: "campaigns.schedule-type", id: "schedule_type", dataKey: "schedule_types", search: "", type: 1},
        { translate: "campaigns.emails-option", id: "email_option", dataKey: "email_options", search: "", type: 1},
        { translate: "campaigns.email-type", id: "email_type", dataKey: "email_types", search: "", type: 1},
        { translate: "campaigns.channels", id: "channel", dataKey: "channels", search: "", type: 1},


        { translate: "general.date", id: "date", dataKey: "", search: "", type: 1 },
    ],
    searchFilterValues: {
        user: [],
        schedule_type: [],
        email_option: [],
        email_type: [],
        channel: [],

        fromDate_type: "",
        toDate_type: "",
    },
    currentPage: 1,
    mutchEmails: 0,
    campaignData: {
        title: '',
        sch_type: '',
        rec_times: '',
        rec_days: '',
        date: "",
        time: "",
        email_option: '',
        email_type: '',
        email_filter: '',
        //contacts_filters
        contact_types: [],
        sources: [],
        countries: [],
        registers: [],
        member_types: [],
        //requests_filters
        status: [],
        categories: [],
        agents: [],
        cr_date_from: "",
        cr_date_to: "",
        budget_from: "",
        budget_to: "",
        //properties_filters
        availability: "",
        subcategories: [],
        prefectures: [],
        municipalities: [],
        areas: [],
        price_from: "",
        price_to: "",
        energy_class: [],
        assignment_orders: [],
        finalities: [],
        synchronized: "",
        //properties_bucket_filters
        inc_date_from: "",
        inc_date_to: "",

        channels: [],
        //field channels
        subject: "",
        body: "",
        design: "",
        message_sms: "",
        message_viber: "",
        image: "",
        image_name: '',
        file: '',
        btn: '',
        btn_link: '',

        contacts: [],
        contacts_fields: [],
        contacts_loading: false,
        files: [],
        filesData: [],
        excludes: []
    },
    sampleData: {
        countries: [],
        channels: [],
        selected_channels: [],
        email: '',
        code: '',
        mobile: '',
        country: ''
    },
    createCampaign: '',
    updateCampaign: '',
    deleteCampaign: '',
    activeCampaign: '',
    saveTemplate: '',
    sample: '',
    contactsCampaign: [],
    activityCampaign: [],
    templates: [],
    deleteCampaignActivity: '',
    deleteTemplate: '',
    dataFields: {
        schedule_types: [],
        email_options: [],
        email_types: [],
        email_filters: [],
        contact_types: [],
        sources: [],
        countries: [],
        registers: [],
        member_types: [],
        status_reqs: [],
        categories: [],
        users: [],
        prefectures: [],
        energy_class: [],
        assignment_orders: [],
        finalities: [],
        synchronized: [],
        availabilities: [],
        status_props: [],
        channels: []
    },
    subcategories: [],
    municipalities: [],
    areas: [],
    loadingBtn: false,
    loadingModal: false,
    loading: true,
    loadingSearch: false,
    loadingMutchEmails: false,
    loadingActivityModal: false,
    loadingContactsModal: false,
    loadingTemplateModal: false,
    error: ''
}

export const campaignSlice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        actionCampaign(state, { payload }) {
            return { ...state, loading: payload ? true : false, loadingSearch: true, error: '' };
        },
        fetchCampaignsSuccess(state, { payload }) {
            return { ...state, campaigns: payload, loading: false, loadingSearch: false, loadingMutchEmails: false, error: '' };
        },
        clearCampaign(state) {
            return { ...state, loadingBtn: false, loadingModal: false, loading: false, loadingSearch: false, loadingMutchEmails: false, loadingActivityModal: false, loadingContactsModal: false, loadingTemplateModal: false, campaignData: initialState.campaignData, mutchEmails: initialState.mutchEmails, contactsCampaign: initialState.contactsCampaign, error: '' };
        },
        clearAlertCampaign(state) {
            return { ...state, updateCampaign: '', createCampaign: '', deleteCampaign: '', activeCampaign: '', deleteCampaignActivity: '', sample: '', deleteTemplate: '', saveTemplate: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        setFilter(state, { payload }) {
            return { ...state, searchFilterValues: payload };
        },
        setFilterType(state, { payload }) {
            return { ...state, searchFilterType: payload };
        },
        setFilterFieldSearch(state, { payload }) {
            return { ...state, fields: payload.dataValues, searchFilter: payload.values };
        },
        setDisplayMode(state, { payload }) {
            return { ...state, displayMode: payload };
        },
        dataSelectAreas(state, { payload }) {
            return { ...state, areas: payload, error: '' };
        },
        dataSelectSubcategory(state, { payload }) {
            return { ...state, subcategory: payload.subcategory, error: '' };
        },
        dataSelectMunicipalities(state, { payload }) {
            return { ...state, municipalities: payload, error: '' };
        },
        clearMutchEmails(state) {
            return { ...state, mutchEmails: initialState.mutchEmails };
        },
        clearSample(state) {
            return { ...state, loadingBtn: false, sampleData: initialState.sampleData, sample: '', error: '' };
        }
    },
    extraReducers: {
      [startFetchCampaigns.fulfilled]: (state, { payload }) => {
        return { ...state, campaigns: payload, loading: false, loadingSearch: false, error: '' };
      },
      [startFetchCampaigns.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, campaigns: initialState.campaigns, error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, fields: payload, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, fields: initialState.fields, error: payload };
      },

      [startActiveCampaign.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeCampaign: payload, error: '' };
      },
      [startActiveCampaign.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeCampaign: '', error: payload };
      },

      [startDeleteCampaign.fulfilled]: (state, { payload }) => {
        return { ...state, deleteCampaign: payload, error: '' };
      },
      [startDeleteCampaign.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteCampaign: '', error: payload };
      },

      [startUpdateCampaign.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateCampaign.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateCampaign: payload, error: '' };
      },
      [startUpdateCampaign.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateCampaign: '', error: payload };
      },

      [startFetchCampaign.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchCampaign.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, campaignData: payload, error: '' };
      },
      [startFetchCampaign.rejected]: (state, { payload }) => {
        return { ...state, loading: false, campaignData: '', error: payload };
      },

      [startCreateCampaign.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateCampaign.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createCampaign: payload, error: '' };
      },
      [startCreateCampaign.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createCampaign: '', error: payload };
      },

      [startFetchSubcategory.fulfilled]: (state, { payload }) => {
        return { ...state, subcategories: payload, error: '' };
      },
      [startFetchSubcategory.rejected]: (state, { payload }) => {
        return { ...state, subcategories: initialState.subcategories, error: payload };
      },

      [startFetchMunicipalities.fulfilled]: (state, { payload }) => {
        return { ...state, municipalities: payload, error: '' };
      },
      [startFetchMunicipalities.rejected]: (state, { payload }) => {
        return { ...state, municipalities: initialState.municipalities, error: payload };
      },

      [startFetchAreas.fulfilled]: (state, { payload }) => {
        return { ...state, areas: payload, error: '' };
      },
      [startFetchAreas.rejected]: (state, { payload }) => {
        return { ...state, areas: initialState.areas, error: payload };
      },

      [startFetchMatchEmails.pending]: (state) => {
        return { ...state, loadingMutchEmails: true, error: '' };
      },
      [startFetchMatchEmails.fulfilled]: (state, { payload }) => {
        return { ...state, loadingMutchEmails: false, mutchEmails: payload, error: '' };
      },
      [startFetchMatchEmails.rejected]: (state, { payload }) => {
        return { ...state, loadingMutchEmails: false, mutchEmails: initialState.mutchEmails, error: payload };
      },

      [startFetchActivityCampaign.pending]: (state) => {
        return { ...state, loadingActivityModal: true, error: '' };
      },
      [startFetchActivityCampaign.fulfilled]: (state, { payload }) => {
        return { ...state, loadingActivityModal: false, activityCampaign: payload, error: '' };
      },
      [startFetchActivityCampaign.rejected]: (state, { payload }) => {
        return { ...state, loadingActivityModal: false, activityCampaign: initialState.activityCampaign, error: payload };
      },

      [startDeleteCampaignActivity.fulfilled]: (state, { payload }) => {
        return { ...state, deleteCampaignActivity: payload.status, activityCampaign: payload.data, error: '' };
      },
      [startDeleteCampaignActivity.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteCampaignActivity: '', activityCampaign: initialState.activeCampaign, error: payload };
      },

      [startSaveTemplate.fulfilled]: (state, { payload }) => {
        return { ...state, saveTemplate: payload, error: '' };
      },
      [startSaveTemplate.rejected]: (state, { payload }) => {
        return { ...state, loading: false, saveTemplate: '', error: payload };
      },

      [startFetchTemplates.pending]: (state) => {
        return { ...state, loadingTemplateModal: true, error: '' };
      },
      [startFetchTemplates.fulfilled]: (state, { payload }) => {
        return { ...state, loadingTemplateModal: false, templates: payload, error: '' };
      },
      [startFetchTemplates.rejected]: (state, { payload }) => {
        return { ...state, loadingTemplateModal: false, templates: initialState.templates, error: payload };
      },

      [startDeleteTempalte.fulfilled]: (state, { payload }) => {
        return { ...state, deleteTemplate: payload.status, templates: payload.data, error: '' };
      },
      [startDeleteTempalte.rejected]: (state, { payload }) => {
        return { ...state, deleteTemplate: '', templates: initialState.templates, error: payload };
      },

      [startFetchSample.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startFetchSample.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, sampleData: payload, error: '' };
      },
      [startFetchSample.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, sampleData: initialState.sampleData, error: payload };
      },

      [sendSample.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [sendSample.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, sample: payload, error: '' };
      },
      [sendSample.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, sample: '', error: payload };
      },

      [startFetchContactsCampaign.pending]: (state) => {
        return { ...state, loadingContactsModal: true, error: '' };
      },
      [startFetchContactsCampaign.fulfilled]: (state, { payload }) => {
        return { ...state, loadingContactsModal: false, contactsCampaign: payload, error: '' };
      },
      [startFetchContactsCampaign.rejected]: (state, { payload }) => {
        return { ...state, loadingContactsModal: false, contactsCampaign: initialState.contactsCampaign, error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      },
      [setSelectedOrderOption.fulfilled]: (state, { payload }) => {
        return { ...state, selectedOrderOption: payload, currentPage: 1 };
      },
    },
});


export const campaignActions = {
    ...campaignSlice.actions,
    startFetchCampaigns,
    startFetchDataFields,
    startFetchSearchFields,
    startActiveCampaign,
    startDeleteCampaign,
    startUpdateCampaign,
    startFetchCampaign,
    startCreateCampaign,
    startFetchSubcategory,
    startFetchMunicipalities,
    startFetchAreas,
    startFetchMatchEmails,
    startFetchActivityCampaign,
    startDeleteCampaignActivity,
    startSaveTemplate,
    startFetchTemplates,
    startDeleteTempalte,
    startFetchSample,
    sendSample,
    startFetchContactsCampaign,
    setSearch,
    setCurrentPage,
    setSort,
    setSelectedOrderOption
}
export default campaignSlice.reducer