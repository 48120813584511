import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, ASSIGNMENTS } from '../../constants/defaultValues';

const startFetchAssignments = createAsyncThunk('assignment/startFetchAssignments', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${ASSIGNMENTS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteAssignment = createAsyncThunk('assignment/startDeleteAssignment', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${ASSIGNMENTS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startPdfAssignment = createAsyncThunk('assignment/startPdfAssignment', async ({ locale, token, id }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(assignmentSlice.actions.actionPdfAssignment(id));
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ASSIGNMENTS}/pdf/${id}`, { headers });

        let pdfWindow = window.open("Assignment Order", "_blank", "toolbar=1, scrollbars=1, resizable=1, width=" + 600 + ", height=" + 800);
        pdfWindow.document.write("<html<head><title>HALKIDIKI PROPERTIES</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
        pdfWindow.document.write("<body><embed width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(res.data)+"#toolbar=1&navpanes=0&scrollbar=0'></embed></body></html>");

        return true;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('assignment/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('assignment/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('assignment/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    assignments: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:true, id: "id"}],
    deleteAssignment: '',
    pdfAssignment: '',
    loadingPdfBtn: false,
    loadingBtn: false,
    loading: true,
    error: ''
}

export const assignmentSlice = createSlice({
    name: 'assignment',
    initialState,
    reducers: {
        fetchAssignmentsSuccess(state, { payload }) {
            return { ...state, loading: false, assignments: payload, error: '' };
        },
        actionPdfAssignment(state, { payload }) {
            return { ...state, loadingPdfBtn: true, pdfAssignment: payload, error: '' };
        },
        clearAlertAssignment(state) {
            return { ...state, deleteAssignment: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchAssignments.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchAssignments.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, assignments: payload, error: '' };
      },
      [startFetchAssignments.rejected]: (state, { payload }) => {
        return { ...state, loading: false, assignments: initialState.assignments, error: payload };
      },

      [startDeleteAssignment.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteAssignment: payload, error: '' };
      },
      [startDeleteAssignment.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteAssignment: '', error: payload };
      },

  
      [startPdfAssignment.fulfilled]: (state) => {
        return { ...state, loadingPdfBtn: false, pdfAssignment: '', error: '' };
      },
      [startPdfAssignment.rejected]: (state, { payload }) => {
        return { ...state, loadingPdfBtn: false, pdfAssignment: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const assignmentActions = {
    ...assignmentSlice.actions,
    startFetchAssignments,
    startDeleteAssignment,
    startPdfAssignment,
    setSearch,
    setCurrentPage,
    setSort
}
export default assignmentSlice.reducer