import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, SELLS } from '../../constants/defaultValues';

const startFetchSells = createAsyncThunk('sell/startFetchSells', async ({ locale, token, filter, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${SELLS}?portalId=${portal_id}&pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateSell = createAsyncThunk('sell/startCreateSell', async ({ sell, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${SELLS}/store`, sell, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateSell = createAsyncThunk('sell/startUpdateSell', async ({ id, sell, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${SELLS}/update/${id}`, sell, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteSell = createAsyncThunk('sell/startDeleteSell', async ({ locale, token, id, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${SELLS}/destroy/${portal_id}/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveSell = createAsyncThunk('sell/startActiveSell', async ({ locale, token, id, action, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${SELLS}/active/${id}`, { action: action, portal_id: portal_id }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSell = createAsyncThunk('sell/startFetchSell', async ({ portal_id, sell, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${SELLS}/edit/${portal_id}/${sell}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            portal_id: data.portal_id,
            template: data.template,
            image_or_yt: data.image_or_yt,
            bottom_img_yt_multiply: data.bottom_img_yt_multiply,
            extra_text: data.extra_text ? true : false,
            url_en: data.url_en,
            url_el: data.url_el,
            url_de: data.url_de,
            url_sr: data.url_sr,
            url_bg: data.url_bg,
            url_ru: data.url_ru,
            title_en: data.title_en,
            title_el: data.title_el,
            title_de: data.title_de,
            title_sr: data.title_sr,
            title_bg: data.title_bg,
            title_ru: data.title_ru,
            text_en: data.text_en,
            text_el: data.text_el,
            text_de: data.text_de,
            text_sr: data.text_sr,
            text_bg: data.text_bg,
            text_ru: data.text_ru,
            subtext_en: data.subtext_en,
            subtext_el: data.subtext_el,
            subtext_de: data.subtext_de,
            subtext_sr: data.subtext_sr,
            subtext_bg: data.subtext_bg,
            subtext_ru: data.subtext_ru,
            meta_title_en: data.page_config ? data.page_config.meta_title_en : '',
            meta_title_el: data.page_config ? data.page_config.meta_title_el : '',
            meta_title_de: data.page_config ? data.page_config.meta_title_de : '',
            meta_title_sr: data.page_config ? data.page_config.meta_title_sr : '',
            meta_title_bg: data.page_config ? data.page_config.meta_title_bg : '',
            meta_title_ru: data.page_config ? data.page_config.meta_title_ru : '',
            meta_desc_en: data.page_config ? data.page_config.meta_desc_en : '',
            meta_desc_el: data.page_config ? data.page_config.meta_desc_el : '',
            meta_desc_de: data.page_config ? data.page_config.meta_desc_de: '',
            meta_desc_sr: data.page_config ? data.page_config.meta_desc_sr : '',
            meta_desc_bg: data.page_config ? data.page_config.meta_desc_bg : '',
            meta_desc_ru: data.page_config ? data.page_config.meta_desc_ru : '',
            meta_lsi_en: data.page_config ? data.page_config.meta_lsi_en : [],
            meta_lsi_el: data.page_config ? data.page_config.meta_lsi_el : [],
            meta_lsi_de: data.page_config ? data.page_config.meta_lsi_de : [],
            meta_lsi_sr: data.page_config ? data.page_config.meta_lsi_sr : [],
            meta_lsi_bg: data.page_config ? data.page_config.meta_lsi_bg : [],
            meta_lsi_ru: data.page_config ? data.page_config.meta_lsi_ru : [],
            youtube_url: data.youtube_url,
            youtube_url_bottom: data.youtube_url_bottom,
            sort: data.sort,
            image: data.image,
            image_name: data.image,
            image_bottom: data.image_bottom,
            image_bottom_name: data.image_bottom_name,
            images_bottom_multiply: data.images_bottom_multiply
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('sell/setSearch', ( args) => {
    return args;
});

const setCurrentPage = createAsyncThunk('sell/setCurrentPage', ( args) => {
    return args;
});

const setSort = createAsyncThunk('sell/setSort', ( args) => {
    return args;
});

const initialState = {
    sells: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    sellData: {
        portal_id: '',
        template: 1,
        image_or_yt: 0,
        bottom_img_yt_multiply: 0,
        extra_text: false,
        sort: 0,
        url_en: '',
        url_el: '',
        url_de: '',
        url_sr: '',
        url_bg: '',
        url_ru: '',
        title_en: '',
        title_el: '',
        title_de: '',
        title_sr: '',
        title_bg: '',
        title_ru: '',
        text_en: '',
        text_el: '',
        text_de: '',
        text_sr: '',
        text_bg: '',
        text_ru: '',
        subtext_en: '',
        subtext_el: '',
        subtext_de: '',
        subtext_sr: '',
        subtext_bg: '',
        subtext_ru: '',
        meta_title_en: '',
        meta_title_el: '',
        meta_title_de: '',
        meta_title_sr: '',
        meta_title_bg: '',
        meta_title_ru: '',
        meta_desc_en: '',
        meta_desc_el: '',
        meta_desc_de: '',
        meta_desc_sr: '',
        meta_desc_bg: '',
        meta_desc_ru: '',
        meta_lsi_en: [],
        meta_lsi_el: [],
        meta_lsi_de: [],
        meta_lsi_sr: [],
        meta_lsi_bg: [],
        meta_lsi_ru: [],
        youtube_url: '',
        youtube_url_bottom: '',
        image: '',
        image_name: '',
        image_bottom: '',
        image_bottom_name: '',
        images_bottom_multiply: []
    },
    createSell: '',
    updateSell: '',
    deleteSell: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const sellSlice = createSlice({
    name: 'sell',
    initialState,
    reducers: {
        fetchSellsSuccess(state, { payload }) {
            return { ...state, loading: false, sells: payload, error: '' };
        },
        clearSell(state) {
            return { ...state, loadingBtn: false, loading: false, sellData: initialState.sellData, error: '' };
        },
        clearAlertSell(state) {
            return { ...state, updateSell: '', createSell: '', deleteSell: '', activeSell: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchSells.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSells.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, sells: payload, error: '' };
      },
      [startFetchSells.rejected]: (state, { payload }) => {
        return { ...state, loading: false, sells: initialState.sells, error: payload };
      },

      [startFetchSell.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSell.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, sellData: payload, error: '' };
      },
      [startFetchSell.rejected]: (state, { payload }) => {
        return { ...state, loading: false, sellData: '', error: payload };
      },
      
      [startCreateSell.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateSell.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createSell: payload, error: '' };
      },
      [startCreateSell.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createSell: '', error: payload };
      },

      [startUpdateSell.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateSell.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateSell: payload, error: '' };
      },
      [startUpdateSell.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateSell: '', error: payload };
      },

      [startDeleteSell.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteSell: payload, error: '' };
      },
      [startDeleteSell.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteSell: '', error: payload };
      },

      [startActiveSell.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeSell: payload, error: '' };
      },
      [startActiveSell.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeSell: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const sellActions = {
    ...sellSlice.actions,
    startFetchSells,
    startFetchSell,
    startCreateSell,
    startUpdateSell,
    startDeleteSell,
    startActiveSell,
    setSearch,
    setCurrentPage,
    setSort
}
export default sellSlice.reducer