import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, KNOWLEDGECOMMENTS } from '../../constants/defaultValues';

const startFetchComments = createAsyncThunk('knowledgeDatabaseComment/startFetchComments', async ({ id, locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${KNOWLEDGECOMMENTS}?pageSize=${filter.pageSize}&currentPage=${filter.currentPage}&knowledge_database_id=${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteComment = createAsyncThunk('knowledgeDatabaseComment/startDeleteComment', async ({ id, locale, token, comments, index }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${KNOWLEDGECOMMENTS}/destroy/${id}`, { headers });

        let new_comments = JSON.parse(JSON.stringify(comments)); 
        const data = new_comments.data.filter((c,i) => i !== index);

        new_comments.data = data;
        new_comments.totalItemCount = new_comments.totalItemCount - 1;

        dispatch(knowledgeDatabaseCommentSlice.actions.fetchCommentsSuccess(new_comments));

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateComment = createAsyncThunk('knowledgeDatabaseComment/startUpdateComment', async ({ id, comment, locale, token, comments, index }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${KNOWLEDGECOMMENTS}/update/${id}`, {comment: comment}, { headers });

        let new_comments = JSON.parse(JSON.stringify(comments)); 
        new_comments.data[index].comment = comment;

        dispatch(knowledgeDatabaseCommentSlice.actions.fetchCommentsSuccess(new_comments));

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateComment = createAsyncThunk('knowledgeDatabaseComment/startCreateComment', async ({ comment, locale, token, comments, new_comment }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${KNOWLEDGECOMMENTS}/store`, comment, { headers });

        let new_comments = JSON.parse(JSON.stringify(comments)); 
        new_comment['id'] = res.data;

        new_comments.data.unshift(new_comment);
        new_comments.totalItemCount = new_comments.totalItemCount + 1;

        dispatch(knowledgeDatabaseCommentSlice.actions.fetchCommentsSuccess(new_comments));

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setCurrentPage = createAsyncThunk('knowledgeDatabaseComment/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});


const initialState = {
    comments: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    currentPage: 1,
    createComment: '',
    updateComment: '',
    deleteComment: '',
    loadingBtn: false,
    loading: true,
    error: ''
};

export const knowledgeDatabaseCommentSlice = createSlice({
    name: 'knowledgeDatabaseComment',
    initialState,
    reducers: {
        clearAlertComment(state) {
            return { ...state, updateComment: '', createComment: '', deleteComment: '', activeComment: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 };
        },
        fetchCommentsSuccess(state, { payload }) {
            return { ...state, loading: false, comments: payload, error: '' };
        },
       
    },
    extraReducers: {
      [startFetchComments.pending]: (state) => {
        return { ...state, loading: true, loadingBtn: false, error: '' };
      },
      [startFetchComments.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, comments: payload, error: '' };
      },
      [startFetchComments.rejected]: (state, { payload }) => {
        return { ...state, loading: false, comments: initialState.comments, error: payload.message };
      },

      [startDeleteComment.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteComment: payload, error: '' };
      },
      [startDeleteComment.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteComment: '', error: payload.message };
      },

      [startUpdateComment.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateComment.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateComment: payload, error: '' };
      },
      [startUpdateComment.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateComment: '', error: payload.message };
      },

      [startCreateComment.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateComment.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createComment: payload, error: '' };
      },
      [startCreateComment.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createComment: '', error: payload.message };
      },

     

      //Heading Functions
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      }
    },
});

export const knowledgeDatabaseCommentActions = {
    ...knowledgeDatabaseCommentSlice.actions,
    startFetchComments,
    startDeleteComment,
    startUpdateComment,
    startCreateComment,
    setCurrentPage
}
export default knowledgeDatabaseCommentSlice.reducer