import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, TASKS } from '../../constants/defaultValues';
import { calendarActions } from "../calendar/calendarSlice"

const startCreateTask = createAsyncThunk('task/startCreateTask', async ({ task, locale, token, form, toggle = null, data = null }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${TASKS}/store`, task, { headers });

        form.resetForm()
        form.setStatus({ success: true })

        if(toggle){
            let new_data = JSON.parse(JSON.stringify(data));

            let event = {
              id: res.data.task,
              title: task.task_name_event,
              subtitle: task.user_name_event,
              category: 1,
              start: task.start + ' 00:00:01',
              end: task.end + ' 23:59:59',
              class: "warning"
            }
            new_data.data.push(event);
            new_data.totalItemCount = new_data.totalItemCount + 1;
            dispatch(calendarActions.fetchCalendarSuccess(new_data))
            //calendarApi.getApi().addEvent(event);
            toggle();
        }

        return res.data.status;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchTask = createAsyncThunk('task/startFetchTask', async ({ task, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TASKS}/edit/${task}`, { headers });

        const data = res.data;

        const results = {
          edit: true,
          new_task: 0,
          task_name_en: '',
          task_name_el: '',
          task_type_id: data.task_type_id,
          type: data.type,
          all_date: data.all_date,
          recurring_times: data.recurring_times ? data.recurring_times : '',
          recurring_days: data.recurring_days ? data.recurring_days : '',
          assign_user: data.assign_user,
          start: data.start,
          start_time: data.start_time ? data.start_time : "",
          end: data.end,
          end_time: data.end_time ? data.end_time : "",
          message: data.message ? data.message : '',
          status: data.status,
          has_notification: data.has_notification,
          notifications: data.notifications ? data.notifications : '',
          properties: data.properties,
          contacts: data.contacts,
          contacts_fields: data.contacts_fields,
          contacts_loading: false
        };

        return results;
    } catch (error) {
        history.push('/error');

        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateTask = createAsyncThunk('task/startUpdateTask', async ({ id, task, locale, token, form, toggle, data, calendarApi }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${TASKS}/update/${id}`, task, { headers });

        form.setStatus({ success: true })

        if(toggle){
            let dts = JSON.parse(JSON.stringify({...data}));
  
            let event = calendarApi.getApi().getEventById(id);
  
            dts.data.forEach((item, i) => {
                if (item.id === res.data.task) {
                  dts.data[i] = {
                      id: res.data.task,
                      title: task.task_name_event,
                      subtitle: task.user_name_event,
                      category: 1,
                      start: task.start + ' 00:00:01',
                      end: task.end + ' 23:59:59',
                      class: "warning"
                    };
  
                    
                    event.setProp( 'title', task.task_name_event);
                    event.setProp( 'start', task.start + ' 00:00:01');
                    event.setProp( 'end', task.end + ' 23:59:59');
                    event.setExtendedProp( 'class', 'warning');
                    event.setExtendedProp( 'subtitle', task.user_name_event);
                }
            });
            // calendarApi.getApi().removeAllEventSources();
            //calendarApi.getApi().removeAllEvents();
            let new_data = dts;
            dispatch(calendarActions.fetchCalendarSuccess(new_data))  
            toggle();
        }

        return res.data.status;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)

        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('task/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TASKS}/dataFields?edit=${ifEdit}&lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSearchFields = createAsyncThunk('task/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TASKS}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCopyTask = createAsyncThunk('task/startCopyTask', async ({ locale, token, id, user_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${TASKS}/copy/${id}`, { user_id: user_id}, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteTask = createAsyncThunk('task/startDeleteTask', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${TASKS}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startStatusTask = createAsyncThunk('task/startStatusTask', async ({ locale, token, id, action, toggle = null, data = null, calendarApi = null, properties = [], files = [], filesData = [] }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        let formData = new FormData();

        formData.append('action', action);
        formData.append('properties', JSON.stringify(properties));
        formData.append('filesData', JSON.stringify(filesData));

        if (files.length > 0) {
            files.map((file, index) => {
                return formData.append(`files[${index}]`, file);
            })
        }

        const res = await axios.post(`${TASKS}/status/${id}`, formData, { headers });

        if(res.data && calendarApi){
            let dts = JSON.parse(JSON.stringify(data));
  
            dts.data.forEach((item, i) => {
                if (item.id === id) {    
                  dts.data[i]['class'] = "success";
                }
            });
  
            let event = calendarApi.getApi().getEventById(id);
            event.setExtendedProp( 'class', 'success')
  
            dispatch(calendarActions.fetchCalendarSuccess(dts))  
            toggle();
        }

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchTasks = createAsyncThunk('task/startFetchTasks', async ({ locale, token, filter, loading }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(taskSlice.actions.actionTask(loading))
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${TASKS}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}
        &users=${filter.searchFilter.user.length > 0 ? filter.searchFilter.user.join() : ""}
        &fromDate_type=${filter.searchFilter.fromDate_type}
        &toDate_type=${filter.searchFilter.toDate_type}
        `, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('task/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('task/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('task/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    tasks: {
        data: [],
        totalItemCount: 0,
        users: []
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    searchFilterType: 1,
    fields: [],
    searchFilter: [
        { translate: "menu.users", id: "user", dataKey: "users", search: "", type: 1},
        { translate: "requests.date-of-creation", id: "creation_type", dataKey: "", search: "", type: 1 },
    ],
    searchFilterValues: {
        user: [],

        fromDate_type: "",
        toDate_type: "",
    },
    sort: [{ desc: false, id: "id" }],
    createTask: '',
    updateTask: '',
    statusTask: '',
    deleteTask: '',
    copyTask: '',
    taskData: {
        edit: false,
        new_task: 0,
        task_name_en: '',
        task_name_el: '',
        task_type_id: '',
        type: '',
        all_date: 0,
        recurring_times: '',
        recurring_days: '',
        assign_user: '',
        start: null,
        start_time: null,
        end: null,
        end_time: null,
        message: '',
        status: '',
        has_notification: 0,
        notifications: [],
        properties: [
            { property_id: '', minus_plus: 0 }
        ],
        contacts: [],
        contacts_fields: [],
        contacts_loading: false
    },
    dataFields: {
        tasks: [],
        types: [],
        users: []
    },
    loadingBtn: false,
    loadingSearch: false,
    loading: false,
    error: ''
}

export const taskSlice = createSlice({
    name: 'task',
    initialState: {...initialState},
    reducers: {
        fetchTasksSuccess(state, { payload }) {
            return {...state, loading: false, loadingSearch: false, tasks: payload, error: ''}
        },
        clearTask(state){
            return {...state, loadingBtn: false, loading: false, loadingSearch: false, taskData: initialState.taskData, error: ''}
        },
        clearAlertTask(state){
            return {...state, updateTask: '', createTask: '', deleteTask: '', statusTask: '', copyTask: '', error: ''}
        },
        setSelectedPageSize(state, { payload }){
            return {...state, selectedPageSize: payload, currentPage: 1}
        },
        setSearchValue(state, { payload }){
            return {...state, searchValue: payload}
        },
        setFilter(state, { payload }){
            return {...state, searchFilterValues: payload}
        },
        setFilterType(state, { payload }){
            return {...state, searchFilterType: payload}
        },
        setFilterFieldSearch(state, { payload }){
            let { dataValues, values } = payload;

            return {...state, fields: dataValues, searchFilter: values}
        },
        actionTask(state, { payload }){
            return {...state, loading: payload ? true : false, loadingSearch: true, error: ''}
        },
    },
    extraReducers: {
      [startCreateTask.pending]: (state) => {
        state.loadingBtn = true
        state.error = ''
      },
      [startCreateTask.fulfilled]: (state, { payload }) => {
        state.createTask = payload;
        state.loadingBtn = false;
        state.error = '';
      },
      [startCreateTask.rejected]: (state, { payload }) => {
        state.loadingBtn = false
        state.error = payload
        state.createTask = ''
      },

      [startFetchTask.pending]: (state) => {
        state.loading = true
        state.loadingBtn = false
        state.error = ''
      },
      [startFetchTask.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.taskData = payload;
      },
      [startFetchTask.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.taskData = ''
      },

      [startUpdateTask.pending]: (state) => {
        state.loadingBtn = true
        state.error = ''
      },
      [startUpdateTask.fulfilled]: (state, { payload }) => {
        state.updateTask = payload;
        state.loadingBtn = false;
        state.error = '';
      },
      [startUpdateTask.rejected]: (state, { payload }) => {
        state.loadingBtn = false
        state.error = payload
        state.updateTask = ''
      },

      [startFetchDataFields.pending]: (state) => {
        state.loading = true
        state.error = ''
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        state.error = '';
        state.dataFields = payload;
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.dataFields = initialState.dataFields
      },

      [startFetchSearchFields.pending]: (state) => {
        return {...state, loading: true, error: ''}
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return {...state, fields: payload, error: ''}
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return {...state, loading: false, fields:  initialState.fields, error: payload}
      },

      [startCopyTask.pending]: (state) => {
        state.loadingBtn = true
        state.error = ''
      },
      [startCopyTask.fulfilled]: (state, { payload }) => {
        state.loadingBtn = false
        state.error = '';
        state.copyTask = payload;
      },
      [startCopyTask.rejected]: (state, { payload }) => {
        state.loadingBtn = false
        state.error = payload
        state.copyTask = ''
      },

     
      [startDeleteTask.fulfilled]: (state, { payload }) => {
        state.loading = false
        state.error = '';
        state.deleteTask = payload;
      },
      [startDeleteTask.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.deleteTask = ''
      },

      [startStatusTask.fulfilled]: (state, { payload }) => {
        state.loading = false
        state.error = '';
        state.statusTask = payload;
      },
      [startStatusTask.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.statusTask = ''
      },

      [startFetchTasks.fulfilled]: (state, { payload }) => {
        state.tasks = payload;
        state.loading = false
        state.loadingSearch = false
        state.error = '';
      },
      [startFetchTasks.rejected]: (state, { payload }) => {
        state.error = payload
        state.loading = false
        state.loadingSearch = false
        state.tasks = initialState.tasks
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      },
    },
});

export const taskActions = {
    ...taskSlice.actions,
    startCreateTask,
    startFetchTask,
    startUpdateTask,
    startFetchDataFields,
    startFetchSearchFields,
    startCopyTask,
    startDeleteTask,
    startStatusTask,
    startFetchTasks,
    setSearch,
    setCurrentPage,
    setSort
}
export default taskSlice.reducer