import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, KNOWLEDGE_DATABASES } from '../../constants/defaultValues';

const startFetchKnowledgeDatabases = createAsyncThunk('knowledgeDatabase/startFetchKnowledgeDatabases', async ({ locale, token, filter, loading}, { rejectWithValue, dispatch }) => {
    try {
        dispatch(knowledgeDatabaseSlice.actions.actionKnowledgeDatabase(loading))
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${KNOWLEDGE_DATABASES}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}&knowledge=${filter.searchFilter.knowledge.length > 0 ? filter.searchFilter.knowledge.join() : ""}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('knowledgeDatabase/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${KNOWLEDGE_DATABASES}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSearchFields = createAsyncThunk('knowledgeDatabase/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${KNOWLEDGE_DATABASES}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteKnowledgeDatabase = createAsyncThunk('knowledgeDatabase/startDeleteKnowledgeDatabase', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${KNOWLEDGE_DATABASES}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteFile = createAsyncThunk('knowledgeDatabase/startDeleteFile', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${KNOWLEDGE_DATABASES}/destroyFile/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateKnowledgeDatabase = createAsyncThunk('knowledgeDatabase/startUpdateKnowledgeDatabase', async ({ id, knowledge_database, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${KNOWLEDGE_DATABASES}/update/${id}`, knowledge_database, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchKnowledgeDatabase = createAsyncThunk('knowledgeDatabase/startFetchKnowledgeDatabase', async ({ knowledge_database, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${KNOWLEDGE_DATABASES}/edit/${knowledge_database}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            title: data.title,
            category_id: data.category_id,
            youtubes: data.youtubes,
            files: [],
            filesData: data.filesData
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateKnowledgeDatabase = createAsyncThunk('knowledgeDatabase/startCreateKnowledgeDatabase', async ({ knowledge_database, locale, token, form, dropzone }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${KNOWLEDGE_DATABASES}/store`, knowledge_database, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
            dropzone.removeAllFiles(true)
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});



//Heading Functions
const setSearch = createAsyncThunk('knowledgeDatabase/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('knowledgeDatabase/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('knowledgeDatabase/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    knowledgeDatabases: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    searchFilterType: 1,
    fields: [],
    searchFilter: [
        { translate: "general.categories", id: "knowledge", dataKey: "knowledge", search: "", type: 1},
    ],
    searchFilterValues: {
        knowledge: []
    },
    knowledgeDatabaseData: {
        title: '',
        category_id: '',
        youtubes: [''],
        files: [],
        filesData: []
    },
    dataFields: {
        knowledge: []
    },
    createKnowledgeDatabase: '',
    updateKnowledgeDatabase: '',
    deleteKnowledgeDatabase: '',
    deleteFile: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const knowledgeDatabaseSlice = createSlice({
    name: 'knowledgeDatabase',
    initialState,
    reducers: {
        actionKnowledgeDatabase(state, { payload }) {
            return { ...state, loading: payload ? true : false, loadingSearch: true, error: '' };
        },
        fetchKnowledgeDatabasesSuccess(state, { payload }) {
            return { ...state, loading: false, loadingSearch: false, knowledgeDatabases: payload, error: '' };
        },
        clearKnowledgeDatabase(state) {
            return { ...state, loadingBtn: false, loading: false, loadingSearch: false, knowledgeDatabaseData: initialState.knowledgeDatabaseData, error: '' };
        },
        clearAlertKnowledgeDatabase(state) {
            return { ...state, updateKnowledgeDatabase: '', createKnowledgeDatabase: '', deleteKnowledgeDatabase: '', deleteFile: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        setFilter(state, { payload }) {
            return { ...state, searchFilterValues: payload };
        },
        setFilterType(state, { payload }) {
            return { ...state, searchFilterType: payload };
        },
        setFilterFieldSearch(state, { payload }) {
            return { ...state, fields: payload.dataValues, searchFilter: payload.values };
        },
    },
    extraReducers: {
      [startFetchKnowledgeDatabases.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, knowledgeDatabases: payload, error: '' };
      },
      [startFetchKnowledgeDatabases.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, knowledgeDatabases: initialState.knowledgeDatabases, error: payload.message };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload.message };
      },

      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, fields: payload, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, fields: initialState.fields, error: payload.message };
      },

      [startDeleteKnowledgeDatabase.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteKnowledgeDatabase: payload, error: '' };
      },
      [startDeleteKnowledgeDatabase.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteKnowledgeDatabase: '', error: payload.message };
      },

      [startDeleteFile.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteFile: payload, error: '' };
      },
      [startDeleteFile.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteFile: '', error: payload.message }; 
      },

      [startUpdateKnowledgeDatabase.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateKnowledgeDatabase.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateKnowledgeDatabase: payload, error: '' };
      },
      [startUpdateKnowledgeDatabase.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateKnowledgeDatabase: '', error: payload.message };
      },

      [startFetchKnowledgeDatabase.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchKnowledgeDatabase.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, knowledgeDatabaseData: payload, error: '' };
      },
      [startFetchKnowledgeDatabase.rejected]: (state, { payload }) => {
        return { ...state, loading: false, knowledgeDatabaseData: '', error: payload.message };
      },

      [startCreateKnowledgeDatabase.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateKnowledgeDatabase.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createKnowledgeDatabase: payload, error: '' };
      },
      [startCreateKnowledgeDatabase.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createKnowledgeDatabase: '', error: payload.message };
      },
      

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      },
    },
});


export const knowledgeDatabaseActions = {
    ...knowledgeDatabaseSlice.actions,
    startFetchKnowledgeDatabases,
    startFetchDataFields,
    startFetchSearchFields,
    startDeleteKnowledgeDatabase,
    startDeleteFile,
    startUpdateKnowledgeDatabase,
    startFetchKnowledgeDatabase,
    startCreateKnowledgeDatabase,
    setSearch,
    setCurrentPage,
    setSort
}
export default knowledgeDatabaseSlice.reducer