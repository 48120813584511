import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, TYPESYNCS } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('typeSync/startFetchDataFields', async ({ locale, token, ifEdit, type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TYPESYNCS}/dataFields/${type}?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchTypeSyncs = createAsyncThunk('typeSync/startFetchTypeSyncs', async ({ locale, token, filter, type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${TYPESYNCS}/${type}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateTypeSync = createAsyncThunk('typeSync/startCreateTypeSync', async ({ typeSyncData, locale, token, form, type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${TYPESYNCS}/store/${type}`, typeSyncData, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateTypeSync = createAsyncThunk('typeSync/startUpdateTypeSync', async ({ id, typeSyncData, locale, token, form, type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${TYPESYNCS}/update/${type}/${id}`, typeSyncData, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteTypeSync = createAsyncThunk('typeSync/startDeleteTypeSync', async ({ locale, token, id, type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${TYPESYNCS}/destroy/${type}/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveTypeSync = createAsyncThunk('typeSync/startActiveTypeSync', async ({ locale, token, id, action, type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${TYPESYNCS}/active/${type}/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchTypeSync = createAsyncThunk('typeSync/startFetchTypeSync', async ({ typeSyncData, history, locale, token, type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TYPESYNCS}/edit/${type}/${typeSyncData}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            typeSync: data.typeSync,
            name_en: data.name_en,
            name_el: data.name_el,
            name_de: data.name_de,
            name_sr: data.name_sr,
            name_bg: data.name_bg,
            name_ru: data.name_ru,
            iso: data.iso ? data.iso : "",
            sort: data.sort,
            portal_hp: data.portal_hp === 1 ? true : false,
            portal_hp_ru: data.portal_hp_ru === 1 ? true : false,
            portal_gp: data.portal_gp === 1 ? true : false,
            rate: data.rate ? data.rate : ""
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('typeSync/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('typeSync/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('typeSync/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    typeSyncs: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,   
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    createTypeSync: '',
    updateTypeSync: '',
    deleteTypeSync: '',
    typeSyncData: {
        edit:false,
        typeSync:'',
        name_en: '',
        name_el: '',
        name_de: '',
        name_sr: '',
        name_bg: '',
        name_ru: '',
        iso: '',
        sort: '',
        portal_hp: false,
        portal_hp_ru: false,
        portal_gp: false,
        rate: '',
    },
    dataFields: {},
    loadingBtn: false,
    loading: true,
    error: ''
}

export const typeSyncSlice = createSlice({
    name: 'typeSync',
    initialState,
    reducers: {
        fetchTypeSyncsSuccess(state, { payload }) {
            return { ...state, loading: false, typeSyncs: payload, error: '' };
        },
        clearTypeSync(state) {
            return { ...state, loadingBtn: false, loading: false, typeSyncData: initialState.typeSyncData, error: '' };
        },
        clearAlertTypeSync(state) {
            return { ...state, updateTypeSync: '', createTypeSync: '', deleteTypeSync: '', activeTypeSync: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchTypeSyncs.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchTypeSyncs.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, typeSyncs: payload, error: '' };
      },
      [startFetchTypeSyncs.rejected]: (state, { payload }) => {
        return { ...state, loading: false, typeSyncs: initialState.typeSyncs, error: payload };
      },

      [startFetchTypeSync.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchTypeSync.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, typeSyncData: payload, error: '' };
      },
      [startFetchTypeSync.rejected]: (state, { payload }) => {
        return { ...state, loading: false, typeSyncData: '', error: payload };
      },
      
      [startCreateTypeSync.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateTypeSync.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createTypeSync: payload, error: '' };
      },
      [startCreateTypeSync.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createTypeSync: '', error: payload };
      },

      [startUpdateTypeSync.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateTypeSync.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateTypeSync: payload, error: '' };
      },
      [startUpdateTypeSync.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateTypeSync: '', error: payload };
      },

      [startDeleteTypeSync.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteTypeSync: payload, error: '' };
      },
      [startDeleteTypeSync.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteTypeSync: '', error: payload };
      },

      [startActiveTypeSync.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeTypeSync: payload, error: '' };
      },
      [startActiveTypeSync.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeTypeSync: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },  
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const typeSyncActions = {
    ...typeSyncSlice.actions,
    startFetchTypeSyncs,
    startFetchTypeSync,
    startCreateTypeSync,
    startUpdateTypeSync,
    startDeleteTypeSync,
    startActiveTypeSync,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default typeSyncSlice.reducer