import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, ACTIVITY } from '../../constants/defaultValues';

const startFetchSearchFields = createAsyncThunk('activity/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`

        const res = await axios.get(`${ACTIVITY}/searchFields?lang=${locale}`, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchActivities = createAsyncThunk('activity/startFetchActivities', async ({ locale, token, filter_values }, { rejectWithValue }) => {
    const headers = HEADERS;
    try {
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`

        const user          = filter_values.filter.user;
        const topic         = filter_values.filter.topic;
        const action        = filter_values.filter.action;
        const type          = filter_values.filter.type;
        const date_from     = filter_values.filter.date_from;
        const date_to       = filter_values.filter.date_to;
        const external      = filter_values.filter.external;
        const description   = filter_values.filter.description;

        const sort = filter_values.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter_values.sort[0].id;

        const res = await axios.get(`${ACTIVITY}?user=${user}&topic=${topic}&action=${action}&type=${type}&date_from=${date_from ? date_from : ""}&date_to=${date_to ? date_to : ""}&external=${external}&description=${description}
        &pageSize=${filter_values.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter_values.currentPage}`, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setCurrentPage = createAsyncThunk('activity/setCurrentPage', ( args ) => {
    return args;
});

const setSort = createAsyncThunk('activity/setSort', ( args ) => {
    return args;
});

const initialState = {
    activities: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 10,
    currentPage: 1,
    sort: [{ desc: true, id: "id" }],
    filter: {user: '', topic: '', action: '', type: '', date_from: null, date_to: null, external: '', description: ''},
    searchFields: {
        users: [],
        topics: [],
        actions: []
    },
    loading: true,
    loadingFetch: false,
    error: ''
}

export const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        fetchActivitiesSuccess(state, { payload }) {
            return { ...state, loadingFetch: false, activities: payload, error: '' };
        },
        clearActivity(state) {
            return { ...state, currentPage: initialState.currentPage, selectedPageSize: initialState.selectedPageSize, activities: initialState.activities, filter: initialState.filter, loading: false, loadingFetch: false, error: '' };
        },
        clearAlertActivity(state) {
            return { ...state, error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setFilterValue(state, { payload }) {
            return { ...state, filter: payload };
        }
    },
    extraReducers: {
      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, searchFields: payload, loading: false, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, searchFields: initialState.dataFields, loading: false, error: payload };
      },

      [startFetchActivities.pending]: (state) => {
        return { ...state, loadingFetch: true, error: '' };
      },
      [startFetchActivities.fulfilled]: (state, { payload }) => {
        return { ...state, loadingFetch: false, activities: payload, error: '' };
      },
      [startFetchActivities.rejected]: (state, { payload }) => {
        return { ...state, loadingFetch: false, activities: initialState.activities, error: payload };
      },

      //Heading Functions
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      },
    },
});

export const activityActions = {
    ...activitySlice.actions,
    startFetchSearchFields,
    startFetchActivities,
    setCurrentPage,
    setSort
}
export default activitySlice.reducer