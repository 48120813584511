import { defaultDirection } from "../constants/defaultValues";

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};


export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
}

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes()
}


export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl"
  };
};

export const setDirection = localValue => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};

export const setErrorAction = errors => {
  let errorValue = "";

  if (typeof errors === 'string' || errors instanceof String) {
    errorValue = errors.result;
  } else {
    for (const prop in errors) {
      if (errors.hasOwnProperty(prop)) {
        errorValue += errors[prop];
      }
    }
  }

  return errorValue;
};


export const getFormattedPhoneNumber = phone => {
  if (phone) {
    let codeAndPhone = phone.split(" ");

    return codeAndPhone[0].replace("00", "+") + ' ' + codeAndPhone[1];
  }
};

export const getFormatedID = id => {
  let newID = String(id);
  if (newID) {
    newID = newID.replace(/\d+/g, (m) => {
      return "0000".substr(m.length) + m;
    });

    return newID
  }
};

export const formatMoney = (n, currency = '') => {
  let money = Number(n);

  return currency + " " + money.toFixed(0).replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
  });
};

export const returnNameDatafields = (data, id, key = "name") => {
  try{
    const selected = data.filter(option => option.id === id)
    return selected[0][key]
  }catch(error){
    return ""
  }
};

export const returnCyriltoLatin = (text) => {
  try{
    let cyr = ['Љ', 'Њ', 'Џ', 'џ', 'ш', 'ђ', 'ч', 'ћ', 'ж', 'љ', 'њ', 'Ш', 'Ђ', 'Ч', 'Ћ', 'Ж','Ц','ц', 'а','б','в','г','д','е','ё','ж','з','и','й','к','л','м','н','о','п', 'р','с','т','у','ф','х','ц','ч','ш','щ','ъ','ы','ь','э','ю','я', 'А','Б','В','Г','Д','Е','Ё','Ж','З','И','Й','К','Л','М','Н','О','П', 'Р','С','Т','У','Ф','Х','Ц','Ч','Ш','Щ','Ъ','Ы','Ь','Э','Ю','Я'];
    let lat = ['Lj', 'Nj', 'Dž', 'dž', 'š', 'đ', 'č', 'ć', 'ž', 'lj', 'nj', 'Š', 'Đ', 'Č', 'Ć', 'Ž','C','c', 'a','b','v','g','d','e','io','zh','z','i','y','k','l','m','n','o','p', 'r','s','t','u','f','h','ts','ch','sh','sht','a','i','y','e','yu','ya', 'A','B','V','G','D','E','Io','Zh','Z','I','Y','K','L','M','N','O','P', 'R','S','T','U','F','H','Ts','Ch','Sh','Sht','A','I','Y','e','Yu','Ya'];
    
    return str_replace(cyr, lat, text)
  }catch(error){
    return ""
  }
};

export const str_replace = ($search, $replace, $subject) =>  {
	
  // Create the regular expression to find the occurrences
  var reg;

  // If the $search parameter is a string
  if ( typeof($search) == "string" ) {

      // Escape all the characters used by regular expressions
      $search = $search.replace(/[.?*+^$[\]\\(){}|-]/g, "\\");
      reg = new RegExp("(" + $search + ")", "g");

  } else {
        
      // Escape all the characters used by regular expressions
      $search = $search.map(function(i) {
        return i.replace(/[.?*+^$[\]\\(){}|-]/g, "\\");
          });

          reg = new RegExp("(" + $search.join("|") + ")", "g");

      }

      // Create the replacement
      var rep;

      // If the $search parameter is a string
      if ( typeof($replace) == "string" ) {
          rep = $replace;
      } else {
          // If the $search is a string and the $replace parameter an array
          if ( typeof($search) == "string" ) {
              rep = $replace[0];
          } else {
              // If the $search and $replace parameter are arrays
              rep = function (i) {
                  return $replace[ $search.indexOf(i) ];
              }
          }
      }

  return $subject.replace(reg, rep);

}




