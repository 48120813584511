import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, SERVICES } from '../../constants/defaultValues';

const startFetchDataFields = createAsyncThunk('service/startFetchDataFields', async ({ locale, token, ifEdit, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${SERVICES}/dataFields?portalId=${portal_id}&lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchServices = createAsyncThunk('service/startFetchServices', async ({ locale, token, filter, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${SERVICES}?portalId=${portal_id}&pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateService = createAsyncThunk('service/startCreateService', async ({ service, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${SERVICES}/store`, service, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateService = createAsyncThunk('service/startUpdateService', async ({ id, service, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${SERVICES}/update/${id}`, service, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteService = createAsyncThunk('service/startDeleteService', async ({ locale, token, id, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${SERVICES}/destroy/${portal_id}/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveService = createAsyncThunk('service/startActiveService', async ({ locale, token, id, action, portal_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${SERVICES}/active/${id}`, { action: action, portal_id: portal_id }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchService = createAsyncThunk('service/startFetchService', async ({ portal_id, service, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${SERVICES}/edit/${portal_id}/${service}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            portal_id: data.portal_id,
            service_category_id: data.service_category_id,
            template: data.template,
            image_or_yt: data.image_or_yt,
            bottom_img_yt_multiply: data.bottom_img_yt_multiply,
            extra_text: data.extra_text ? true : false,
            url_en: data.url_en,
            url_el: data.url_el,
            url_de: data.url_de,
            url_sr: data.url_sr,
            url_bg: data.url_bg,
            url_ru: data.url_ru,
            title_en: data.title_en,
            title_el: data.title_el,
            title_de: data.title_de,
            title_sr: data.title_sr,
            title_bg: data.title_bg,
            title_ru: data.title_ru,
            text_en: data.text_en,
            text_el: data.text_el,
            text_de: data.text_de,
            text_sr: data.text_sr,
            text_bg: data.text_bg,
            text_ru: data.text_ru,
            subtext_en: data.subtext_en,
            subtext_el: data.subtext_el,
            subtext_de: data.subtext_de,
            subtext_sr: data.subtext_sr,
            subtext_bg: data.subtext_bg,
            subtext_ru: data.subtext_ru,
            meta_title_en: data.page_config ? data.page_config.meta_title_en : '',
            meta_title_el: data.page_config ? data.page_config.meta_title_el : '',
            meta_title_de: data.page_config ? data.page_config.meta_title_de : '',
            meta_title_sr: data.page_config ? data.page_config.meta_title_sr : '',
            meta_title_bg: data.page_config ? data.page_config.meta_title_bg : '',
            meta_title_ru: data.page_config ? data.page_config.meta_title_ru : '',
            meta_desc_en: data.page_config ? data.page_config.meta_desc_en : '',
            meta_desc_el: data.page_config ? data.page_config.meta_desc_el : '',
            meta_desc_de: data.page_config ? data.page_config.meta_desc_de: '',
            meta_desc_sr: data.page_config ? data.page_config.meta_desc_sr : '',
            meta_desc_bg: data.page_config ? data.page_config.meta_desc_bg : '',
            meta_desc_ru: data.page_config ? data.page_config.meta_desc_ru : '',
            meta_lsi_en: data.page_config ? data.page_config.meta_lsi_en : [],
            meta_lsi_el: data.page_config ? data.page_config.meta_lsi_el : [],
            meta_lsi_de: data.page_config ? data.page_config.meta_lsi_de : [],
            meta_lsi_sr: data.page_config ? data.page_config.meta_lsi_sr : [],
            meta_lsi_bg: data.page_config ? data.page_config.meta_lsi_bg : [],
            meta_lsi_ru: data.page_config ? data.page_config.meta_lsi_ru : [],
            youtube_url: data.youtube_url,
            youtube_url_bottom: data.youtube_url_bottom,
            sort: data.sort,
            image: data.image,
            image_name: data.image,
            image_bottom: data.image_bottom,
            image_bottom_name: data.image_bottom_name,
            images_bottom_multiply: data.images_bottom_multiply
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('service/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('service/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('service/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    services: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    serviceData: {
        portal_id: '',
        service_category_id: '',
        template: 1,
        image_or_yt: 0,
        bottom_img_yt_multiply: 0,
        extra_text: false,
        sort: 0,
        url_en: '',
        url_el: '',
        url_de: '',
        url_sr: '',
        url_bg: '',
        url_ru: '',
        title_en: '',
        title_el: '',
        title_de: '',
        title_sr: '',
        title_bg: '',
        title_ru: '',
        text_en: '',
        text_el: '',
        text_de: '',
        text_sr: '',
        text_bg: '',
        text_ru: '',
        subtext_en: '',
        subtext_el: '',
        subtext_de: '',
        subtext_sr: '',
        subtext_bg: '',
        subtext_ru: '',
        meta_title_en: '',
        meta_title_el: '',
        meta_title_de: '',
        meta_title_sr: '',
        meta_title_bg: '',
        meta_title_ru: '',
        meta_desc_en: '',
        meta_desc_el: '',
        meta_desc_de: '',
        meta_desc_sr: '',
        meta_desc_bg: '',
        meta_desc_ru: '',
        meta_lsi_en: [],
        meta_lsi_el: [],
        meta_lsi_de: [],
        meta_lsi_sr: [],
        meta_lsi_bg: [],
        meta_lsi_ru: [],
        youtube_url: '',
        youtube_url_bottom: '',
        image: '',
        image_name: '',
        image_bottom: '',
        image_bottom_name: '',
        images_bottom_multiply: []
    },
    dataFields: {
        service_categories: []
    },
    createService: '',
    updateService: '',
    deleteService: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        fetchServicesSuccess(state, { payload }) {
            return { ...state, loading: false, services: payload, error: '' };
        },
        clearService(state) {
            return { ...state, loadingBtn: false, loading: false, serviceData: initialState.serviceData, error: '' };
        },
        clearAlertService(state) {
            return { ...state, updateService: '', createService: '', deleteService: '', activeService: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchServices.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchServices.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, services: payload, error: '' };
      },
      [startFetchServices.rejected]: (state, { payload }) => {
        return { ...state, loading: false, services: initialState.services, error: payload };
      },

      [startFetchService.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchService.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, serviceData: payload, error: '' };
      },
      [startFetchService.rejected]: (state, { payload }) => {
        return { ...state, loading: false, serviceData: '', error: payload };
      },
      
      [startCreateService.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateService.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createService: payload, error: '' };
      },
      [startCreateService.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createService: '', error: payload };
      },

      [startUpdateService.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateService.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateService: payload, error: '' };
      },
      [startUpdateService.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateService: '', error: payload };
      },

      [startDeleteService.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteService: payload, error: '' };
      },
      [startDeleteService.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteService: '', error: payload };
      },

      [startActiveService.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeService: payload, error: '' };
      },
      [startActiveService.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeService: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const serviceActions = {
    ...serviceSlice.actions,
    startFetchServices,
    startFetchService,
    startCreateService,
    startUpdateService,
    startDeleteService,
    startActiveService,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default serviceSlice.reducer