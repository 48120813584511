import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, LOCATION_CATEGORIES } from '../../constants/defaultValues';

const startFetchLocationCategories = createAsyncThunk('locationCategory/startFetchLocationCategories', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const sort = filter.sort[0].desc ? "DESC" : "ASC";
        const sortKey = filter.sort[0].id;

        const res = await axios.get(`${LOCATION_CATEGORIES}?pageSize=${filter.pageSize}&sortKey=${sortKey}&sort=${sort}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateLocationCategory = createAsyncThunk('locationCategory/startCreateLocationCategory', async ({ location_category, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${LOCATION_CATEGORIES}/store`, location_category, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateLocationCategory = createAsyncThunk('locationCategory/startUpdateLocationCategory', async ({ id, location_category, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${LOCATION_CATEGORIES}/update/${id}`, location_category, { headers });

        if(res.data){
            form.setStatus({ success: true })
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteLocationCategory = createAsyncThunk('locationCategory/startDeleteLocationCategory', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${LOCATION_CATEGORIES}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveLocationCategory = createAsyncThunk('locationCategory/startActiveLocationCategory', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${LOCATION_CATEGORIES}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchLocationCategory = createAsyncThunk('locationCategory/startFetchLocationCategory', async ({ location_category, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${LOCATION_CATEGORIES}/edit/${location_category}`, { headers });

        const data = res.data;

        const results = {
          edit: true,
          url_en: data.url_en,
          url_el: data.url_el,
          title_en: data.title_en,
          title_el: data.title_el,
          text_en: data.text_en,
          text_el: data.text_el,
          meta_title_en: data.page_config ? data.page_config.meta_title_en : '',
          meta_title_el: data.page_config ? data.page_config.meta_title_el : '',
          meta_desc_en: data.page_config ? data.page_config.meta_desc_en : '',
          meta_desc_el: data.page_config ? data.page_config.meta_desc_el : '',
          meta_lsi_en: data.page_config ? data.page_config.meta_lsi_en : [],
          meta_lsi_el: data.page_config ? data.page_config.meta_lsi_el : [],
          image_or_yt: data.image_or_yt,
          image: data.image,
          image_name: data.image,
          youtube_url: data.youtube_url,
          sort: data.sort
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('locationCategory/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('locationCategory/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('locationCategory/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    locationCategories: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sort: [{desc:false, id: "id"}],
    locationCategoryData: {
        url_en: '',
        url_el: '',
        title_en: '',
        title_el: '',
        text_en: '',
        text_el: '',
        meta_title_en: '',
        meta_title_el: '',
        meta_desc_en: '',
        meta_desc_el: '',
        meta_lsi_en: [],
        meta_lsi_el: [],
        image_or_yt: 1,
        youtube_url: '',
        image: '',
        image_name: '',
        sort: 0
    },
    createLocationCategory: '',
    updateLocationCategory: '',
    deleteLocationCategory: '',
    loadingBtn: false,
    loading: true,
    error: ''
}

export const locationCategorySlice = createSlice({
    name: 'locationCategory',
    initialState,
    reducers: {
        fetchLocationCategoriesSuccess(state, { payload }) {
            return { ...state, loading: false, locationCategories: payload, error: '' };
        },
        clearLocationCategory(state) {
            return { ...state, loadingBtn: false, loading: false, locationCategoryData: initialState.locationCategoryData, error: '' };
        },
        clearAlertLocationCategory(state) {
            return { ...state, updateLocationCategory: '', createLocationCategory: '', deleteLocationCategory: '', activeLocationCategory: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        }
    },
    extraReducers: {
      [startFetchLocationCategories.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchLocationCategories.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, locationCategories: payload, error: '' };
      },
      [startFetchLocationCategories.rejected]: (state, { payload }) => {
        return { ...state, loading: false, locationCategories: initialState.locationCategories, error: payload };
      },

      [startFetchLocationCategory.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchLocationCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, locationCategoryData: payload, error: '' };
      },
      [startFetchLocationCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, locationCategoryData: '', error: payload };
      },
      
      [startCreateLocationCategory.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateLocationCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createLocationCategory: payload, error: '' };
      },
      [startCreateLocationCategory.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createLocationCategory: '', error: payload };
      },

      [startUpdateLocationCategory.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateLocationCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateLocationCategory: payload, error: '' };
      },
      [startUpdateLocationCategory.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateLocationCategory: '', error: payload };
      },

      [startDeleteLocationCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteLocationCategory: payload, error: '' };
      },
      [startDeleteLocationCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteLocationCategory: '', error: payload };
      },

      [startActiveLocationCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeLocationCategory: payload, error: '' };
      },
      [startActiveLocationCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeLocationCategory: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sort: payload }
      }
    },
});


export const locationCategoryActions = {
    ...locationCategorySlice.actions,
    startFetchLocationCategories,
    startFetchLocationCategory,
    startCreateLocationCategory,
    startUpdateLocationCategory,
    startDeleteLocationCategory,
    startActiveLocationCategory,
    setSearch,
    setCurrentPage,
    setSort
}
export default locationCategorySlice.reducer